const CN = () => ({
  seo: {
    title: '企业博客 - 万物新生',
    description: '万物新生 - 企业博客每月更新集团业务动态,关键事件,行业洞察.',
    keywords: '万物新生、万物新生 官网、爱回收、爱回收 官网、拍机堂、拍机堂 官网、拍拍、拍拍 官网'
  },
  pageTitle: '企业博客',
  breadcrumb: '首页 / 新闻动态 /企业博客',
  news: [{
    year: '2024年',
    list: [
      {
        title: '万物新生集团2024年12月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2025年1月17日',
        url: '/news/blog/202412',
      },
      {
        title: '万物新生集团2024年11月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2024年12月17日',
        url: '/news/blog/202411',
      },
      {
        title: '万物新生集团2024年10月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2024年11月13日',
        url: '/news/blog/202410',
      },
      {
        title: '万物新生集团2024年9月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2024年10月15日',
        url: '/news/blog/202409',
      },
      {
        title: '万物新生集团2024年8月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2024年9月14日',
        url: '/news/blog/202408',
      },
      {
        title: '万物新生集团2024年7月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2024年8月13日',
        url: '/news/blog/202407',
      },
      {
        title: '万物新生集团2024年6月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2024年7月11日',
        url: '/news/blog/202406',
      },
      {
        title: '万物新生集团2024年5月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2024年6月17日',
        url: '/news/blog/202405',
      },
      {
        title: '万物新生集团2024年4月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2024年5月14日',
        url: '/news/blog/202404',
      },
      {
        title: '万物新生集团2024年3月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2024年4月17日',
        url: '/news/blog/202403',
      },
      {
        title: '万物新生集团2024年2月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2024年3月21日',
        url: '/news/blog/202402',
      },
      {
        title: '万物新生集团2024年1月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2024年2月7日',
        url: '/news/blog/202401',
      },
    ]
  },
  {
    year: '2023年',
    list: [
      {
        title: '万物新生集团2023年12月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2024年1月15日',
        url: '/news/blog/202312',
      },
      {
        title: '万物新生集团2023年11月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2023年12月14日',
        url: '/news/blog/202311',
      },
      {
        title: '万物新生集团2023年10月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2023年11月16日',
        url: '/news/blog/202310',
      },
      {
        title: '万物新生集团2023年9月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2023年10月12日',
        url: '/news/blog/202309',
      },
      {
        title: '万物新生集团2023年8月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2023年9月15日',
        url: '/news/blog/202308',
      },
      {
        title: '万物新生集团2023年7月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2023年8月16日',
        url: '/news/blog/202307',
      },
      {
        title: '万物新生集团2023年6月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2023年7月12日',
        url: '/news/blog/202306',
      },
      {
        title: '万物新生集团2023年5月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2023年6月13日',
        url: '/news/blog/202305',
      },
      {
        title: '万物新生集团2023年4月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2023年5月15日',
        url: '/news/blog/202304',
      },
      {
        title: '万物新生集团2023年3月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2023年4月12日',
        url: '/news/blog/202303',
      },
      {
        title: '万物新生集团2023年2月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2023年3月16日',
        url: '/news/blog/202302',
      },
      {
        title: '万物新生集团2023年1月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2023年2月13日',
        url: '/news/blog/202301',
      },
    ]
  },
  {
    year: '2022年',
    list: [
      {
        title: '万物新生集团2022年12月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2023年1月12日',
        url: '/news/blog/202212',
      },
      {
        title: '万物新生集团2022年11月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2022年12月13日',
        url: '/news/blog/202211',
      },
      {
        title: '万物新生集团2022年10月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2022年11月14日',
        url: '/news/blog/202210',
      },
      {
        title: '万物新生集团2022年9月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2022年10月14日',
        url: '/news/blog/202209',
      },
      {
        title: '万物新生集团2022年8月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2022年9月9日',
        url: '/news/blog/202208',
      },
      {
        title: '万物新生集团2022年7月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2022年8月16日',
        url: '/news/blog/202207',
      },
      {
        title: '万物新生集团2022年6月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2022年7月14日',
        url: '/news/blog/202206',
      },
      {
        title: '万物新生集团2022年5月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2022年6月15日',
        url: '/news/blog/202205',
      },
      {
        title: '万物新生集团2022年4月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2022年5月9日',
        url: '/news/blog/202204',
      },
      {
        title: '万物新生集团2022年3月博客',
        description: '(以下内容仅供参考，具体数据以官方季度财报披露为准)',
        date: '2022年4月11日',
        url: '/news/blog/202203',
      },
      {
        title: '万物新生集团2022年2月博客',
        description: '以下内容仅供参考，具体数据以官方季度财报披露为准',
        date: '2022年3月15日',
        url: '/news/blog/202202',
      },
      {
        title: '万物新生集团2022年1月博客',
        description: '以下内容仅供参考，具体数据以官方季度财报披露为准',
        date: '2022年2月10日',
        url: '/news/blog/202201',
      },
    ]
  },
  {
    year: '2021年',
    list: [
      {
        title: '万物新生集团2021年12月博客',
        description: '以下内容仅供参考，具体数据以官方季度财报披露为准',
        date: '2022年1月6日',
        url: '/news/blog/202112',
      },
      {
        title: '万物新生集团2021年11月博客',
        description: '以下内容仅供参考，具体数据以官方季度财报披露为准',
        date: '2021年12月9日',
        url: '/news/blog/202111',
      },
      {
        title: '万物新生集团2021年10月博客',
        description: '以下内容仅供参考，具体数据以官方季度财报披露为准',
        date: '2021年11月9日',
        url: '/news/blog/202110'
      },
      {
        title: '万物新生集团2021年9月博客',
        description: '以下内容仅供参考，具体数据以官方季度财报披露为准',
        date: '2021年10月10日',
        url: '/news/blog/202109'
      },
      {
        title: '万物新生集团2021年8月博客',
        description: '以下内容仅供参考，具体数据以官方季度财报披露为准',
        date: '2021年9月8日',
        url: '/news/blog/202108'
      }
    ]
  }],
  articles: {
    '202412': {
      articleTitle: '万物新生集团2024年12月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '关键事件',
          list: [
            {
              subTitle: '',
              text: '12月，爱回收自营标准店数量净新增近40间，加盟标准店数量在月内实现约90间净新增，在全国范围内提升交付网络建设的速度。爱回收提出“力争用3年时间，实现5000家门店，5000名上门回收工程师”的回收服务能力建设目标，打造扎实有力的能力基础设施。'
            },
            {
              subTitle: '',
              text: '作为多品类回收的一部分，爱回收推出了游戏账号回收服务，第一批业务支持王者荣耀、和平精英、火影忍者三款腾讯热门游戏账号的回收。该业务通过百家商家线上评估竞价，用户等候约15分钟快速获得估价，并可以享受一对一的客服服务，实现“虚拟资产”回收。'
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '商务部等五部门联合印发《二手商品流通试点工作方案》',
              text: '12月，商务部等五部门联合印发《二手商品流通试点工作方案》，旨在通过3年的试点工作，形成一批可复制、可推广的二手商品流通典型案例，进一步完善二手商品流通政策体系，提升二手商品交易市场和经营企业的专业化、标准化、特色化、品牌化运营水平。由省级商务主管部门会同有关部门，组织试点城市与试点企业申报工作，并于2025年1月24日前将实施方案报商务部。'
            },
          ]
        },
      ]
    },
    '202411': {
      articleTitle: '万物新生集团2024年11月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '关键事件',
          list: [
            {
              subTitle: '',
              text: '爱回收于11月净新增自营门店9间，于省会及重点城市提升覆盖率。同期，加盟标准店在营店数量净新增44间，于广东、湖北、新疆等多地区增加下沉市场渗透，加强爱回收门店在各地的服务触达。'
            },
            {
              subTitle: '',
              text: '万物新生集团发布2024年第三季度未经审计的财务报告，总收入同比增长24.4%至40.5亿元，季度non-GAAP经营利润首次突破1亿元规模，并首次实现GAAP口径全面盈利。在集团旗下的自营C2B回收业务中，以旧换新回收方式更受用户欢迎。延续至四季度，在双十一大促全周期内，京东渠道的回收总业绩同比增长77%，其中以旧换新为主要增长动力。'
            },
            {
              subTitle: '',
              text: '万物新生集团入《二手消费电子行业循环经济实践》案例入选联合国全球契约组织“二十年二十佳”企业可持续发展案例，该案例为国内二手消费电子行业唯一入选案例。本次案例审议由联合国系统高级别代表以及高校专家所组成的专家评委会完成，一同收录的还有腾讯、蚂蚁、携程、宁德时代、国家开发银行等共20家知名企业和机构在各领域的可持续发展实践。'
            },
            {
              subTitle: '',
              text: '爱回收携手欧莱雅集团一起亮相进博会，通过“返航新生”环保倡议共同打造循环消费新模式。自11月11日起，用户在全国约30个城市通过爱回收机器投递任意品牌化妆品空瓶，即有机会获得欧莱雅集团专属福利，共建环保回收消费理念。'
            },
            {
              subTitle: '',
              text: '爱回收以环保行动支持北京亦庄与湖北大冶半程马拉松，现场回收塑料空瓶及塑料包装等可回收物，并用瓶盖为完赛跑者制作环保奖牌，通过大众赛事将环保态度与体育精神集合。'
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '江苏省推出为期约一个月的手机3C产品以旧换新补贴',
              text: '江苏省安排一定规模的省级财政资金开展3C数码产品补贴专项活动，有效期为2024年11月27日至12月31日，用于补贴个人消费者的手机、平板电脑、数码相机等3C品类的购买需求。补贴标准为产品成交价格的15%，最高可优惠2,000元。该补贴需线下核销，并进行实名制管理。'
            },
          ]
        },
      ]
    },
    '202410': {
      articleTitle: '万物新生集团2024年10月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '爱回收于10月净新增13间自营店与28间加盟店，进一步拓展回收与严选二手的服务范围。期间，多品类回收业务下的翡翠到店回收受到用户喜爱。10月，全国16座城市已支持翡翠回收，供用户预约体验。'
            },
            {
              subTitle: '',
              text: '爱回收“返航新生计划”的品牌伙伴进一步扩充，10月携手妙可蓝多、家乐氏、福奈特、茶百道、美团青山计划等，促进居民的塑料、纸品、包装、织物等可回收物的回收分类与再造。用户可获得由合作品牌方提供的实物礼品或礼券激励，共同实践可持续的循环经济。'
            },
            {
              subTitle: '',
              text: '10月，拍机堂战略升级，进一步发挥二手手机3C交易行业基础设施能力，优化供给端商家服务模式，在处置端拉通质检标准和价格能力，并在销售环节加强平台用户的体验。此前，万物新生管理层曾在公开交流中提及，集团旗下的B2B交易平台拍机堂联合抖音电商，以拍机堂行业领先的二手消费电子产品供应链，赋能更多行业中小商家做好二手新零售。'
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '中国资源循环集团有限公司成立',
              text: '中国资源循环集团有限公司成立大会于10月18日在天津举行。据新华社报道，这家新组建的中央企业将专门从事资源循环利用，承担打造全国性、功能性资源回收再利用平台的重要任务。企业注册资本100亿元，由国务院国资委代表国务院履行出资人职责。习总书记强调：“组建中国资源循环集团有限公司，是党中央着眼健全绿色低碳循环发展经济体系，全面推进美丽中国建设作出的重要决策部署。”'
            },
          ]
        },
      ]
    },
    '202409': {
      articleTitle: '万物新生集团2024年9月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '爱回收持续加强多品类回收能力，其中，9月发布翡翠回收日历，巡回服务在上海、苏州、南京、合肥、郑州等14城落地。用户进行线上预约后，到店估价，即可当场回收结款，以爱回收门店的优质交付场景为用户创造更多回收价值。'
            },
            {
              subTitle: '',
              text: '爱回收的品牌活动 “返航新生计划”与更多品牌伙伴建立合作：与舒洁纸品联合发起纸类与塑料回收，倡议守护蔚蓝深海；与京东推出旧酒瓶回收，换新酒的品味升级活动；与伊利金典和蒙牛真果粒分别启动奶盒回收再生；与戴森发起闲置宠物用品回收；与可口可乐、软塑新生项目、太太乐于世界清洁日发起“为地球减塑”的环保行动。'
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '爱回收门店9月单月落地108间',
              text: '9月内，爱回收门店运营取得新突破，包括南京德基广场店在内，单月落地108间自营与加盟门店的新开或重新装修升级，从综合城市覆盖与回收服务能力两方面提升，让更多用户能够在身边找到爱回收门店与更多回收服务。'
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '国际数据公司IDC发布全球二手手机季度数据跟踪监测报告',
              text: '据IDC跟踪<a rel="noopener noreferrer" target="_blank" href="https://mp.weixin.qq.com/s/Np6Bs0ggWUuAql6rFRKOvQ">数据</a>，2024年第二季度，全球二手手机市场容量约4,988万台，同比增长7.4%，以旧换新已经成为二手手机的主要供应来源。中国作为全球最大的二手手机市场，今年第二季度以1,870万台的市场容量，占据了全球38%的市场份额，同比增长接近10%。中国二手手机市场中，Apple凭借超过三分之一的市场份额占据绝对优势，iPhone依旧是二手市场最受欢迎的产品。'
            },
          ]
        },
      ]
    },
    '202408': {
      articleTitle: '万物新生集团2024年8月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '8月，爱回收净新开10间自营标准店，分布于一二线省会城市的优质商场，并在四川、贵州、湖南等省份净新增24间加盟标准店，服务更广泛用户的回收和以旧换新需求。'
            },
            {
              subTitle: '',
              text: '爱回收联合知名品牌共同发起的“返航新生”计划受到用户广泛好评，于8月上线更多品牌的绿色倡议，例如，联合美团“青山计划”和茶百道回收更多塑料奶茶杯，携手乳制品品牌恒天然与安佳回收更多奶盒并完成绿色处置。同时，“返航新生”推出更多玩法，鼓励用户组建返航小队，邀请好友助力共同参与绿色消费。'
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '万物新生发布2024年第二季度财务报告，实现优质收入与利润增长',
              text: '万物新生发布24Q2财务报告，集团总收入37.8亿元，同比增长27.4%超出指引区间高端。利润层面实现经调整经营利润超9400万，经调整经营利润率2.5%。万物新生管理层在财报电话会上表示，在二季度的运营中，以旧换新、自营严选零售、多品类回收服务呈现显著增长。'
            },
            {
              subTitle: '万物新生的标普企业可持续评估ESG评分获得提升',
              text: '2024年，万物新生标普全球ESG评分为35分（满分为100分），相比上一年的33分实现提升，超过了全球93%的零售业同行企业，数据可及性评级为“非常高”。万物新生本次年度评分提升的主要驱动力来自于气候战略、人力资源管理、商业道德等重要议题的绩效提升。万物新生对透明度的承诺还体现在其数据的高度可用性上，为此次评估提供了71%的要求披露内容和66%的额外披露内容。这一透明度水平显著超过了行业平均水平，再次彰显了集团对开放和坚守商业道德的承诺。'
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '商务部牵头发布进一步做好家电电脑以旧换新工作的通知',
              text: '8月24日，商务部等四部门办公厅关于进一步做好家电以旧换新工作的通知，提出中央与地方政策协同联动。通知明确补贴品种和补贴标准，对个人消费者购买1级与2级及以上能效或水效标准的家电与电脑等8类产品给予新品售价20%或15%的以旧换新补贴，每件补贴不超过2000元。通知同时提出地方优化补贴方式和审核流程，加强数字化管理并同等支持线上与线下两种以旧换新渠道。北京与广州等城市随后发布地方以旧换新补贴方案。'
            },
          ]
        },
      ]
    },
    '202407': {
      articleTitle: '万物新生集团2024年7月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '7月，爱回收于深圳、成都等高线城市净新开10间自营标准店，并在多个优质城市的商场内开设爱回收加盟标准店，为城市合作伙伴进行回收与优品二手零售赋能。'
            },
            {
              subTitle: '',
              text: '爱回收联合更多知名品牌共促绿色消费的“返航新生”。7月，爱回收与奥乐齐联名推出饮料空瓶回收、与雪肌精联合发起化妆品空瓶回收、与哔哩哔哩共同回收闲置数码配件与玩偶。消费者通过领取返航新生任务，兑换品牌伙伴好礼，与爱回收一起实践更多元的环保回收与循环消费。'
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '发改委 财政部 加力支持大规模消费品以旧换新',
              text: '7月，国家发展改革委 财政部印发《关于加力支持大规模设备更新和消费品以旧换新的若干措施》的通知，就统筹安排3000亿元左右超长期特别国债资金，加力支持大规模设备更新和消费品以旧换新，提出相关措施。通知中提出支持包含电脑在内的家电产品以旧换新，并明确补贴标准。'
            },
            {
              subTitle: '爱回收在上海发起社区绿色联动活动',
              text: '近日，爱回收联合22家致力于环保与可持续发展的合作伙伴以及数十家商户，在上海市共同发起“碳循嘉年华”——一场别开生面的绿色联动。园区、社区、校区三区联动，以多样化的用户体验倡导绿色生活方式。通过综合发挥各品牌独特的优势资源，为用户提供更加便捷的回收渠道，共同促进用户绿色低碳行动与消费的认知。'
            },
          ]
        },
      ]
    },
    '202406': {
      articleTitle: '万物新生集团2024年6月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '6月，爱回收净新增10间自营标准店。同期，爱回收加盟标准店净新增13间，在广东、江苏、浙江、河北、贵州、山东等省份加强下沉市场的渗透密度，同时在部分加盟标准店铺设严选样机，加强用户对优品二手商品的感知与购买体验。'
            },
            {
              subTitle: '',
              text: '爱回收奢侈品上门服务全新升级，继手机3C品类上门回收，上海城区率先开通奢侈品上门回收。用户通过线上预约、员工上门、确认价格、等待鉴定、成交打款五个环节舒适便捷地回收家中闲置的箱包腕表等高价值商品。预计下半年，爱回收将于国内更多一线城市开通奢侈品的上门回收服务。'
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '万物新生发布2023年环境、社会、管治报告，连续四年落实负责任披露',
              text: '万物新生集团连续第四年发布环境、社会及管治（ESG）报告，回应用户、员工、投资者、监管机构、社区等关键利益相关方对可持续发展议题的关切。报告披露，2023年内万物新生的温室气体排放强度（范畴一+范畴二）为0.28吨二氧化碳当量/百万元，同比下降约6.67%，连续四年实现碳排放强度下降。集团于2023年回收并监督绿色处置的废旧电子设备超过11万台，减少电子产品污染近18吨。同时加强绿色物流，加强物流中的包装材料绿色循环利用。加强内部审计工作，提升经营过程的规范化和制度化程度，在提升风险管理能力的同时，对SOX内控项目缺陷完成100%整改。'
            },
            {
              subTitle: '拍机堂供应链助力抖音电商与行业伙伴开展二手新零售',
              text: '6月26日，万物新生旗下B2B交易平台拍机堂于南京开展二手电子消费品行业交流大会。大会期间，拍机堂联合万物新生B2C零售平台拍拍与抖音电商落地战略合作签约仪式，拍机堂开放其行业领先的二手消费电子产品供应链能力，赋能更多行业中小商家做好新零售，并在大会期间邀请抖音电商、迪信通、汇通达等多家行业专家为与会商家进行经验分享。近日，该供应链赋能已形成样板店，于国内大型连锁手机3C通讯店试点销售。'
            },
          ]
        },
      ]
    },
    '202405': {
      articleTitle: '万物新生集团2024年5月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '5月净新增爱回收自营标准店10间，主要分布在上海、成都、天津、合肥、武汉等城市的核心商圈商场内，提升对本地市场用户回收服务的密度，加强对优质一手货源的获取能力。同期，净新增爱回收加盟标准店11间，推进西部地区市场的品牌覆盖与行业商家赋能。'
            },
            {
              subTitle: '',
              text: '5月底，随着荣耀品牌的新机发售以及618大促的预热，荣耀商城与爱回收联合开展主题为“耀回收，有惊喜”环保回收活动。通过共同发起的以旧换新与数码配件的环保回收，鼓励消费者尝试更绿色低碳的消费方式。同期，华为商城联合爱回收推广旗舰耳机的以旧换新，并以华为官方补贴鼓励用户参与。'
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '万物新生发布2024年一季度财报，经调整经营利润同比增长超80%',
              text: '5月20日，万物新生集团公布2024年一季度财报，总收入为36.5亿元，同比增长27.1%，达到收入指引区间高端。本季度non-GAAP经营利润为8022万元，同比增长80.8%，连续七个季度实现运营盈利。截至2024年一季度末，万物新生集团现金及现金等价物、受限资金、短期投资及第三方支付平台账户余额等总计26.0亿元。'
            },
            {
              subTitle: '万物新生与京东续签商业合作协议，共同提升二手业务战略发展',
              text: '5月31日，万物新生与京东续签商业合作协议，协议期限为2024年6月1日至2027年12月31日。双方将整合资源，发挥各自优势，在二手业务上继续开展合作，在用户流量、技术支持、物流等多个领域继续合作，共同为二手商品提供优质、有竞争力的价格，提升二手商品交易的用户体验。'
            },
            {
              subTitle: '《人民日报》多篇文章引述万物新生行业参与及领先能力',
              text: '《人民日报》头版<a rel="noopener noreferrer" target="_blank" href="https://www.peopleapp.com/column/30045152007-500005457024">文章</a>《循环利用，让旧手机变废为宝》大篇幅引用万物新生对二手消费电子回收与交易的行业经验、生态能力建设成果。另发布<a rel="noopener noreferrer" target="_blank" href="https://wap.peopleapp.com/article/7426845/7257473">文章</a>《让企业专心创新、安心发展（法治头条）》，引述电子产品合规翻新指引发布以来得到的市场检验成果，助力万物新生等企业实现有效合规业务的增量。'
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '苹果将在马来西亚开设第一家零售店以继续扩张亚洲市场',
              text: '苹果公司将于6月份开启在马来西亚的第一家零售店，以扩大其在东南亚的业务，增加在亚洲的销售和运营。该公司在马来西亚的第一家零售店位于吉隆坡的 The Exchange TRX 购物中心，将于当地时间 6 月 22 日上午 10 点开业。'
            },
          ]
        },
      ]
    },
    '202404': {
      articleTitle: '万物新生集团2024年4月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '爱回收联合多个消费品牌共同发起循环消费公益倡议。与蒙牛开展世界地球日环保公益活动，落地街道社区，鼓励居民用户对可持续循环消费的参与，并达成ESG战略合作，共促绿色低碳发展。与谷雨、全棉时代、盒马携手，分别号召用户护肤品空瓶、旧衣、纸类回收“返航新生”。'
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '任命周晔为万物新生集团董事',
              text: '4月26日，京东集团副总裁、集团战略投资负责人周晔加入万物新生集团董事会，并参与薪酬委员会及提名与公司治理委员会，接替由于辛利军辞任相关职务所产生的空缺。本次董事任命体现万物新生对卓越战略增长的承诺。同时，万物新生集团对辛利军在任期内的重要贡献致以由衷感谢。'
            },
            {
              subTitle: '海南国际消费季，万物新生总裁陈晨分享二手商品流通新实践',
              text: '4月14日，海南国际消费季主题活动在海口举办，聚焦绿色流通，万物新生（爱回收）集团总裁陈晨围绕二手商品流通作主题发言。“在国家政策引领下，万物新生作为市场主体，助推二手商品流通在‘以旧换新’中发挥重要作用，让消费者可以更方便地处理二手闲置设备并获得相应价值回报，为‘换新’提供消费助力。”陈晨同时讲述了万物新生集团以新模式和新技术推动行业规模化、标准化和数智化发展的创新实践。'
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '继中央印发消费品以旧换新通知，多地地方印发行动计划',
              text: '以上海为例，上海市人民政府关于印发《上海市推动大规模设备更新和消费品以旧换新行动计划（2024-2027年）》的通知，提出“完善提升回收循环利用体系”，并支持二手商品流通交易。该计划提出“推动二手电子产品交易规范化，探索制定电子产品信息清除标准规范，防范泄露及恶意恢复用户信息。支持二手商品交易线上平台和线下市场建设…”'
            },
          ]
        },
      ]
    },
    '202403': {
      articleTitle: '万物新生集团2024年3月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '万物新生海外业务部AHS Device取得新的企业合作，与日本一家领先商社达成合作，在日本线下铺设升级版自助回收机，测试新的用户回收合规流程，并验证二手手机3C自动化质检解决方案在境外市场的适配能力。'
            },
            {
              subTitle: '',
              text: '拍机堂调整部分适合toC零售的靓机成色标准，部分手机与平板商品的成色标准下探，开放更多高等级成色货源，以适配商家的零售货源批量采购需求。'
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '万物新生发布2023年四季度及全年财报与2,000万美元回购计划',
              text: '2023年四季度收入超指引高端达成38.7亿元，同比增长29.9%，首次实现净利润278万，并连续6个季度实现经调整经营利润。在全年层面，集团总收入同比增长31.4%至129.7亿元，经调整经营利润突破2.5亿元，同比2022年经调整经营利润690万元显著提升。集团同期宣布2,000万美元的股票回购计划，有效期为12个月，展现管理层对集团业绩发展的信心。'
            },
            {
              subTitle: '中国环境报专题采访万物新生，报道二手电子产品交易市场发展',
              text: '中国环境报就二手电子产品交易市场的现状、面临的挑战以及未来的发展方向对万物新生进行了专题采访与<a rel="noopener noreferrer" target="_blank" href="https://res.cenews.com.cn/h5/news.html?id=1121922">报道</a>。万物新生集团副总裁田牧从回收端、处置端、销售端对产业链存在的问题与万物新生核心能力进行讲解分析，并强调用户隐私清除与信息安全保护是企业方的重要责任。'
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '国务院印发《推动大规模设备更新和消费品以旧换新行动方案》的通知',
              text: '今年3月，国务院发布关于印发《推动大规模设备更新和消费品以旧换新行动方案》的通知，实施回收循环利用行动，加快“换新+回收”物流体系和新模式发展，支持二手商品流通交易并推动二手电子产品交易规范化。'
            },
          ]
        },
      ]
    },
    '202402': {
      articleTitle: '万物新生集团2024年2月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '关键事件',
          list: [
            {
              subTitle: '对话中欧商学院，万物新生畅谈循环经济',
              text: '微纪录片<a rel="noopener noreferrer" target="_blank" href="https://m.chinanews.com/wap/detail/chs/sp/10155339.shtml">《师想者来了》</a>第四期，中欧国际工商学院金融与会计学教授芮萌与万物新生(爱回收)集团执行总裁王永良走进身边的循环经济，畅谈企业商业价值与社会和环境价值的并行成长。访谈中，万物新生分享以企业创新拓展目标用户，丰富性别、年龄等维度的用户画像，引导社群消费习惯的绿色升级的领先实践。',
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '中央财经委员会议鼓励设备与消费品大规模以旧换新',
              text: '2月24日，时值两会前夕，习主席主持召开中央财经委员会第四次会议，主持研究并鼓励引导新一轮大规模设备更新和消费品以旧换新等议题。会议指出要推动耐用消费品以旧换新，推动大规模回收循环利用。对消费品以旧换新，要坚持中央财政和地方政府联动，统筹支持全链条各环节，更多惠及消费者。'
            },
          ]
        },
      ]
    },
    '202401': {
      articleTitle: '万物新生集团2024年1月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '集团预备“春节不打烊”整体方案。春节期间，C2B保持二手3C及主要多品类回收业务全渠道运营，标准店目标在春节假期实现90%的正常营业时间；拍机堂正常开放暗拍、批发、零售、备件库的拍卖场次，为商家在节日期间的采销需求提供保障；拍拍制定备货与节日操盘方案，为用户提供不间断且充足的优品购买选项。',
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '中央电视台采访报道万物新生商业模式、供应链技术能力与行业规范表率',
              text: '万物新生的二手消费电子回收与循环模式获得央视新闻频道的现场采访与<a rel="noopener noreferrer" target="_blank" href="https://mp.weixin.qq.com/s/JLWSiGXDRpGls_-TyFpIpQ">报道</a>。其中，万物新生安全、便捷的回收和以旧换新服务能力获得消费者认可，由集团进行自主技术研发升级的东莞自动化质检运营中心的高效质检和流转效率得到现场探访和重点关注。',
            },
            {
              subTitle: '人民日报报道旧手机物尽其用的统计数据',
              text: '<a rel="noopener noreferrer" target="_blank" href="http://paper.people.com.cn/rmrb/html/2024-01/05/nw.D110000renmrb_20240105_1-07.htm">报道</a>中引述了万物新生执行总裁王永良对废旧手机回收产业规范化发展的建议。',
            },
            {
              subTitle: '万物新生参加逆向低碳联盟会议并解读二手3C检测标准',
              text: '逆向物流与低碳供应链产业联盟2024年年会在上海召开，会上发布了《二手3C产品检测定级规范手机》和《二手3C产品检测服务规范》两大企业联合标准。万物新生集团作为联盟理事长单位参与会议，并作为两大标准的主要起草方对标准内容进行了详细介绍和解读。本次年度会议的召开有望加快推进供应链绿色低碳转型，助推二手消费电子行业标准化发展。',
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: 'IDC报告显示中国智能手机出货出现反弹',
              text: '据市场调研机构IDC发布的<a rel="noopener noreferrer" target="_blank" href="https://www.idc.com/getdoc.jsp?containerId=prAP51817224">报告</a>，2023年第四季度，中国智能手机市场出货量约为7363万台，同比增长1.2%，在10个季度连续同比下降后首次实现反弹。此外，目前市场需求主要来自一至三线城市，销售较好的产品包括苹果iPhone 15、华为Mate 60、小米14、vivo X100等品牌的旗舰系列。从市场份额看，根据IDC数据，苹果在2023年全年及第四季度均排名中国智能手机市场出货量第一。'
            },
          ]
        },
      ]
    },
    '202312': {
      articleTitle: '万物新生集团2023年12月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '12月，爱回收净新开29间自营标准店，主要分布在北京、广州、上海、成都、武汉、南京、郑州等高线城市的核心商场。同期，净新增爱回收加盟标准店11间，通过渗透华北与西北大区重点城市的主流购物广场服务更多用户的回收需求。',
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '万物新生获评《证券日报》“2023上市公司ESG创新实践案例”',
              text: '12月，由《证券日报》社主办的“2023上市公司环境、社会及公司治理交流大会”在北京举行，评选优秀的ESG创新实践案例。该评选致力于推动建立健全本土ESG生态体系，并为资本市场提供专业、权威的投资标尺，鼓励企业绿色经营，帮助企业挖掘内生动力，树立行业内优秀标杆，引领新时代经济社会高质量发展。万物新生以“互联网+环保”创新的ESG商业实践成为获评案例之一。',
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: 'Counterpoint报告显示全球高端智能手机市场2023年再创新高',
              text: '根据Counterpoint Research发布的手机销量月度<a rel="noopener noreferrer" target="_blank" href="https://mp.weixin.qq.com/s/-B62gjuxOY4ydKLE-N6QoA">报告</a>，全球高端智能手机（批发价≥600美元）市场的销售额预计将在2023年同比增长6%，创下新的纪录，与今年整体全球智能手机市场下滑预期形成对比。高端市场预计将占2023年全球智能手机市场销量近四分之一，收入占60%。报告表示，苹果仍然是高端市场的绝对领导者，中国、印度、中东和非洲以及拉丁美洲预计将在2023年创下高端市场销售新纪录。',
            },
            {
              subTitle: 'IDC洞察：2024年中国智能终端市场购买向“平替”“二手”倾斜',
              text: '近日，IDC发布2024年中国智能终端市场十大<a rel="noopener noreferrer" target="_blank" href="https://www.idc.com/getdoc.jsp?containerId=prCHC51425923">洞察</a>，预测未来人群消费特征更加注重价值回归。用户在终端消费上将更加倾向“既要省又要花”的理念，更加谨慎和注重性价比。随着市场信息的透明化和购买渠道的拓宽，用户购买的考虑因素将叠加“平替”“二手”等多重影响。'
            },
          ]
        },
      ]
    },
    '202311': {
      articleTitle: '万物新生集团2023年11月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '爱回收自营标准店在11月净新增3间，新开门店主要位于长沙和北京等城市。加盟标准店净新增6间，主要位于广东及川渝地区。',
            },
            {
              subTitle: '',
              text: '自今年11月起，爱回收获得苹果内地官方体系内iPhone产品N-1、N-2代的回收资格—— 即用户在苹果直营渠道用iPhone 13、iPhone 14系列产品进行回收换新，苹果门店店员在完成前端回收后将旧机交由爱回收处置。苹果官方体系内回收资格的更新体现了品牌对爱回收供应链能力的认可。此外，今年用户的回收和以旧换新的热情高涨，“双十一”大促开门红期间，爱回收C2B回收额同比显著增长60%。',
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '万物新生循环经济案例入选新华信用金兰杯ESG优秀案例',
              text: '11月13日，第二届新华信用金兰杯ESG优秀案例名单<a rel="noopener noreferrer" target="_blank" href="https://www.cnfin.com/xy-lb/detail/20231113/3964158_1.html">正式发布</a>，万物新生以“扎根循环经济赛道，助力实现碳达峰碳中和目标”入选ESG实践优秀案例。此次案例征集由中国经济信息社主办，国内外各领域各行业的企业和机构踊跃报名，征集案例涵盖能源、电力、汽车、物流运输、互联网等多个行业。',
            },
            {
              subTitle: '万物新生发布2023年第三季度财务报告，总收入与经调整利润超预期达成',
              text: '11月22日，万物新生发布三季度财务报告，总收入同比增长28.4%至32.6亿元人民币，突破指引区间高端，二手逆周期快速发展。同时公布经调整经营利润达7380万元，同比增长585.5%，经调整经营利润率2.3%，季度利润超预期达成。自营翻新业务达成2亿销售规模，多品类回收GMV规模达3.5亿，为用户贡献更多价值。',
            },
            {
              subTitle: '集团获评路透社“2023年全球商业责任奖”',
              text: '万物新生集团在路透社（Reuters）2023年全球商业责任奖（Responsible Business Awards 2023）评选中取得突出表现。在“循环过渡奖”（Circular Transition Award）评选中，万物新生与甲骨文（Oracle）、施耐德电器（Schneider Electric）、百事（PepsiCo）等一众世界知名企业展开角逐，获得了评委会“高度赞扬（highly commended）”荣誉。',
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '高盛发布11月中国智能手机报告',
              text: '近日高盛发布中国智能手机月报，11月畅销机型的平均售价为515美元，高于5月的260美元，显示高端手机在2023年下半年有更好的销售势头。此外，11月热销机型的平均售价相比新机发布的平均价格有15%的折扣，优于5月热销机型17%的销售折扣幅度。',
            },
            {
              subTitle: '闲鱼升级为阿里巴巴集团第一批战略级创新业务之一',
              text: '在11月16日的财报电话会上，阿里巴巴集团CEO吴泳铭公布了阿里第一批战略级创新业务——1688、闲鱼、钉钉、夸克。上述战略级创新业务以独立子公司的方式运营，打破以往在集团内的定位限制，用更独立的策略去面对最广阔的市场。集团将以3至5年为周期进行持续投入。'
            },
          ]
        },
      ]
    },
    '202310': {
      articleTitle: '万物新生集团2023年10月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '10月，上海、青岛、成都等城市共计净新增10间“爱回收严选”自营店，在同一家店里整合了二手回收与优品销售能力。同期，在浙江、广东、江西省内多个地级市净新开9间爱回收加盟标准店。新增门店服务当地热门休闲购物商圈，夯实爱回收品牌在全国市场的用户心智。',
            },
            {
              subTitle: '',
              text: '近日，爱回收多品类战略取得积极进展，全国单月回收业绩突破1亿元，这是基于全国门店分层运营策略达成的。此外，今年10月，上海与北京的单城月度回收业绩规模突破1亿元里程碑。两个里程碑印证了循环经济的发展潜力，也是用户对爱回收品牌的信任与选择。',
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '万物新生入围国家循环经济标准化试点示范项目',
              text: '万物新生以“二手3C电子产品再利用”项目申报2023年国家循环经济标准化试点示范项目。经专家评审与相关部门意见征求后，得到国家标准化管理委员会公示，是“废弃电器电子产品回收利用”领域唯一涉及二手3C再利用的项目。',
            },
            {
              subTitle: '爱回收联合回力打造“旧鞋返航新生”',
              text: '项目旨在减少被随意丢弃的旧鞋造成的环境污染，促进更多可回收材料得以循环再生。北京、上海、深圳、杭州、成都共有35间爱回收指定门店，用户可以带任意品牌的旧鞋到店回收，并获得爱回收环保袋和抽取回力好礼的机会。该联名活动促进更多用户参与闲置商品的环保回收。',
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '深圳成立中国二手电子交易中心',
              text: '为促进二手电子产品的资源循环利用，深圳构建集批发零售于一体的二手电子产品展示交易平台，引进多家互联网企业在华强北加强平台与线下产业的融合。万物新生旗下爱回收、拍机堂、拍拍作为核心企业入驻，协助二手电子交易市场的合规、标准化发展。',
            },
            {
              subTitle: '市场研究机构CCS Insight发布对二手 iPhone 的预测',
              text: '根据 CCS Insights 最近发布的<a rel="noopener noreferrer" target="_blank" href="https://www.ccsinsight.com/company-news/ccs-insights-predictions-for-2024-and-beyond-revealed/">预测</a>，到 2023 年底，在13亿使用中的iPhone里，将有超过 50% 是二手设备。从 2024 年开始，iPhone 的平均寿命为八年。 相比之下，二手 Android 智能手机的数量不及 Android设备使用总数的25%。',
            },
          ]
        },
      ]
    },
    '202309': {
      articleTitle: '万物新生集团2023年9月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '本月，爱回收严选自营标准店净新增9间，主要位于杭州、长沙、东莞等地的热门商场商圈，并在西南、华中等地区的中线城市净新增14间加盟标准店，共同提升爱回收严选品牌在全国各城市核心商业区域的用户心智，为用户回收更多闲置商品，促进二手商品的回收流转。',
            },
            {
              subTitle: '',
              text: '爱回收在支持戴森官方回收、以旧换新、翻新机销售的业务基础上，新增支持美发产品的以旧换新，并推进用户在购买戴森品牌的新产品时，使用手机或其他品牌的小家电进行以旧换新，加强双方在绿色循环消费领域的深度合作。',
            },
            {
              subTitle: '',
              text: '2023 拍拍二手招商大会重庆站顺利召开，旨在为商家提供线上专家社群答疑同时，开展线下专业能力提升训练营，并提供流量扶持、品类优惠等新商家孵化政策支持，为更广泛商家提供二手商品运营赋能。',
            },
            {
              subTitle: '',
              text: '拍机堂上线笔记本零售专场，每天下午开放明拍、竞价销售，商品涵盖多个主流品牌的热门型号，便于商家采买品质更好、适合直接toC零售的笔记本货源。同期升级POP预付款，基于商家体量与质量等级的基础额度，为优质商家全新升级临时额度，商家亦可基于体量自行调整保证金档位，灵活管理资金需求。',
            }
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '万物新生加入联合国全球契约组织，领先二手行业做出可持续发展承诺',
              text: '万物新生集团宣布加入联合国全球契约组织（United Nations Global Compact，简称UNGC），承诺支持关于人权、劳工、环境和反腐败四个领域的十大原则。万物新生成为国内二手消费电子行业内首家加入该国际组织的成员。',
            },
            {
              subTitle: '爱回收积极参与社会公益，提倡电子助学与环保回收',
              text: '爱回收携手腾讯99公益日准备了“让回收变善举”的小红花活动，在爱回收完成任意回收即可获得小红花，用于支持“回收爱”数码助学公益计划，捐助闲置电子数码设备，为山村儿童充实教育资源。同期支持世界清洁日，呼吁中国居民参与垃圾捡拾与分类，关注环保责任。',
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '上海市生态环境局印发《上海市碳普惠管理办法（试行）》',
              text: '9月25日，上海市生态环境局制定并向各区印发了《上海市碳普惠管理办法（<a rel="noopener noreferrer" target="_blank" href="https://sthj.sh.gov.cn/hbzhywpt2022/20230927/78a437ce9f7b4f30bc50b4fc11fc5d0b.html">试行</a>）》，为推动形成绿色低碳生产生活方式，保障上海市碳普惠体系规范、有序运行。',
            },
          ]
        },
      ]
    },
    '202308': {
      articleTitle: '万物新生集团2023年8月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '本月，爱回收自营标准店净新增5间，主要位于新一线城市，丰富主流运营城市的回收服务覆盖。同期在全国其他地区净新增与自营店形象和服务标准保持一致的加盟标准店11间，提升安徽、河南、四川等地的三线城市能力覆盖。',
            },
            {
              subTitle: '',
              text: '拍机堂商家服务能力升级，借力机大侠便携质检终端提升批量采购入库管理能力。机大侠为拍机堂用户专项研发一件入库功能，每日及时同步用户在拍机堂采购的货品清单，进行批量入库、库存管理、销售管理、经营分析，帮助用户节省入库时间与人工成本，提高生意效率。',
            }
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '万物新生发布2023年第二季度财务报告，收入增长与利润释放再超预期',
              text: '8月23日，万物新生集团发布2023年第二季度财务报告，公布截至6月30日的经营与财务动态。其中，全国269座城市共布局1944间爱回收严选门店，积极满足主要城市用户的数码3C的回收与以旧换新需求，同时升级特色店提升高值多品类回收服务能力。二季度总收入29.6亿元，同比增长38.1%超指引高端。经调整经营利润5200万元，经调整经营利润率达到1.8%，在连续四个季度报告盈利的基础上再次突破利润记录，得益于自动化技术效率提升所带来的履约费用率的优化。',
            },
          ]
        },
      ]
    },
    '202307': {
      articleTitle: '万物新生集团2023年7月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '本月，爱回收自营标准店净新增31间，主要来自长沙和石家庄的加盟门店转自营，以加强区域市场内的运营管理效率，提升门店覆盖用户的回收交易体验。',
            },
            {
              subTitle: '',
              text: '自有零售产品品牌“拍拍严选”正式更名为“爱回收严选”，范围覆盖优品二手手机、平板、笔记本、智能数码、腕表奢侈品等。本次品牌升级旨在加强对C端用户的服务能力整合，强化“爱回收”主品牌的用户心智，“拍拍”品牌主要指B2C平台服务。此前，万物新生集团已完成内部的前端组织架构调整及运营能力的更新升级。',
            }
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '万物新生2023届管培生项目启动',
              text: '新一届管培生于7月初顺利入职并完成一周集训，专业背景涵盖信息管理与信息系统、计算机应用工程、物流管理等。集训后进入业务、职能部门、项目组，进行为期近六个月的轮岗实践，最终将于12月考核定岗，完成管培生成长地图，作为新生血液正式加入万物新生集团。',
            },
            {
              subTitle: '万物新生入选上海市“质量标杆”（2022-2023） 建议名单',
              text: '据工信部相关要求，上海市经济和信息化委员会组织开展了质量标杆的申报和遴选工作，并于近日完成评审遴选，形成上海市“质量标杆”（2022-2023）建议名单。万物新生凭“二手3C产品‘流程质检一体化+智慧管理数字化’的实践经验”入选。',
            },
            {
              subTitle: '入围路透社“2023年全球商业责任大奖”',
              text: '近日，路透社（Reuters）公布“2023年全球商业责任大奖”（Responsible Business Awards 2023）的入围企业名单，万物新生凭借行业领先的、创新的、规模化的循环商业模式，推动商业从线性模式到循环模式的转型，成为唯一入围“循环过渡奖”（Circular Transition Award）的中国企业。同时入围该奖项的企业有施耐德电气Schneider Electric、甲骨文Oracle、百事公司PepsiCo等。',
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '国务院办公厅转发国家发展改革委关于恢复和扩大消费措施的通知，提升电子产品消费、推广绿色消费',
              text: '7月31日，国务院办公厅转发国家发展改革委<a href=https://www.gov.cn/zhengce/content/202307/content_6895599.htm>《关于恢复和扩大消费的措施》</a>，共七方面、20条。该文件在稳定大宗消费方面提出“提升家装家居和电子产品消费”，其中明确鼓励“推进电子产品升级换代”、“深入开展家电以旧换新”。在拓展新型消费方面提出“推广绿色消费”，明确“积极发展绿色低碳消费市场”及“健全节能低碳和绿色制造标准体系”。',
            },
          ]
        },
      ]
    },
    '202306': {
      articleTitle: '万物新生集团2023年6月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '新开店与多品类回收能力升级同步进行。6月内，爱回收自营标准店净新增5间，服务核心商业区用户的手机数码、箱包腕表、黄金首饰等多品类的循环回收需求，进一步打造爱回收主品牌“为你回收更多”的服务标杆。6月内，加盟标准店净新增9间，同时对合作店做调整并主要在华北地区下沉铺设新的触点。',
            },
            {
              subTitle: '',
              text: '拍机堂6月16日正式上线POP授权商家48小时保卖，符合交易体量及信誉分等级的商家用户可以申请成为“授权商家”，满足型号、等级、属性要求的二手手机商品可以享受48小时保卖，商家可按照确认的保卖价格享受高速资金周转。',
            },
            {
              subTitle: '',
              text: '6月30日，万物新生旗下C2B回收品牌“爱回收”正式登陆苹果在中国大陆的官网与官方旗舰店，成为第二家官方以旧换新与年年焕新服务供应商，在苹果为中国大陆用户安全、便捷地回收换新过程中提供后端供应链支持。爱回收为合作伙伴提供有吸引力的回收价格，满足更多用户对高端智能手机的轻松换新需求，以及对美好生活、绿色消费方式的追求。',
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '万物新生发布2022年度ESG报告',
              text: '6月20日，万物新生发布第三份年度<a rel="noopener noreferrer" target="_blank" href="https://www.atrenew.com/resources/downloads/2022_ESG_Report_CN.pdf?_v=1.0.0_5624112">ESG报告</a>，向各界利益相关方披露集团在环境、社会及管治三个方面的重点绩效。在环境方面，万物新生因外购电力产生的温室气体排放强度连续3年下降，在2022年回收并监督处置废旧电子设备27万台，减少电子产品污染43.2吨，回用toB包裹填充物18吨，回用toC物流包装3.6万个。在社会方面，通过ISO 9001质量管理体系认证，持续关注就业与人才培养，为超过8,000家拍机堂中小商家提供专项培训与赋能，通过多项社会公益项目累计捐赠100万元人民币。在管治方面，持续加强风险管理与事故管理能力，开展内部审计并完成90%的缺陷整改，在顶层治理方面进一步多元化董事会结构（目前独立董事占比37.%，女性董事占比25%）。',
            },
            {
              subTitle: '万物新生入选中国连锁经营协会的“2022年生活服务业连锁企业TOP100”榜单',
              text: '中国连锁经营协会(CCFA)联合多家本地生活服务机构首次推出并共同开展了“2022年生活服务业连锁企业TOP100”统计调查工作，涵盖近20个生活服务细分行业，并于6月15日的CCFA新消费论坛上公布榜单。万物新生作为回收与维修行业代表入选该<a rel="noopener noreferrer" target="_blank" href="https://mp.weixin.qq.com/s/lmSYEmjr2DKdb6gSexxDhg">榜单</a>。',
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: 'Counterpoint发布白皮书，2022年中国高端智能手机市场份额提升',
              text: '6月30日，Counterpoint发布<a rel="noopener noreferrer" target="_blank" href="https://www.counterpointresearch.com/wp-content/uploads/2017/06/China-Premium-Smartphone-White-Paper.pdf">《中国智能手机高端市场白皮书》</a>，尽管大盘面临压力，中国高端智能手机销量整体呈上涨态势，在 2022 年，中国高端手机销量占到了整体市场的 26%以上，其中超高端（批发价≥700 美元）市场的份额较 2021 年有明显提升。围绕超高端市场的竞争愈发激烈，苹果仍然占据了七成以上的份额。',
            },
          ]
        },
      ]
    },
    '202305': {
      articleTitle: '万物新生集团2023年5月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '5月，爱回收于上海、深圳、大连等地净新开自营标准店5间，在西北、华北等区域进一步落实联营标准店的网络建设及下沉市场的合作店开拓，提升本地通讯市场商家伙伴的标准回收能力和用户服务可及性。',
            },
            {
              subTitle: '',
              text: '拍机堂于5月29日上线华为备件库商品，即品牌7天无理由退货、经品牌检测后的良品，其中少量商品经品牌维修焕新。每天15点至18点进行统货明拍，提供手机、平板、笔记本、手表、耳机、充电器等商品，以品类多、品质好、价格优的独家货源丰富拍机堂商家用户的竞拍选项。',
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '万物新生发布2023年第一季度财务报告',
              text: '5月23日，万物新生集团发布2023年第一季度业绩，集团总收入同比增长30.2%至28.7亿元，同时再次突破收入指引高端。其中，自营业务恢复显著，对应的产品收入同比增长34.9%，得益线下场景、物流的快速恢复，及1.45亿元自营货源的合规翻新收入贡献。服务收入对应的平台收费率对比去年同期4.15%显著提升至5.46%，平台业务提升健康度、保证高质量发展。经调整经营利润超4400万元、利润率1.5%，均为单季新高，得益于自动化效益释放下的履约费用效率提升、营销控费。CEO Kerry在业绩电话会上提及2023年下半年“全球头部的手机厂商在以旧换新业务上的深度合作”，将为集团整体业务带来新的增量。',
            },
            {
              subTitle: '618大促启动，回收与以旧换新再发战报',
              text: '万物新生启动618回收换新活动，5月31日0:00至6月7日23:59期间，C2B整体回收成交额同比增长62%，3C数码一站式以旧换新成交额同比增长103%，大区交付连续三天突破历史记录。以安全的高价回收、便捷的低价换新，万物新生进一步推动C端用户低碳购物新趋势的发展。',
            },
          ]
        },
      ]
    },
    '202304': {
      articleTitle: '万物新生集团2023年4月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '爱回收于4月增加10间加盟标准店，主要在长沙、保定、哈尔滨等多个城市核心商圈提升回收与履约能力共建。同时净新增17间合作店，加强保定、石家庄、福州、汉中等地通讯市场的数码店的回收标准赋能。',
            },
            {
              subTitle: '',
              text: '拍机堂授权质检全新升级，筛选拥有专业质检人员、质检设备、全流程录像、退货率低于官方的商家进入授权质检，对质检人员进行定期培训和考试，对商家物品定期抽查，对不合格商家做清退处理，保证物品质检质量，强化对买家权益的保障。',
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '万物新生发布2022年年度报告',
              text: '4月18日，集团以20-F表格形式向美国证券交易委员会（“SEC”）提交2022财政年度报告。完整20-F表格可以通过SEC网站 <a rel="noopener noreferrer" target="_blank" href="https://www.sec.gov">https://www.sec.gov</a> 或集团投资者关系网站 <a rel="noopener noreferrer" target="_blank" href="https://ir.atrenew.com">https://ir.atrenew.com</a> 获取。',
            },
            {
              subTitle: '迎世界地球日，爱回收发起多项倡议，呼吁绿色环保回收',
              text: '爱回收启动“宇宙回收联盟”，与国内领先的消费品牌共同呼吁保护地球，通过体验回收换礼等活动倡导绿色生活方式。爱回收同时联合凯德中国在成都、西安、深圳三地发起环保数码服务、多品类回收加价券发放、闲置旧衣鞋服自助回收投放等活动，鼓励用户进行循环消费。',
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '深圳举办资源创新产业论坛，发布多项电子产品翻新团标',
              text: '4月26日，深圳市电子行业协会举办资源创新产业高质量发展论坛，发起电子产品翻新产业专业委员会，推动产业健康、持续、稳定发展，同期针对电子产品翻新通用要求、标识及信息披露、质量管理、技术规范等多个方面发布团体标准。万物新生作为主要起草单位参与此系列团标的制定。',
            },
          ]
        }
      ]
    },
    '202303': {
      articleTitle: '万物新生集团2023年3月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '3月，爱回收门店继续增加市场覆盖，联营-店中店继续渗透服务于中低线市场，包括保定、石家庄、丽水等城市。并在月底于深圳成功举办2023年合作伙伴大会，由管理团队、商家代表进行分享。爱回收为加盟商做选址、陈列、标准赋能，共同提升用户回收服务能力。',
            },
            {
              subTitle: '',
              text: '拍机堂升级POP预付款规则，根据平台商家（买家和卖家）的信用等级调整保证金档位，并依该等级将此前固定的平台预付款倍数调整为浮动倍数。交易体量、信誉星级越大则浮动倍数越大，商家能够获得的预付款倍数则越高，交易的资金周转效率则越高。新规则于4月6日生效，预计这一新规将有助于加强平台持续交易商家的用户粘性、筛选交易效率低或交易不规范的商家，从而进一步优化平台的用户结构。此外，拍机堂为卖家升级保卖服务，在按照平台提供的参考价保卖的同时，卖家销售款即刻到账，售后问题由平台承接处理，提升卖家的销售和资金周转效率。',
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '万物新生发布2022年Q4及全年财报，盈利与现金流等多项指标取得突破',
              text: '3月13日，万物新生集团发布2022年四季度及全年财务报告，达成多个收入与盈利里程碑，包括全年收入同比增长26.9%至98.7亿元，全平台回收与交易二手商品数量达3,200万台，首次实现全年盈利：non-GAAP经营利润690万、non-GAAP净利润盈利超5,080万。年内连续四个季度取得正向经营性现金流入，全年现金流净流入2亿元。截至2022年12月31日，全国在266座城市分布1,920间线下门店。质检运营能力覆盖8大区域级运营中心，华南（东莞）自动化运营中心于四季度投入使用，进一步提升集团整体自动化质检效率，当季non-GAAP履约费用率下降2.8个百分点至8.7%，降本增效成果落地。<br/>集团同时宣布，加强与全球领先的手机消费电子品牌的以旧换新战略合作，预期延展集团1P回收供应链对零售场景的服务能力，提升一、二线城市回收渗透率。',
            },
            {
              subTitle: '央视新闻报道关注循环经济，二手电子商品流转交易值得倡导',
              text: '近日，央视财经《经济半小时》报道循环经济下的二手电子商品交易，引用万物新生集团爱回收的商业模式。报道中表示，让闲置二手物品流转起来用起来，是值得倡导的绿色生活方式，也是方兴未艾的新市场，亟待开发的新蓝海。新闻报道视频请参考“万物新生新鲜发布”微信视频号。',
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '政府工作报告：推动发展方式绿色转型，重点领域节能降碳',
              text: '据《第一财经》<a rel="noopener noreferrer" target="_blank" href="https://m.yicai.com/news/101692627.html">报道</a>，时任国务院总理李克强于3月5日在十四届全国人大一次会议上作政府工作报告时指出，今年推动发展方式绿色转型，完善支持绿色发展的政策，发展循环经济，推进资源节约集约利用。',
            },
          ]
        }
      ]
    },
    '202302': {
      articleTitle: '万物新生集团2023年2月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '本月内，爱回收仍采用以加盟合作店为主的开店策略，主要在华北下沉市场增设合作店点位，同时对少量标准店进行点位调整，优化区域客群覆盖效率。同期在试点多品类回收的门店上线潮鞋品类回收，通过前端爱回收门店的服务能力+后端能力共建的方式打开服务试点，增加多品类回收能力。',
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '万物新生2023开年大会暨2022年年度评优表彰',
              text: '2月21日，万物新生延续开年大会传统，由总经办主持开年大会及年度评优表彰。集团总裁王永良代表总经办进行年度发言，回顾2022年集团在疫情挑战下做减法、聚焦核心回收业务的决策历程，在逆风中抠经营细节、打磨各项业务的财务模型，并重申2023年核心战略：通过城市模型持续提升手机3C主品类的回收渗透率、加强合规维修整备完善产业链价值、放大国内领先回收品牌“爱回收”的价值、持续投入并放大自动化效能。',
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '上海印发2023年应对气候变化工作要点',
              text: '<a rel="noopener noreferrer" target="_blank" href="https://news.bjx.com.cn/html/20230221/1290031.shtml">《要点》</a>指出，逐步健全本市碳普惠体系。组建碳普惠管理中心与运营中心，搭建碳普惠系统平台,研究出台配套政策，开发能源、交通等领域项目场景，建立试点示范，将碳普惠核证减排量纳入本市碳市场补充抵消机制，初步实现与本市碳市场闭环运行。加强区域协作，积极推动长三角区域碳普惠联建工作。',
            },
          ]
        }
      ]
    },
    '202301': {
      articleTitle: '万物新生集团2023年1月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '爱回收制定全年开店目标，并在1月有序迎接农历春节期间，阶段性调整开店节奏，月内全国净新增12间爱回收门店，主要分布在华北及西北地区。',
            },
            {
              subTitle: '',
              text: '在2023年1月21日至1月27日春节假期期间，万物新生中国大陆核心业务实行 “春节不打烊”。爱回收与拍拍线下门店配合各商场安排有序调整营业时间，在回收业务侧保障用户到店、上门、快递回收全回收渠道正常开启，并为用户在期间提交的订单提供十天保价。爱回收门店自2021年启动“春节不打烊”，该活动在今年增设奢侈品、黄金、名酒、钻石等多品类，不间断为用户“除旧迎新”、变现提供更多便利。拍机堂核心拍卖场次照常进行，拍拍线上业务持续5年“春节也送货”，平台业务客服与售后人员在线值班，运营中心与城市小仓正常安排收发货，帮助中小商家实现源源不断的收入，通过拍拍为消费者提供“省薪过新年”的优品二手购买服务。'
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '万物新生举办季度头条会，Kerry分享年度总结与开年战略',
              text: '1月17日，万物新生连线全国各地办公室举办全员头条会，分享各事业部近期要闻，集团创始人兼CEO Kerry进行年度总结与战略分享，同时进行四季度业务/文化评优表彰。在展望2023年战略目标时，Kerry提及三个要点：1）将“爱回收”打造为国内第一回收品牌，并为品牌赋予爱和温度，通过拓展非手机3C的高值品类商品回收，为集团打开增长与利润空间；2）继2022年底完成华南东莞运营中心的自动化升级，持续投入自动化技术（RERE InnoTech），让自动化技术驱动万物新生高效且有质量的发展；3）集团孵化的绿色城市产业链业务“爱回收·爱分类”商业模式得到验证，重回万物新生大家庭，目标提升城市端盈利能力、以创新的“互联网+回收”业务模式彰显资源节约与低碳普惠价值。',
            },
            {
              subTitle: '万物新生荣获中国网“年度品牌影响力企业”称号',
              text: '中国互联网新闻中心（中国网）发起“2022中国网•科技企业先锋榜”评选，通过对科技企业的价值创造能力、社会绩效、环境绩效、治理绩效、信息披露等多项指标考核，遴选40家具有领先行业影响力和创新力的科技企业。其中，万物新生名列“年度品牌影响力企业”榜单。',
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '国务院新闻办发布《新时代的中国绿色发展》白皮书，鼓励二手交易蓬勃发展',
              text: '1月19日，国务院新闻办公室发布《新时代的中国绿色发展》白皮书（<a rel="noopener noreferrer" target="_blank" href="http://www.gov.cn/zhengce/2023-01/19/content_5737923.htm">全文</a>），介绍新时代中国绿色低碳发展理念、实践与成效。据白皮书，循环经济推动绿色生活方式渐成时尚，并提出“鼓励推动共享经济、二手交易等新模式蓬勃发展，绿色消费品类愈加丰富，绿色消费群体持续扩大”。',
            },
          ]
        }
      ]
    },
    '202212': {
      articleTitle: '万物新生集团2022年12月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '12月，爱回收于华北与西北等市场净新开12间联营标准店，于西南与华北等地净新开18间联营合作店。截至12月末，全国爱回收联营店布局突破1,300间，于中低线城市提升近一倍的回收网络密度，让爱回收品牌更深入人心，在提升C端消费者回收意识、回收渗透率的同时，便捷商家端用户获取优质货源。',
            },
            {
              subTitle: '',
              text: '12月，爱回收在北京和上海两地13家门店开启“闲置鞋服，回收换礼”活动，按照成色回收用户家中闲置的衣服与鞋子，成色等级高且满足要求品牌的物品按件予以回收，不满足要求的则按重量回收。通过回收活动，用户获得的环保积分，以兑换保温杯、随行杯、无线充电器等暖冬好礼，更便捷实在地参与环保回收。'
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '围绕多品类战略，万物新生进一步升级集团组织架构',
              text: '12月1日，为把爱回收打造成第一回收品牌，便于集团在新的阶段围绕多品类战略开展业务工作，万物新生发布内部通知宣布组织架构升级。其中，对于手机平板笔记本等成熟品类，划分平台能力中心，以已有的平台能力牵头大盘业绩增长。对于二手奢侈品、摄影摄像器材、黄金等新兴品类，集团将以门店为载体，围绕线上线下的良好体验，通过以品类策略牵头，拉通收和卖的各个环节，从而实现多品类业务快速发展的目标。',
            },
            {
              subTitle: '万物新生荣获智通财经“最佳ESG奖”',
              text: '12月19日， “第七届智通财经上市公司评选” 在杭州举办，万物新生（RERE.US）荣获<a rel="noopener noreferrer" target="_blank" href="https://www.zhitongcaijing.com/content/detail/849477.html">“最佳ESG奖”</a>。“最佳ESG奖”旨在表彰在过去一年中，特别是在疫情的大背景下，始终坚持以客户为中心，以员工为基石，以社会为己任，践行社会责任，注重与全球利益相关方沟通，并为社会和环境、股东、客户、员工及合作伙伴四方创造长期价值的港美股上市公司。本届“上市公司评选”有超过1000家港、美股上市公司参会报名，涵盖多个行业板块。',
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '京东发布2022年消费趋势关键词，以旧换新受到更多消费者青睐',
              text: '据<a rel="noopener noreferrer" target="_blank" href="https://mp.weixin.qq.com/s/Bzp-kgZFJHzIyQQunyGD-w">京东研究院</a>分析，消费者“精打细算的笃定消费”成为2022年消费市场的主要趋势之一，更加注重价格、产品、服务的性价比。在电器市场消费方面，京东研究院数据显示，2022前三季度，京东通过以旧换新方式选购新手机的订单量同比增长240％；通过以旧换新选购电脑数码产品的订单量同比增长373%；家电以旧换新订单量同比增长超359%，为超百万家庭实现旧家电换新。',
            },
          ]
        }
      ]
    },
    '202211': {
      articleTitle: '万物新生集团2022年11月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '11月，爱回收净新开联营标准店9间，加盟合作店23间，在东部和南部中高线市场加密联营标准店覆盖，在中部与北部中低线市场增设加盟合作店的回收网络铺设，为本地市场用户提供更近便的回收与交易服务。',
            },
            {
              subTitle: '',
              text: '在 “双十一”开门红阶段（10月31日晚8点起28小时内），由万物新生独家支持，京东以旧换新的用户数同比增长超310%。其中，万物新生深化与京东的联动，首次在大促期间跨品类以旧换新，支持用户在购买手机、电脑数码、家电等新品时最多同时回收6件手机3C等旧机抵扣购新费用，在促进循环再利用的同时，提升用户的购买体验并减轻购新的经济负担。在今年叠加的3C跨家电品类以旧换新业务中，支付单量占“双十一”全程以旧换新业务总量的约20%。'
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '万物新生发布2022年第三季度财报，再次实现盈利，继续四个战略核心不变',
              text: '11月22日，万物新生发布最新季度财务报告，总收入达25.4亿元，循环经济业务抵御经济和国内消费压力，实现29.2%的同比增长。Non-GAAP层面，通过提升成本效率，本季度实现经营利润近1080万元，在二季度疫情冲击后快速扭亏。同时，集团取得1.01亿元经营性现金流净流入，截至三季度末共有26.7亿元的综合现金储备，可持续发展有保障。管理层重申集团的战略清晰且持续，强调“四个继续”：继续推进一体化城市模型的战略，继续扩大多品类回收的服务范围和交付能力，继续让更多的自营货源经过产业链增值后toC零售，继续通过自动化提升运营效率。',
            },
            {
              subTitle: '万物新生创始人兼CEO陈雪峰获“2020-2021绿色中国年度人物”提名',
              text: '中国生态文明论坛年会于11月召开，会议期间，生态环境部对第三届中国生态文明奖40个先进集体、60位先进个人，10位“2020-2021绿色中国年度人物”获奖者进行了命名表彰。其中，万物新生集团创始人兼首席执行官陈雪峰获得“2020-2021绿色中国年度人物”<a rel="noopener noreferrer" target="_blank" href="https://caijing.chinadaily.com.cn/a/202211/28/WS638466b9a3102ada8b22436a.html">提名</a>，所领导的手机3C电子产品回收及环保处置平台与绿色城市产业链获得专业认可，万物新生以实际行动将社会责任融入到商业实践中。',
            },
            {
              subTitle: '万物新生名列“WISE 2022 新经济之王 消费生活领域年度企业”榜单',
              text: '36氪聚焦前沿科技、消费生活、汽车出行等领域，通过启动“WISE 2022 新经济之王 年度企业”系列调研，综合近千份报名企业资料与专业评审团意见，遴选并发布年度调研结果。凭借行业领先的循环经济模式和绿色生活方式，万物新生<a rel="noopener noreferrer" target="_blank" href="https://36kr.com/p/2022133442341897">名列</a>消费生活领域年度企业榜单。',
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '日元贬值等因素推动日本二手iPhone交易热度',
              text: '据<a rel="noopener noreferrer" target="_blank" href="https://www.sohu.com/a/604022208_120546417">正观新闻</a>报道（转自<a rel="noopener noreferrer" target="_blank" href="https://www.reuters.com/technology/yen-tumbles-gadget-loving-japan-goes-secondhand-iphones-2022-11-08/">路透社</a>），在近期日元兑美元汇率跌至32年来最低点期间，一些日本消费者放弃购买最新款iPhone，转为选择购买二手机器，带来二手iPhone市场交易增长。日本消费者对购买二手物品变得更加重视，在一定程度上得益于二手拍卖网站的兴起。',
            },
          ]
        }
      ]
    },
    '202210': {
      articleTitle: '万物新生集团2022年10月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '10月，爱回收净新开自营标准店8间，联营标准店14间，集中于南部战区的二线城市做持续的回收履约网络扩展。同时在中部战区和北部战区等地增设40间爱回收合作店，提高对中低线市场消费电子产品绿色回用的消费者影响与商家赋能。',
            },
            {
              subTitle: '',
              text: '京东电器联手万物新生，提升以旧换新项目的绿色贡献。基于万物新生提供的跨品类以旧换新能力，京东电器宣布开展“以旧换新1元行动”：从2022年8月15日开始，按手机、电脑数码及家电产品实际产生的订单数量，以每单1元钱的金额捐赠给中国绿化基金会，于2023年春季植树期在宁夏种植枸杞林，助力当地构建绿色生态屏障。'
            },
            {
              subTitle: '',
              text: '拍机堂持续完善买家和卖家客户分层运营，基于商家生命周期和交易规模，针对买家新客进行流程简化，提升新买家的交易体验和留存，对卖家权益和收费具体化，更好地服务优质商家。'
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '万物新生第二个自动化运营中心在东莞正式投产，实现多方面能力提升',
              text: '东莞自动化运营中心较上一代华东常州自动化运营中心实现流转效率、质检能力、仓储能力三方面的重要提升：通过多个数字化管理的运输体系，打通货品在不同环节间的流传，传输效率提升15%；采用Matrix 3.0，质检精准度和质检效率分别提升10%、50%，进一步降低因质检失误产生的退货损失；智能立库升级，同等体积空间的仓储能力较上一代翻倍。正式投产后，东莞自动化运营中心的8小时产能突破10,000台。',
            },
            {
              subTitle: '万物新生荣获中国社会企业与影响力投资论坛“向光奖”',
              text: '中国社会企业与影响力投资论坛与财新传媒联合发起2022年度向善企业评选，基于企业在战略制定过程中对社会问题的融入，对财务回报与社会收益的平衡，审核确认了242家企业成功入选《2022向光奖丨向善企业名录》。万物新生以“让闲置不用，都物尽其用”的企业使命以及持续的社会责任贡献，入选向善企业名录并获得“向光奖”。',
            },
          ]
        }
      ]
    },
    '202209':{
      articleTitle: '万物新生集团2022年9月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '9月，爱回收新开设8间标准店型的自营回收门店，51间以合作店（店中店）模式为主的加盟店，加强在华中与华东市场的服务渗透。此外，爱回收多品类回收旗舰店落地上海宝山万达广场，除常规手机3C品类外，增设箱包、腕表、首饰、黄金、名酒、鞋服等品类的回收，同时支持以旧换新、手机维修贴膜、配件销售等消费者需求，形成多功能特色服务站。爱回收多品类旗舰店预备全国开城方案，预计今年第四季度将覆盖上海10店，并在北京建设旗舰服务触点，未来依托全国千家门店，为更多消费者提供便捷且丰富的绿色循环经济消费服务，让爱回收品牌更深入人心。',
            },
            {
              subTitle: '',
              text: '东莞运营中心在9月底落实试运营，包含Matrix 2.0自动化质检系统的安装落地，实现国内第二个区域运营中心的自动化升级。升级完成后，东莞运营中心的8小时运营产能可以突破10,000台电子设备。'
            },
            {
              subTitle: '',
              text: 'iPhone 14系列首发期间，以旧换新流程完整嵌入京东主站，在商品详情页、购物车、结算页面均可以使用以旧换新下单购买新机，主站流程下单无须跳转，提升用户下单体验。同时，支持手机3C及家电跨品类多件换新，丰富用户回收品类场景、满足旧设备置换需求，进一步提升消费者便捷的、经济的购机体验。9月16日至9月18日期间，爱回收的自营回收业绩同比增长50%、门店回收业绩同比增长60%，此外增值服务业绩同比上涨100%。'
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '万物新生获晨星Sustainalytics ESG“低风险”评级',
              text: '万物新生集团在国际权威ESG评级机构晨星Sustainalytics最新的环境、社会和治理(“ESG”)评级中获评“<a rel="noopener noreferrer" target="_blank" href="https://www.sustainalytics.com/esg-rating/atrenew-inc/2008898065">低风险</a>”，总得分为17.6/100分（分数越低，表现越好），在全球“在线和直销零售”板块的公司中位列第四名（4/85）。值得注意的是，万物新生在数据隐私和安全、商业道德以及产品和服务的环境和社会影响等重要的行业特定ESG风险中被评为“可忽略”（“Negligible”），表明在以上方面万物新生的表现尤其突出。晨星Sustainalytics的最新评级肯定了万物新生在ESG综合指标上的卓越管理表现，表明万物新生在相关政策、项目和举措方面取得了行业性的领先地位。晨星Sustainalytics是一家领先的ESG研究、评级和数据公司，为全球投资者提供负责任的投资策略支持。',
            },
            {
              subTitle: '辛利军先生与姚彦中先生加入万物新生董事会',
              text: '2022年9月30日，京东零售首席执行官辛利军先生与京东集团高级副总裁、3C家电事业群总裁姚彦中先生加入<a rel="noopener noreferrer" target="_blank" href="https://ir.atrenew.com/corporate-governance">万物新生董事会</a>，接替徐雷先生和唐伟先生担任董事。辛利军先生还将出任薪酬委员会委员、提名与公司治理委员会委员。自此，万物新生集团董事会由八名成员组成：陈雪峰先生、王永良先生、辛利军先生、姚彦中先生、陈晨先生、王静波先生、蒋国兴先生、朱睿女士。',
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '韩国最大门户网站Naver以12亿美元收购北美二手交易电商平台Poshmark',
              text: '美东时间2022 年 10 月 3 日盘后，韩国最大的搜索引擎和电子商务平台 Naver Corp.（KRX：035420）（“Naver”）（<a rel="noopener noreferrer" target="_blank" href="https://www.sohu.com/a/582303222_120702">宣布</a>与北美二手交易电商平台 Poshmark, Inc.（NASDAQ：POSH）（“Poshmark”）达成最终协议，Naver将以每股17.90美元的现金价格收购 Poshmark的全部股份——较Poshmark在10月3日的收盘价溢价15%，企业价值约为 12 亿美元。该交易得到 Naver 和 Poshmark 董事会的一致批准，预计将于 2023 年第一季度完成。',
            },
          ]
        }
      ]
    },
    '202208':{
      articleTitle: '万物新生集团2022年8月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '8月，线下联营爱回收门店增加77间，继续以合作店（店中店）模式为主加强在国内中部地区下沉市场的渗透覆盖，为中国更广泛的消费者提供手机3C回收的服务，加强爱回收品牌的消费者认知，提高国民环保回收的绿色消费意识。',
            },
            {
              subTitle: '',
              text: '万物新生与日本领先的连锁中古交易公司开展合作，将自主研发的新一代手机自助回收机落地日本合作伙伴在东京的门店，为海外伙伴赋予回收质检能力。未来，万物新生将持续拓展更多海外目标市场，通过技术能力的输出，与全球合作伙伴共同实现手机3C产品的高效流转。'
            },
            {
              subTitle: '',
              text: '拍机堂调整商家物流费用，自9月1日小幅减少对买家和POP卖家的物流费用补贴，基于商家用户的信赖与粘性，提升货币化能力并持续优化平台对商家客户的服务能力。'
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '首次举办集团投资者开放日（线上）',
              text: '8月2日，万物新生集团举办2022年投资者日，通过网络研讨会（直播）形式向资本市场充分展示集团C2B爱回收、B2B拍机堂、B2C拍拍的业务能力。生动展示门店服务与常州运营中心的自动化质检技术，通过商家访谈探索平台竞争力，结合与高管团队的战略问答，为投资人和分析师提供更透明、更便捷的交流方式。如需视频回放，欢迎邮件垂询：<a rel="noopener noreferrer" href="mailto:ir@atrenew.com">ir@atrenew.com</a>。',
            },
            {
              subTitle: '发布第二季度业绩报告，展示循环经济业务韧性',
              text: '8月24日，万物新生发布2022年第二季度业绩报告，展示疫情反复环境下总收入再超预期的增长实力。集团二季度总收入21.5亿元，同比增长14.9%；全平台GMV达86亿元，同比增长10.3%；连续第二个季度取得经营性现金流入，达2.8亿元；综合现金储备26亿元，为长期健康发展巩固基础。',
            },
            {
              subTitle: '荣获《哈佛商业评论》 “高能团队奖”',
              text: '《哈佛商业评论》中文版联合FESCO发起评选，旨在寻找新的组织管理模式，并通过扩大优秀团队案例的影响力产生更大的社会价值。万物新生凭借突出的创新能力和战略执行力，成为本次评选中唯一一家入围的二手3C领域企业，以在人才培养、团队协作和组织效能等方面取得的成绩，获得了企业界及学术界的肯定。'
            }
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '经济日报携手京东发布数据——循环经济串起绿色产业链',
              text: '调研数据显示（<a rel="noopener noreferrer" target="_blank" href="https://www.sohu.com/a/582303222_120702">完整数据链接</a>），大部分消费者虽然还没有“完整了解”环保、绿色等内涵与范畴，但却早已开始实践绿色环保的消费行为，这主要是通过使用各平台以旧换新服务实现的。可以说，以旧换新不仅是一种拉动消费升级的手段，还引领了绿色消费观念的形成，并由此衍生出“循环供应链”概念。',
            },
          ]
        }
      ]
    },
    '202207':{
      articleTitle: '万物新生集团2022年7月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '7月，集团线下门店网络保持此前的建设节奏，月内净新增49间门店，主要以合作店（店中店）形式分布在中部和南部的一二线城市，比如深圳、无锡等市场，加强爱回收品牌在本地通讯市场的覆盖和消费者服务能力。',
            },
            {
              subTitle: '',
              text: '拍机堂为提升买家的采货体验进行标签和入口调整：在原有认证标签基础上，通过质检工具、质检员、质检环境的区别，对质检方分为“官方质检”、“授权质检（拍机堂授权的高能力质检员）”、“第三方质检（商家采用拍机堂质检标准和流程）”，更新质量标签。对授权质检和第三方质检商品加以星级标签，星级越高，物品质量越有保障，进一步实现信息透明化。暗拍专区调整拆分为手机平板和笔记本数码2个专场，形成品类聚焦，为商家采货提供更多便捷。'
            },
            {
              subTitle: '',
              text: '加快多品类回收运营试水，复用北京上海等城市的主流商圈的爱回收门店体系与能力，分别选取一些门店叠加二手奢侈品箱包腕表、摄影器材、黄金回收业务。消费者可以在爱回收APP、小程序上看到新品类的回收入口。以奢侈品回收为例，服务流程相对传统回收有了显著升级，提升消费者服务体验。多品类业务的发展，是建立在消费者对爱回收品牌的认知和信赖基础上的，与长期建设的门店交付场景、店员的质检和培训体系密不可分。'
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '万物新生荣获凤凰网年度CSR担当者、中国乡村发展基金会卓越贡献奖',
              text: '凤凰网2022企业社会责任与创新论坛年度CSR创新案例揭晓，万物新生以“回收爱”公益助学项目在本次评选中获得“年度CSR担当”的荣誉。同时，万物新生由于在助力脱贫攻坚方面表现突出，获得中国乡村发展基金会(原中国扶贫基金会)颁发的助力脱贫攻坚卓越贡献奖，万物新生在2021年为河南受到水灾损失的学校支援智慧黑板、教学设备及办公设备，帮助学校快速重建。',
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '极光大数据发布低碳社会洞察报告，二手3C交易领域企业案例分析引用万物新生',
              text: '极光大数据旗下月狐研究院推出 《 2022低碳社会洞察报告 》（<a rel="noopener noreferrer" href="https://www.moonfox.cn/insight/detail?id=1168&type=report" target="_blank">报告链接</a>），着眼于产业和企业端，以互联网行业为例，分析其在碳中和领域内的价值、具体碳减排表现以及典型的企业举措。万物新生以领先的商业模式、突出的减碳举措与贡献，成为该报告中闲置电子产品循环利用企业的案例典范。',
            },
          ]
        }
      ]
    },
    '202206': {
      articleTitle: '万物新生集团2022年6月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '集团线下爱回收门店于6月净新增47间，均为联营门店（包含标准店与合作店中店模式），主要在合肥、武汉、杭州等东部城市及苏州、西安、连云港等中部城市铺设回收网络，增加消费者触达。部分城市小仓由自营转联营，分别由前端运营联营小仓、中台运营自营小仓，整体持续增加对南部、中部、北部下沉市场的覆盖。加强本地市场的C端与B端能力建设，形成一体化城市群。',
            },
            {
              subTitle: '',
              text: '拍机堂零售场自6月起向拍拍POP商家开放，后者在买货后进入拍拍云仓发货，为二手3C行业内的零售商家提供便捷的采货与运营支持。目前，该服务已经跑通了链路，深入做好BC的融合、为BC两端客户提升服务体验。'
            },
            {
              subTitle: '',
              text: '拍拍再发618战报，巅峰28小时前4小时（6月17日20点至6月18日0点）京东回收订单同比增长340%，京东以旧换新订单同比增长246%，拍拍全渠道销售额同比增长152%，手机、笔记本平板、奢侈品品类销售额分别同比增长139%、155%、440%，以“省薪生活，就上拍拍”的绿色生活方式占领更多消费者心智。'
            },
            {
              subTitle: '',
              text: '6月21日宣布推出企业数据清除解决方案“爱清除”，通过针对企业数据安全需求，升级隐私数据清除算法，支持单台和批量数据深度清除。“爱清除”历经4年自主研发迭代，已通过ADISA国际认证，支持手机、平板笔记本电脑、PC电脑，兼容IOS与安卓系统，帮助商家与企业保障数据隐私安全。'
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '一“拍”即合，三生有“爱”——爱回收&拍拍融合三周年庆典',
              text: '6月3日迎来爱回收与拍拍融合的三周年纪念日，万物新生为此举办庆典活动，连线北京、上海、深圳三地，核心团队成员复盘融合历程中的挑战与收获，并展望对未来以一个团队、一个平台、一个品牌为目标的期许。',
            },
            {
              subTitle: '首次采用TCFD建议发布2021年ESG报告，披露二手手机循环利用碳减排绩效',
              text: '6月9日，万物新生发布上市以来第二份年度ESG报告，首次引入气候相关财务信息披露工作组（TCFD）框架，即金融稳定理事会（FSB）在回应二十国集团（G20）气候变化对全球金融市场稳定性的影响时所提出的建议，从管治、策略、风险管理、指标与目标四个方面评估了在公司运营与二手3C回收再利用中面临的气候变化风险与机遇。此外，集团积极厘清产品碳足迹，作为二手行业上市公司表率，率先采用生命周期评价（LCA）方法，通过循环足迹公式（CFF） 计算并披露报告期内全平台二手手机回用的46.4万吨温室气体减排绩效。',
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '北京市发布废弃电子产品回收规范征求意见，注重回收过程中的用户信息保护',
              text: '北京市市场监管局日前发布《废弃电器电子产品回收规范（征求意见稿）》（<a rel="noopener noreferrer" href="http://scjgj.beijing.gov.cn/hdjl/myzj/bzzxdyjzj/202205/t20220519_2716308.html" target="_blank">通知附件6</a>），对废弃电器电子产品回收过程中的收集、贮存、运输以及处置等流程进行了详尽的规定，包括要求回收过程电子产品时当面清理用户的个人信息。这一文件的出台，对进一步提高废弃电子产品回收利用率，更好地保护消费者权益意义重大。',
            },
          ]
        }
      ]
    },
    '202205': {
      articleTitle: '万物新生集团2022年5月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '5月，由于疫情导致的城市封控在上海、北京两地持续，爱回收在上海的业务仍然关停，职场采用居家办公政策。但上海业务与职场自6月1日起逐渐恢复，其中，上海C2B回收额在6月的前两周快速恢复，相较2021年同期，已实现正同比增长，这主要得益于自营门店渠道的强劲复苏。',
            },
            {
              subTitle: '',
              text: '爱回收门店延续上月以共担收益与风险的联营门店（店中店）为主的扩张策略，5月内净新增门店59家（均为联营店），主要在武汉、宁波、郑州加快市场覆盖，向二线城市消费者靠近并拓展更多优质货源。期内净新增3个城市小仓，在南部城市增加本地交易市场的覆盖。'
            },
            {
              subTitle: '',
              text: '拍机堂推出“焕新机”子品牌，增设零售货源采购专场，即在4月深圳出台的维修翻新合规指引政策下（<a rel="noopener noreferrer" href="http://www.shenzhen.jcy.gov.cn/inspection/Notice/5026.html" target="_blank">原文链接</a>），由拍机堂对二手手机进行合规维修，包括采用手机原厂或第三方品牌的电池或屏幕，提升手机性能与状态，并在销售过程中充分告知买家维修情况与售后规范，为商家提供便捷、有质量保障的采货方案。'
            },
            {
              subTitle: '',
              text: '制订618大促节奏，鼓励用户以旧换新和环保回收的绿色消费方式，按照京东平台大促的节奏分四个阶段释放利益点。其中，拍拍在正式期首4小时（5月31日20点至6月1日0点）实现开门红，京东回收提交量同比增长340%，京东以旧换新提交量同比增长318%，拍拍全渠道销售额同比增长192%，手机、笔记本平板、奢侈品品类销售额分别同比增长83%、112%、189%。'
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '朱睿女士新加入董事会并担任独立董事，加强公司治理与董事会多元性',
              text: '朱睿教授加入万物新生董事会，成为审计委员会成员。朱睿教授现任长江商学院市场营销学教授、社会创新与品牌研究中心主任，她的加入将有助于提升万物新生商业模式的可持续发展与财务稳定性，同时倡导“商业向善”，即发掘商业活动中的社会价值。朱教授通过对消费者与市场的洞察推动循环利用的绿色生活方式成为新主流，进而让循环经济与万物新生的发展产生更紧密的联动性。该任命于5月17日生效，届时万物新生董事会共有8位成员，其中3位为独立董事。',
            },
            {
              subTitle: '发布2022年一季度财务报告，再次实现季度盈利',
              text: '5月24日，万物新生发布2022年第一季度财务报告，集团总营收22.1亿元，同比增长45.7%，超出此前指引。全平台GMV 94亿元，二手商品交易量840万单，分别实现同比增长51.6%、31.3%。经调整经营利润390万元，再次实现季度盈利。综合现金储备24亿元，较上一季度末持平，为稳定运营提供保障。',
            },
            {
              subTitle: '加强落实“城市一体化”核心战略，升级大前端组织架构',
              text: '集团将大前端分为东、南、中、北四大战区，落实精细化管理，渗透到全国服务消费者、抓一手货源，服务赋能中小商家、提升经营收益。同时在总部层面调整C2B业务部和B2B业务部，沉淀和打磨两条业务线的核心能力。该调整于6月1日正式发布。',
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '深圳为经翻新的3C产品销售提供5%补贴',
              text: '5月23日，深圳市发展和改革委员会、深圳市商务局等部门联合印发《深圳市关于促进消费持续恢复的若干措施》（<a rel="noopener noreferrer" target="_blank" href="http://www.szgm.gov.cn/xxgk/kjxgfy/zczy/content/post_9821828.html">全文链接</a>），在扩大消费电子市场规模中明确提出“支持回收设备绿色利用，符合条件企业回收旧机在深圳翻新出售的，按照销售额的5%给予补贴”。',
            },
          ]
        }
      ]
    },
    '202204': {
      articleTitle: '万物新生集团2022年4月博客',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '4月，疫情对上海造成持续负面影响，政府采取严厉的封控措施，因此爱回收上海业务关停，职场采用居家办公。全国新增70+爱回收门店，以共担收益与风险的联营门店（店中店）形式为主，自营门店审慎增加，主要增加回收业务在二三线城市的消费者触达。同期增设13个城市小仓，以联营形式主要渗透于南部城市，增加下沉市场零售渠道覆盖、以及二手商家对标准化质检服务的可及性。',
            },
            {
              subTitle: '',
              text: '在翻新利好政策支持下，拍机堂于4月正式上线苹果换屏维修服务，商家用户提交“焕新维修”订单后将手机邮寄至东莞运营中心，收货后48小时内更换苹果原厂屏幕。该服务目前对iPhone 12与iPhone 12 Pro开放，平台将继续探索更多品牌与型号的合规维修能力，为商家提供便捷可靠的增值服务，让商家卖得更好，在价值创造中获得服务费增收的空间。'
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '上市主体发布2021年年报与年度股东信，并提供外国公司问责法下的最新状况',
              text: '4月27日，万物新生集团以20-F表格形式向美国证券交易委员会（“SEC”）提交2021财政年度报告。此外，集团发布由CEO陈雪峰撰写的年度股东信《抓住逆势而发的机遇，迎接二手经济的春天》，在复盘行业与集团发展的同时，展望新的挑战与机遇。<br/>5月5日，集团对于被SEC暂定为美国公众公司会计监督委员会(“PCAOB”)认定的发行人进行公开状况更新，该认定目前不影响集团的上市地位和经营活动，并表示将遵守中美相关法律法规，持续留意市场发展并评估所有策略选项。<br/>万物新生集团年报、年度股东信、HFCAA状况更新，均可在投资者关系网站ir.atrenew.com获取。',
            },
            {
              subTitle: '万物新生在行动，为社区与员工提供抗疫援助',
              text: '上海疫情突发，万物新生积极践行企业社会责任，心系社区为共抗疫情贡献力量。4月向上海各区累计捐赠近80吨蔬菜和生活物资，同时为杨浦区捐赠价值40万元的二手手机作为核酸检测信息采集使用的防疫物资，为复旦大学捐赠20万元用于购置可循环使用的保鲜饭盒，为同济大学捐赠价值11.5万元的防护服、手套、护目镜等医疗物资。此外，集团还向居家办公的员工提供两批生活物资礼包，切实解决员工买菜难题。',
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '深圳检察机关出台电子产品翻新产业规范，保护知识产权，促进合规翻新发展',
              text: '4月25日，在第22个“世界知识产权日”前夕，深圳市人民检察院举办的“电子产品翻新产业高峰论坛”上，发布了《深圳市检察机关电子产品翻新产业知识产权刑事合规指引（试行）》（<a rel="noopener noreferrer" target="_blank" href="http://www.shenzhen.jcy.gov.cn/inspection/Notice/5026.html">原文链接</a>）。这是全国首个由检察机关发布的产业刑事合规指引，对国内市场电子产品行业合规翻新实践有积极的示范意义。合规翻新的要点包括：商标权保护，翻新商品来源合法，个人信息隐私保护，在翻新、销售环节主动且充分披露翻新信息，翻新限于抛光及一般维修，可以使用有质量保证的第三方配件，提供一定期限保修服务。万物新生作为企业代表之一为翻新标准的设定、为指引的制订提供参考意见。',
            },
          ]
        }
      ]
    },
    '202203': {
      articleTitle: 'RERE.US 企业博客 - 2022年3月',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '2022年3月，深圳、上海、沈阳等城市新冠疫情复发，应对各地防疫政策要求，部分爱回收门店临时关闭，其他区域的爱回收门店和线上订单导流不停歇，员工跟进订单后转为上门交付或延期交付，正常营业的门店采用“全服务、减少接触”的交付方式，尽最大可能满足消费者的安全回收需求。',
            },
            {
              subTitle: '',
              text: '国内疫情复发期间，运营中心积极配合当地政府的防疫政策与管理，做到每日三次消杀、到货包裹消杀、人员测温、健康码管控，以保证员工健康与生产安全。我们同样根据大数据系统，联动周边小仓的运营能力，进行货物的分流和调拨，努力降低疫情对质检运营的影响。'
            },
            {
              subTitle: '',
              text: '3月新增16个新增的城市级运营中心（小仓），以联营合作形式为主，扩展在华南、西南地区的下沉城市运营能力覆盖。'
            },
            {
              subTitle: '',
              text: '一体化城市模型有序推进，其中，云仓业务于2021年底在苏州和深圳启动试点，覆盖中心化市场，通过机大侠质检能力对当地的小B POP商家赋能，帮助商家把货上架，从而拉动一线销售GMV。截至3月底，云仓已经覆盖60个城市，为本地市场销售带来增长。'
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '发布2021年第四季度及全年业绩，Q4收入超预期增长并取得经营利润',
              text: '3月10日发布集团2021年第四季度及全年业绩。2021年全年收入达77.8亿元人民币，同比增长60.1%；全平台GMV累计达325亿元人民币，同比增长65.8%；全年累计二手商品交易总数达3120万，同比增长32.2%。第四季度营收24.4亿元人民币，超出三季度给出的指引高端，较前一年同期增长48.2%，第四季度取得调整后经营利润970万元；集团现金储备充足。',
            },
            {
              subTitle: '启动2021年ESG评审及年度报告',
              text: '在妙盈科技的支持下，万物新生启动2021年ESG报告工作，参照全球报告倡议组织（GRI）标准，全面梳理集团可持续发展工作的开展情况，并对二手电子产品循环利用业务的碳影响进行量化评估。报告重点关注二手电子产品的环保回收、数据安全及隐私保护、新冠疫情环境下的员工健康与职业发展等重要性议题。',
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '央视财经频道报道手机回收市场升温，以旧换新服务受青睐',
              text: '央视财经《第一时间》4月5日报道（<a rel="noopener noreferrer" target="_blank" href="https://tv.cctv.com/2022/04/05/VIDEAJfxcHdP7ioJ6ikrxwIn220405.shtml">视频播放链接</a>）以旧换新和二手消费行为越来越流行，电商与服务平台开放在线估价与线下回收服务，线上线下相结合的模式提高了回收率，二手消费成为更多消费者的选择。受访消费者表示：旧手机闲置等同浪费，回收则可以降低换机的成本，此外电池还对环境有污染，认可绿色回收理念。据中国电子装备技术协会测算，2021年中国手机社会保有量接近19亿部，预计“十四五”期间废旧手机闲置总量累计达到60亿部。',
            },
          ]
        }
      ]
    },
    '202202': {
      articleTitle: 'RERE.US 企业博客 - 2022年2月',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '2022年2月，爱回收于春节假期后恢复本地服务网络的扩张，月内净新增39间加盟店，其中38间为合作店。2月新增4个城市级运营中心（小仓），总计103个。',
            },
            {
              subTitle: '',
              text: '2022年一季度，一体化城市模型收获有效验证。在22个试点城市中，12座城市的B2B业务增长超过100%，包括三座省会城市沈阳、南昌、合肥。 集团将鉴定落实一体化城市模型这个清晰的战略，充分做到精细化管理、经营分析和激励。'
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '自主研发手机外观智能检测设备“拍照盒子3.0”',
              text: '万物新生自主研发手机外观智能检测设备“拍照盒子3.0”，具备流水线式并发处理能力，可同时检测多台手机。通过融入AI算法，拍照盒子3.0可在20秒内精准检测30余种外观缺陷，准确率超99%，较人工质检节省90%的时间。预计2022年上半年落地应用，进一步落实集团全自动智能质检战略。',
            },
            {
              subTitle: '有序推完成东莞运营中心自动化升级，上海运营中心关仓采用成本优化方案',
              text: '深圳自动化运营中心在迁址东莞的同时完成自动化升级，东莞中心目前处于现场施工阶段，三个主要的自动化升级子项目包括：自动化移交输送线、智能化立库、Matrix全自动质检系统。东莞自动化检测流水线计划6月中旬完成调试，6月底启动全项目运营，工序自动化率提升40%。同时，上海运营中心关仓，本地质检订单将有上海城市小仓承担，周边市场的入仓质检需求将由常州自动化运营中心承接，进一步节省履约成本。',
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '2022年政府工作报告提出，今年持续改善生态环境，推动绿色低碳发展',
              text: '国务院总理李克强于3月5日在政府工作报告（<a rel="noopener noreferrer" target="_blank" href="https://www.gov.cn/premier/2022-03/05/content_5677248.htm">原文链接</a>）中提出，完善节能节水、废旧物资循环利用等环保产业支持政策。两会期间，多位全国人大代表就循环经济的发展作出提议，进一步加强电子废旧物循环利用，制定电子废旧物循环利用中长期发展规划，大力培育市场主体，加强协同规范发展。',
            },
          ]
        }
      ]
    },
    '202201': {
      articleTitle: 'RERE.US 企业博客 - 2022年1月',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '2022年1月，爱回收自营门店、联营门店（包含新类型合作店）、拍拍严选门店总计净新增40间，月底城市级运营中心（小仓）总数达99个，以行业领先的本地服务网络充分发挥揽货能力，夯实为商家客户更高效便捷实现履约的能力基础。',
            },
            {
              subTitle: '',
              text: '春节不打烊：拍拍春节2月1日至2月7日再创佳绩，全渠道销售同比增长71%，手机品类销售同比增长78%，拍拍严选门店加官网的销售同比增长630%，抖音、快手新渠道销售增长均突破300%，京东普通回收、以旧换新订单的同比增长分别为83%、173%。<br/>为保障用户服务体验，爱回收在春节期间的回收订单有效期保持10天，普通上门回收、快递回收业务正常服务；保持拍机堂暗拍专场、一口价、京东帮卖、京东备件库的正常开放，并提供买货暂存服务，确保商家在春节期间的备货、流转不间断。'
            },
            {
              subTitle: '',
              text: '对应一体化城市模型战略，完成组织架构调整升级：将全国主要省份分为南北战区，围绕一体化城市模型，强化前端商家服务能力、下沉市场拓展能力，进行精细化管理和考核。技术中心组织架构升级，更加注重平台能力建设、重点项目开发、信息安全和质量保障。',
            },
            {
              subTitle: '',
              text: '拍机堂启用中通物流，并于1月20日起依照单台物品结算金额，向买家收取每台0.9元至4.9元的小额发货服务费，依托商家信赖，继续降本增效。',
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '管理层与彭博Bloomberg Green连线，分享公司的电子产品回收和商业化经验',
              text: '万物新生集团CFO陈晨接受Bloomberg Daybreak Asia电视直播采访，探讨“以旧换新”购机的经济适用性，通过回收交易的梯次利用延长电子产品生命周期，提倡绿色生活方式的消费者教育，万物新生作为行业引领者是如何以平台能力、数字工具赋能中小商家，等话题。',
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '国家发改委等七部门联合印发《促进绿色消费实施方案》',
              text: '1月21日，国家发展改革委等部门联合印发<a rel="noopener noreferrer" target="_blank" href="https://www.ndrc.gov.cn/xwdt/tzgg/202201/t20220121_1312525.html?code=&state=123">《促进绿色消费实施方案》</a>，为贯彻落实“双碳”目标进一步明确指导方案。方案要求强化绿色消费科技和服务支撑，拓宽闲置资源共享利用和二手交易渠道——积极发展家电、消费电子产品和服装等二手交易，优化交易环境；规范开展二手商品在线交易，加强信用和监管体系建设，完善交易纠纷解决规则；鼓励二手检测中心、第三方评测实验室等配套发展。',
            },
          ]
        }
      ]
    },
    '202112': {
      articleTitle: 'RERE.US 企业博客 - 2021年12月',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '截至2021年12月底，含新类型的爱回收合作店在内，万物新生全国门店总数超1,300间；城市级运营中心（小仓）总数超过90个，为集团坚持抓一手货源供给的战略方针夯实布局，为集团在更多城市复制“一体化城市模型”奠定揽货和运营的基础能力。',
            },
            {
              subTitle: '',
              text: '拍机堂商家服务再升级：12月针对智能数码品类（运动相机、游戏机、智能手表等）使用定制密封袋封装，对买家规则中增加售后举证规则，进一步提高平台规范化水平，完善对买卖双方权益的保护机制，促进更顺畅的交易履约。此外，一口价商品页上线“今日上新”、“拍机堂认证”、“优质质检”等快速筛选标签，商详页上线滑动切换的商品详情查看功能，为商家选货、备货、交易提供更多便捷。',
            },
            {
              subTitle: '',
              text: '12月31日晚8:00至1月1日24:00，根据京东手机部门战报，京东手机元旦开门红前28小时的以旧换新销量同比提升625%，该部分服务由爱回收独家承接。',
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '开放常州“优检”自动化运营中心，欢迎资本市场参观交流',
              text: '12月6日，万物新生常州“优检”自动化运营中心向资本市场开放，广发证券及数家投资机构参观Matrix 2.0自动化质检流水线，并在运营中心与运营经理团队充分交流万物新生集团的二手手机3C规模化质检、运营、技术创新能力。',
            },
            {
              subTitle: '落实集团四季度战略会，打磨战略落地方案、探讨财务预算',
              text: '12月24日-26日，集团SEC召开季度战略会，在验收三季度重点项目之外，探讨2022年第一季度增长预算、财务目标、一体化城市模型的复用能力，推敲全年创新、外部合作、竞争破局的方向。',
            },
            {
              subTitle: '荣获新经济企业、社会责任、资本市场沟通多项荣誉',
              text: '12月，万物新生集团收获多项荣誉，包括36氪“2021年度资本市场潜力创新公司”，上海报业集团 “年度社会责任优秀奖”， 第五届中国卓越IR 年度评选“最佳资本市场沟通奖”，为各界对集团突出的创新能力、社会责任表率、及时且充分的资本市场沟通工作的认可。',
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '国家发改委、商务部、知识产权局等多个部门走进循环经济企业，调研产业发展',
              text: '近日，国家发改委、商务部、知识产权局等多个部门对循环经济产业开展调研，了解并听取行业内重点企业的发展动态及相关建议。万物新生作为循环经济企业代表向各部门进行汇报，并对税收政策、合规经营、知识产权等议题做交流。',
            },
          ]
        }
      ]
    },
    '202111': {
      articleTitle: 'RERE.US 企业博客 - 2021年11月',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '经京东零售介绍，拍拍与戴森建立品牌合作，推出拍拍严选戴森官方旗舰店，销售戴森官翻吸尘器、空气净化器、吹风机、卷发棒等精品小家电。旗舰店在今年10月于京东上线，当月销售额超14万，11月销售额超300万。',
            },
            {
              subTitle: '',
              text: '11月，爱回收门店总数达1,074间，拍拍严选门店达17间，提前达成“百城千店”战略目标。月内在全国范围内增设21个城市级运营中心（小仓），持续铺开本地回收+本地质检运营能力，为推进一体化城市模型、“百城千亿”战略目标夯实本地供应链能力和运营管理基础。',
            },
            {
              subTitle: '',
              text: '双十一期间，京东爱回收一站换新支付订单同比增长84%，普通回收提交订单同比增长44%；双十一大促当天，拍拍自营品牌“拍拍严选”成交额夺得京东、快手及抖音等多家平台二手类目销售排名榜首，占领更广泛的消费者心智。',
            },
            {
              subTitle: '',
              text: '11月底，配合本地化战略，拍机堂上线“快速达”标签，便于买家辨认、筛选发货地距默认收货地址更近的商品，优化平台商家的采购体验与效率，提高平台货品的周转效率。',
            },
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '参与第四届中国国际进口博览会，与德勤共话循环经济',
              text: '11月6日，万物新生集团CEO陈雪峰参加第四届中国国际际进口博览会，在德勤“中国应对气候变化的转折点”主题论坛上作为主旨演讲嘉宾分享万物新生在促进二手3C商品流通方面的经验以及企业自身的ESG实践。',
            },
            {
              subTitle: '上市主体完成更名，以“ATRenew Inc.”加强国际品牌认知，启动新官网',
              text: '11月15日，经临时股东大会讨论及投票，通过上市主体更名为“ATRenew Inc.”的决议，加强全球市场对集团“万物新生”（All Things Renew）的品牌认知。同期上线集团新官网www.atrenew.com与投资者关系网站ir.atrenew.com，确保集团信息披露及与各界的交流做到准确、完整、及时、透明。',
            },
            {
              subTitle: '发布第三季度财务报告，以“买入”评级获国泰君安国际首次覆盖',
              text: '11月18日，集团发布2021年第三季度财务报告，对季度内运营进展与一体化城市模型进行进一步解读。在持续的资本市场沟通中，获得国泰君安国际的首次覆盖和“买入”评级，继华兴、中信、广发、天风、西南后再增中资券商覆盖，补充与国内资本市场沟通的桥梁。',
            },
            {
              subTitle: '万物新生成为“低碳办公联盟”首批核心成员',
              text: '11月25日，瑞安办公联合循环派、MSC咨询在上海举办“低碳办公的N种可能”主题活动，宣布成立“低碳办公联盟”，万物新生作为联盟协同企业出席。万物新生集团高级副总裁、爱回收自营事业部总裁杜晓忱出席，作为循环经济代表企业分享集团低碳办公解决方案和企业实践。',
            },
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '京东发布循环经济行业研究报告，引述万物新生二手消费电子洞察',
              text: '12月1日，京东集团与万物新生集团、科尔尼管理咨询公司合作，通过用户调研及对2018年以来二手电器产品的销售情况分析等，结合目前国内循环经济现状与发展，发布<a href="https://mp.weixin.qq.com/s/Z5h6wfqStmm8q7GzpLBzaw" rel="noopener noreferrer" target="_blank">《当“旧物”不再是“废物”——2021“循环经济”研究报告》</a>。据分析，3C行业对于“循环”理念的认知和应用领先于其它行业，其也是最有可能率先实现循环理念普及、用户心智培育的行业。此外，以旧换新拉动的多为件单价较高的大件商品，本身较高端的二手商品通过可靠的渠道二次销售，延长自身使用寿命的同时，提升了不同层次人群的消费品质。',
            },
          ]
        }
      ]
    },
    '202108': {
      articleTitle: 'RERE.US 企业博客 - 2021年8月',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '8月门店净新开31家，总数超900家，在华中、华北加深中低线城市下沉。'
            },
            {
              subTitle: '',
              text: '8月新增4个城市级运营中心（小仓），自营小仓总数达34个，在华北、华东、华中分散下沉，增加平台对本地商家和本地通讯市场的服务能力，获取更多货源。',
            },
            {
              subTitle: '',
              text: '8月1日起，拍机堂对入仓质检的商品，增加质检服务单项收费，这是因为拍机堂在行业内的规模效应、以及持续提升的客户服务能力。'
            },
            {
              subTitle: '',
              text: '8月15日起，拍拍代卖模式中，部分价格区间的手机品类，调整卖家基础服务费与增值服务费，这是因为拍拍品牌提升，增强了购买端消费者的信任，带来的对货源端议价权的提升。'
            },
            {
              subTitle: '',
              text: '为配合拍拍APP升级为拍拍严选APP，更专注于C2B和B2C功能，8月下旬上线了鲸置APP，承接以往拍拍APP所提供的C2C京东用户已购商品转卖功能。鲸置由万物新生团队研发，考虑到京东用户数据保护，目前暂用京东主体发布上线，得到京东法务部门的认可。'
            }
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '发布上市以来首份季报，行业内率先发布ESG年度报告',
              text: '8月17日发布上市以来首份财报，于投资者关系网站同步业绩电话会议速记，在公司运营与财务成绩方面保持透明、高效沟通。同期发布2020年ESG报告，参照国际标准（GRI可持续发展报告标准，即GRI Standards），率先在二手行业内识别了ESG重要性议题，披露公司在隐私安全和用户数据保护、二手产业链标准化、员工保护等方面的重要举措和制度。同时回顾2020年的环境数据、社会价值与企业管治的阶段性成绩，为未来建立更全面的环境管理体系夯实基础，为二手消费电子产业链的节能减排管理标准提供先行经验，以可持续的商业模式积极参与我国循环经济的发展。中英文完整版报告可以在万物新生投资关系网站“ESG”栏目下获取。'
            },
            {
              subTitle: '获中信证券、广发证券首次覆盖及“买入”评级',
              text: '继7月获得高盛、美银、华兴首次覆盖及“买入”评级，万物新生获得国内一线券商中信证券与广发证券的首次覆盖及“买入”评级，12个月目标价分别为19.00与20.33美元/ADS。中信看好万物新生通过自主技术研发创新所成功建立的二手消费电子产品的回收、评级、定价、销售的全价值链升级，认可公司的平台渗透能力与服务收费能力。广发认可公司的市场领先地位，认为二手消费电子市场属于供给驱动的新蓝海，市场发展将驶入快车道。'
            },
            {
              subTitle: '完成上半年评优，提拔优秀人才',
              text: '完成2021年中晋升工作，经过评估与全员公示，最终遴选130位脱颖而出的员工并确定晋级，继续扎实公司的人才基础与骨干培养。'
            }
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '人民网权威发布《二手交易与低碳生活研究报告》',
              text: '8月中旬，人民网旗下人民数据研究院发布《<a href="http://www.peopledata.com.cn/uploadfile/2021/0818/20210818050335636.pdf">二手交易与低碳生活研究报告</a>》。该报告依托我国“双碳”目标及循环经济的最新发展政策，探讨“互联网+二手交易”平台的规范发展。报告引用了人民启信推出的用于评价企业信用水平的启信分，万物新生集团（爱回收）在二手交易平台赛道中获得最高信启信分。'
            }
          ]
        }
      ]
    },
    '202109': {
      articleTitle: 'RERE.US 企业博客 - 2021年9月',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '9月保持开店节奏，全国门店总数约950家，在全国范围加深中低线城市下沉，上海单城门店数量超过100家。'
            },
            {
              subTitle: '',
              text: '9月新增7个城市级运营中心（小仓），自营小仓总数达41个，在华北、华东、华中分散下沉，在新的地级市布局平台对本地商家和本地通讯市场的服务能力，获取更多货源。',
            },
            {
              subTitle: '',
              text: '9月1日起，拍机堂对入仓送检模式物品收取“入仓增值服务费”，并设定保底服务费。收费能力的提升得益于平台在行业内的获得商家广泛认可，以及持续提升的客户服务能力。'
            },
            {
              subTitle: '',
              text: '9月13日起，拍机堂上线“即刻卖”服务。二手手机卖家可自主选择该服务，资金回款快，收益即刻锁定。平台收取基础服务费与基于大数据计算的溢价成交增值服务费，赋能普通代拍商家，为卖家提供更便捷的交易体验。'
            },
            {
              subTitle: '',
              text: '9月18日起，拍机堂升级资金模块。升级后买卖资金统计灵活，支付方式做了更安全的优化，银行卡管理更自由，100万元以下资金支持7x24小时转账——为商家提供更便捷的核账与资金管理方式。'
            }
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '回收爱·美好学校河南数码助学基金走进受灾校园',
              text: '继7月向中国扶贫基金会捐赠505万元，驰援河南受强降雨天气影响到教学设施的灾区校园，万物新生集团于9月有条不紊运送电子教学设备，支持受灾学校重建。'
            },
            {
              subTitle: '落实集团三季度战略会，明确2022年增长路径与预算',
              text: '9月14日-16日，集团SEC召开季度战略会，复盘季度工作成果，以团结和开放为核心主题，讨论四季度与2022年战略目标。其间，SEC回顾评估集团定价策略，交流平台业务的创新发展，探讨开店逻辑、门店流量、服务组合的优化空间。'
            },
            {
              subTitle: '入围IR Magazine最佳ESG重要性报告评选名单',
              text: '9月23日，万物新生入围受全球资本市场关注的《投资者关系杂志》（IR Magazine）2021年奖项候选名单。自今年6月上市，即凭借积极ESG实践和披露入围，与大中华区上市公司角逐“最佳ESG重要性报告（中小市值）”奖项，即“IR Magazine Awards - Greater China 2021: Best ESG materiality reporting (small to mid-cap)”。最终获奖名单将于12月9日公布。'
            },
            {
              subTitle: '落实集团内部季度培训，提升风险管控与ESG实践意识',
              text: '9月24日，集团针对萨班斯法案(US SOX) 与环境、社会、治理（ ESG ）展开内部培训，旨在进一步提升上市公司在公司治理、财务报告政策与流程、证券市场合规、可持续发展实践等方面的表现。9月同期举办全员安全意识培训，提升企业信息安全保护与风险控制能力。'
            },
            {
              subTitle: '发布临时股东大会公告，商讨上市主体更名决议',
              text: '9月30日，集团发布公告，拟定于11月15日召开临时股东大会（Extraordinary General Meeting，即“EGM”），讨论上市主体更名为“ATRenew Inc.”的决议，该决议旨在加强集团在全球市场的品牌建设，方便海外投资人理解。EGM详情与安排请参考投资者关系网站ir.aihuishou.com。'
            }
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '发改委向社会公开征集“循环经济促进法”修订意见和建议',
              text: '9月10日，国家发展改革委发布关于向社会公开征集《中华人民共和国循环经济促进法》修订意见和建议的公告，向社会各界开放多项需要法规重点规范的内容，包括互联网+资源循环利用、二手市场、共享经济、绿色消费等。'
            }
          ]
        }
      ]
    },
    '202110': {
      articleTitle: 'RERE.US 企业博客 - 2021年10月',
      articleTips: '以下内容仅供参考，具体数据以官方季度财报披露为准',
      sections: [
        {
          title: '业务动态',
          list: [
            {
              subTitle: '',
              text: '截至10月末，全国门店总数超990间，充分延伸C端回收业务在（尤其是华南地区）的货源获取能力。月内新增3间拍拍严选门店，在成都与郑州两个城市核心商业区加密优品二手数码产品对C端销售的布局。'
            },
            {
              subTitle: '',
              text: '本地模型/本地商家服务：在全国范围内增设逾10个联营城市级运营中心（小仓），通过联动本地爱回收门店、标准化质检运营能力、拍拍严选门店，控一手货源供给，实现“本地收+本地卖”的极致周转能力。',
            },
            {
              subTitle: '',
              text: '在处理端，融合toB与toC能力，在质检定级时同时出具toB与toC销售的两份报告，提高内部B转C的流转效率，进一步控制平台二手电子设备交易的运营成本。'
            }
          ]
        },
        {
          title: '关键事件',
          list: [
            {
              subTitle: '发布《万物新生集团事故管理办法》，加强有序的运营管理能力',
              text: '10月15日，集团发布《万物新生集团事故管理办法》，旨在规范公司事故定级、上报、应对、跟踪等环节管理活动，降低不利影响和损失，提高运营效率，保障公司运营的有序推进。该制度主要包括：事故定级机制、事故上报机制、事故应对机制以及事故跟踪机制等内容，有利于公司对经营发展过程中各类事故的有效管理。同时，原办法统一纳入《万物新生集团事故管理办法》执行管理。'
            },
            {
              subTitle: '资金管理能力得到国际认可，获Adam Smith Awards Asia 2021奖项',
              text: '10月20日，集团宣布获得国际资金领域知名的Treasury Today Group’s Adam Smith Awards Asia 2021认可，在亚太市场突围斩获“中国区最佳资金管理”奖（“Best in Class Treasury Solution” in the PRC category）。万物新生资金部通过有序的资金管理办法、高效的信用与风险管理能力、领先的数字化与中心化账户管理实践，获得国际市场的高度认可。'
            },
            {
              subTitle: '举办第四季度产研日及主题团建',
              text: '10月29日，集团于上海举办季度产研日，CEO与COO就集团战略动态进行主题分享。此外，在产研日期间落实季度评优表彰，并举办具有趣味性的户外音乐会与团建活动。'
            }
          ]
        },
        {
          title: '行业动态',
          list: [
            {
              subTitle: '中国国务院印发《2030年前碳达峰行动方案》',
              text: '10月26日，国务院印发《2030年前碳达峰行动方案》（简称“行动方案”），明确“十四五”期间产业结构和能源结构调整优化目标，“十五五”期间基本健全绿色低碳循环发展政策体系、有利于绿色低碳发展的法律体系。此外，行动方案将循环经济助力降碳行动纳入“碳达峰十大行动”。（<a href="https://www.gov.cn/zhengce/content/2021-10/26/content_5644984.htm">原文链接-中文</a>）'
            },
            {
              subTitle: '中国国务院新闻办发布《中国应对气候变化的政策与行动》白皮书',
              text: '10月27日，国务院新闻办发布《中国应对气候变化的政策与行动》白皮书，重申对应对气候变化的中国倡议，包括坚持可持续发展的倡议，强调“要把应对气候变化纳入国家可持续发展整体规划，坚定走绿色低碳发展道路，倡导绿色、低碳、循环、可持续的生产生活方式”。（<a href="http://www.scio.gov.cn/zfbps/32832/Document/1715491/1715491.htm">原文链接-中文</a>）'
            },

          ]
        }
      ]
    }
  }
})

const US = () => ({
  seo: {
    title: 'Corporate Blog - ATRenew',
    description: 'ATRenew corporate blog provides the most up-to-date knowledge to the Company and the industry.',
    keywords: 'ATRenew、RERE、AiHuiShou、PJT、Paipai'
  },
  pageTitle: 'Corporate Blog',
  breadcrumb: 'Home / News / Corporate Blog',
  news: [
    {
      year: '2024',
      list: [
        {
          title: 'RERE.US Corporate Blog – December 2024',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in December 2024. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Jan 17, 2025',
          url: '/news/blog/202412',
        },
        {
          title: 'RERE.US Corporate Blog – November 2024',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in November 2024. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Dec 17, 2024',
          url: '/news/blog/202411',
        },
        {
          title: 'RERE.US Corporate Blog – October 2024',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in October 2024. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Nov 13, 2024',
          url: '/news/blog/202410',
        },
        {
          title: 'RERE.US Corporate Blog – September 2024',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in September 2024. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Oct 15, 2024',
          url: '/news/blog/202409',
        },
        {
          title: 'RERE.US Corporate Blog – August 2024',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in August 2024. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Sep 14, 2024',
          url: '/news/blog/202408',
        },
        {
          title: 'RERE.US Corporate Blog – July 2024',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in July 2024. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Aug 13, 2024',
          url: '/news/blog/202407',
        },
        {
          title: 'RERE.US Corporate Blog – June 2024',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in June 2024. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Jul 11, 2024',
          url: '/news/blog/202406',
        },
        {
          title: 'RERE.US Corporate Blog – May 2024',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in May 2024. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Jun 17, 2024',
          url: '/news/blog/202405',
        },
        {
          title: 'RERE.US Corporate Blog – April 2024',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in April 2024. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'May 14, 2024',
          url: '/news/blog/202404',
        },
        {
          title: 'RERE.US Corporate Blog – March 2024',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in March 2024. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Apr 17, 2024',
          url: '/news/blog/202403',
        },
        {
          title: 'RERE.US Corporate Blog – February 2024',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in February 2024. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Mar 7, 2024',
          url: '/news/blog/202402',
        },
        {
          title: 'RERE.US Corporate Blog – January 2024',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in January 2024. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Feb 7, 2024',
          url: '/news/blog/202401',
        }
      ]
    },
    {
      year: '2023',
      list: [
        {
          title: 'RERE.US Corporate Blog – December 2023',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in December 2023. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Jan 15, 2024',
          url: '/news/blog/202312',
        },
        {
          title: 'RERE.US Corporate Blog – November 2023',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in November 2023. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Dec 14, 2023',
          url: '/news/blog/202311',
        },
        {
          title: 'RERE.US Corporate Blog – October 2023',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in October 2023. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Nov 16, 2023',
          url: '/news/blog/202310',
        },
        {
          title: 'RERE.US Corporate Blog – September 2023',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in September 2023. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Oct 12, 2023',
          url: '/news/blog/202309',
        },
        {
          title: 'RERE.US Corporate Blog – August 2023',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in August 2023. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Sep 15, 2023',
          url: '/news/blog/202308',
        },
        {
          title: 'RERE.US Corporate Blog – July 2023',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in July 2023. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Aug 16, 2023',
          url: '/news/blog/202307',
        },
        {
          title: 'RERE.US Corporate Blog – June 2023',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in June 2023. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Jul 12, 2023',
          url: '/news/blog/202306',
        },
        {
          title: 'RERE.US Corporate Blog – May 2023',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in May 2023. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Jun 13, 2023',
          url: '/news/blog/202305',
        },
        {
          title: 'RERE.US Corporate Blog – April 2023',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in April 2023. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'May 15, 2023',
          url: '/news/blog/202304',
        },
        {
          title: 'RERE.US Corporate Blog – March 2023',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in March 2023. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Apr 12, 2023',
          url: '/news/blog/202303',
        },
        {
          title: 'RERE.US Corporate Blog – February 2023',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in February 2023. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Mar 16, 2023',
          url: '/news/blog/202302',
        },
        {
          title: 'RERE.US Corporate Blog – January 2023',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in January 2023. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Feb 13, 2023',
          url: '/news/blog/202301',
        },
      ]
    },
    {
      year: '2022',
      list: [
        {
          title: 'RERE.US Corporate Blog – December 2022',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in December 2022. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Jan 12, 2023',
          url: '/news/blog/202212',
        },
        {
          title: 'RERE.US Corporate Blog – November 2022',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in November 2022. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Dec 13, 2022',
          url: '/news/blog/202211',
        },
        {
          title: 'RERE.US Corporate Blog – October 2022',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in October 2022. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Nov 14, 2022',
          url: '/news/blog/202210',
        },
        {
          title: 'RERE.US Corporate Blog – Sept 2022',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in September 2022. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Oct 14, 2022',
          url: '/news/blog/202209',
        },
        {
          title: 'RERE.US Corporate Blog – Aug 2022',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in August 2022. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Sep 9, 2022',
          url: '/news/blog/202208',
        },
        {
          title: 'RERE.US Corporate Blog – Jul 2022',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in July 2022. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Aug 16, 2022',
          url: '/news/blog/202207',
        },
        {
          title: 'RERE.US Corporate Blog – June 2022',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in June 2022. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Jul 14, 2022',
          url: '/news/blog/202206',
        },
        {
          title: 'RERE.US Corporate Blog – May 2022',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in May 2022. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Jun 15, 2022',
          url: '/news/blog/202205',
        },
        {
          title: 'RERE.US Corporate Blog – Apr 2022',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in April 2022. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'May 9, 2022',
          url: '/news/blog/202204',
        },
        {
          title: 'RERE.US Corporate Blog – Mar 2022',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in March 2022. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Apr 11, 2022',
          url: '/news/blog/202203',
        },
        {
          title: 'RERE.US Corporate Blog – Feb 2022',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in February 2022. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Mar 15, 2022',
          url: '/news/blog/202202',
        },
        {
          title: 'RERE.US Corporate Blog – Jan 2022',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in January 2022. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Feb 10, 2022',
          url: '/news/blog/202201',
        },
      ]
    },
    {
      year: '2021',
      list: [
        {
          title: 'RERE.US Corporate Blog – Dec 2021',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in December 2021. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Jan 6, 2022',
          url: '/news/blog/202112',
        },
        {
          title: 'RERE.US Corporate Blog – Nov 2021',
          description: 'Below is a summary of the recent business development of the Company and the industry headlines in November 2021. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Dec 9, 2021',
          url: '/news/blog/202111',
        },
        {
          title: 'RERE.US Corporate Blog – Oct 2021',
          description: 'Below is a summary of our recent business developments and industry headlines in October 2021. Regarding operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Nov 9, 2021',
          url: '/news/blog/202110'
        },
        {
          title: 'RERE.US Corporate Blog – Sept 2021',
          description: 'Below is a summary of our recent business developments and industry headlines in September 2021. Regarding operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
          date: 'Oct 10, 2021',
          url: '/news/blog/202109'
        },
        {
          title: 'RERE.US Corporate Blog - August 2021',
          description: 'Below is a summary of our recent business developments and industry headlines in August 2021. Regarding operational metrics, please refer to the Company’s quarterly earnings release and the filings with the U.S. SEC.',
          date: 'Sep 8, 2021',
          url: '/news/blog/202108'
        }
      ]
    }
  ],
  articles: {
    '202412': {
      articleTitle: 'RERE.US Corporate Blog – December 2024',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in December 2024. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: '',
              text: 'In December, the number of self-operated standard AHS stores increased by nearly 40, and the number of franchised standard AHS stores increased by about 90, gearing up the pace of expanding order fulfillment network nationwide. AHS Recycle has set a goal of “establishing a 5,000-store nationwide recycling service network and building up a 5,000-pax doorstep recycling engineer team in three years” to strive for building a solid recycling service capability infrastructure.'
            },
            {
              subTitle: '',
              text: 'As part of the multi-category recycling initiatives, AHS Recycle has officially launched its game account recycling service. The first batch of services supports the recycling of Tencent/QQ Zone game accounts, including Honor of Kings, Peacekeeper Elite, and Naruto. This service involves online assessment and bidding by hundreds of business owners. Users receive pricings in 15 minutes, enjoy one-on-one customer service while cashing out their “virtual assets.”'
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'The Ministry of Commerce and four other departments jointly issued the “Pilot Work Plan for the Circulation of Second-hand Goods”',
              text: 'In December, the Ministry of Commerce and four other departments jointly issued the “Pilot Work Plan for the Circulation of Second-hand Goods”, aiming to form a number of replicable and promotable typical cases of second-hand goods circulation through a three-year pilot program. This will further advance the policy system for the circulation of second-hand goods and enhance the professional, standardized, distinctive, and branded operation levels of second-hand goods trading markets and corporates. Provincial-level commerce authorities, in conjunction with relevant departments, will organize the application work for pilot cities and corporates, and submit implementation plans to the Ministry of Commerce before January 24, 2025.'
            },
          ]
        },
      ]
    },
    '202411': {
      articleTitle: 'RERE.US Corporate Blog – November 2024',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in November 2024. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: '',
              text: 'AHS Recycle added a net total of nine self-operated stores in November, enhancing coverage in provincial capital and priority cities. Concurrently, the number of franchised standard stores in operation increased by a net total of 44, with increased penetration into lower-tier mass markets in regions like Guangdong, Hubei, and Xinjiang, thereby strengthening the service reach of AHS stores across various locations.'
            },
            {
              subTitle: '',
              text: 'ATRenew reported its unaudited third quarter 2024 financial results with total net revenue increased by 24.4% year-over-year to RMB4.05 billion. On profitability milestones, its non-GAAP operating profit exceeded RMB100 million and GAAP incomes turned positive for the first time. On business operations, trade-in solutions offered by its C2B segment become increasingly popular. This trend was also seen in the fourth quarter 2024. During the Singles’ Day grand promotion, the total value of products recycled via JD.com increased by 77% year-on-year, while trade-in solution was the major growth driver during this period.'
            },
            {
              subTitle: '',
              text: 'ATRenew’s practices of the circular economy in pre-owned consumer electronics industry was recognized by United Nations Global Compact\'s “Forward Faster: 20 Examples of Private Sector\'s Sustainable Development in China”. This prestigious acknowledgment highlights ATRenew’s practices as the only showcase from the secondhand consumer electronics industry. The case review in this instance was completed by a panel of experts composed of high-level representatives from the United Nations system and university specialists. Also included are the sustainable development practices in various fields by a total of 20 well-known enterprises and institutions, such as Tencent, Ant Group, Ctrip, CATL, and the China Development Bank.'
            },
            {
              subTitle: '',
              text: 'AHS Recycle and L\'Oréal Groupe made a joint appearance at the China International Import Expo, co-creating a new model of circular consumption through the “Revive” initiative. Starting from November 11th, users in approximately 30 cities across the country have the opportunity to receive exclusive benefits from L\'Oréal Groupe by dropping off empty cosmetic containers of any brand through AHS Recycle’s recycling kiosks, thereby jointly building an environmentally friendly recycling and consumption concept.'
            },
            {
              subTitle: '',
              text: 'AHS Recycle supports Beijing Yizhuang and Hubei Daye Half Marathons with environmental actions, recycling empty plastic bottles and packages on-site, and creating eco-friendly medals for finishers using plastic bottle caps. By doing so, they combine environmental awareness with the spirit of sports through general public events.'
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'Jiangsu Province launches a one-month consumer electronic products trade-in subsidy program',
              text: 'Jiangsu Province has allocated a certain scale of provincial fiscal funds for a special subsidy program on consumer electronic products, valid from November 27, 2024, to December 31, 2024, to subsidize the purchase needs of individual consumers for new mobile phones, tablets, digital cameras, etc. The subsidy standard is 15% of the product\'s sales price, up to RMB2,000. This subsidy requires offline redemption with personal ID verification.'
            },
          ]
        },
      ]
    },
    '202410': {
      articleTitle: 'RERE.US Corporate Blog – October 2024',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in October 2024. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'AHS Recycle net added 13 self-operated stores and 28 jointly operated stores in October, further expanding its trade-in service and retail network. AHS Recycle’s jade in-store recycling under the multi-category recycling business was well received by users, and the store appointment calendar was updated to serve 16 cities across the country in October. '
            },
            {
              subTitle: '',
              text: 'AHS Recycle collaborates with an increasing number of consumer brand owners while rolling out the “Revive Initiative.” In October, it joined hands with Milkground, Kellogg’s, Fornet, ChaPanda, Meituan Qingshan, etc. to promote the recycling, classification and reuse of recyclables such as plastics, paper products, packaging goods, and fabrics among residents. Users are encouraged to redeem physical gifts or vouchers provided by brand owners to jointly practice a sustainable circular economy.'
            },
            {
              subTitle: '',
              text: 'In October, PJT Marketplace team brainstormed its strategy 2.0 to further leverage its infrastructure capabilities of the pre-owned consumer electronic products transaction industry. PJT aims to optimize the merchant service model on the supply side, integrate quality inspection standards and pricing capabilities on the processing side, and enhance the experience for users during the sales process. Previously, the management of ATRenew addressed at a public meeting that PJT, its B2B trading platform, had joined forces with Douyin e-commerce to empower more small and medium-sized businesses to thrive in the new retail landscape by leveraging PJT’s leading second-hand consumer electronics supply chain.'
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'China establishes new state-owned enterprise on resource-recycling',
              text: 'The founding ceremony of China Resources Recycling Group Co., Ltd., a centrally administered state-owned enterprise, was held in Tianjin on October 18. According to Xinhua News Agency, this newly established central enterprise will specialize in resource recycling and undertake the important tasks of building a national, functional resource recycling and reuse platform. “The registered capital of the enterprise is 10 billion yuan ($1.4 billion), with the State-owned Assets Supervision and Administration Commission of the State Council representing the State Council in fulfilling the responsibilities of the investor.” “The establishment of the company is an important decision and arrangement made by the CPC Central Committee to perfect an economy that facilitates green, low-carbon, and circular development, and advance the building of a beautiful China on all fronts, said Xi Jinping, general secretary of the Communist Party of China (CPC) Central Committee '
            },
          ]
        },
      ]
    },
    '202409': {
      articleTitle: 'RERE.US Corporate Blog – September 2024',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in September 2024. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'Continuously strengthening its multi-category recycling services, AHS Recycle released its September Jade Recycling Calendar. This service tour was first launched in 14 cities nationwide, including Shanghai, Suzhou, Nanjing, Hefei, and Zhengzhou. Consumers bring their jade jewelry pieces to the stores according to appointment instructions and they will get cash on the spot after the specialists make an offer. This new service creates more value for users with the high-quality fulfillment scenario of AHS stores.'
            },
            {
              subTitle: '',
              text: 'AHS Recycle onboards more brand partners to its original “Revive” initiative. The initiative joins hands with Kleenex to collect used paper and plastic goods while urging the public to protect the ocean. With JD.com, the initiative circulates empty liquor bottles when consumers pay for a new bottle of liquor. There are milk carton recycling and remake initiatives with Satine by Yili Dairy and Zhenguoli by Mengniu Dairy. There were also pet care product recycling programs co-launched with Dyson and plastic recycling programs kicked off with Coco-Cola, GRPG, and Totele on World Cleanup Day.'
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'AHS Recycle landed 108 stores in September 2024',
              text: 'In September, AHS Recycle reached its milestone of either opening or revamping 108 stores, including Deji Plaza, a premium shopping mall in Nanjing. The landing of those stores demonstrates the next-level service accessibility and multi-category recycling capabilities of AHS Recycle.'
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'IDC launched its quarterly tracker for global used smartphone shipments',
              text: 'In the second quarter of 2024, 49.88 million used smartphones were shipped into the global market, representing an increase of 7.4% year-on-year, <a rel="noopener noreferrer" target="_blank" href="https://mp.weixin.qq.com/s/Np6Bs0ggWUuAql6rFRKOvQ">according</a> to International Data Corporation (“IDC”). Meanwhile, trade-in programs continue to fuel the industry. China is the largest single market for used smartphone shipments, accounting for 38% of the global market with 18.7 million units, which grew by 10% year-on-year in the second quarter of 2024. Used iPhones remained the most popular smartphones in China as its shipments accounted for over one-third of the market share.'
            },
          ]
        },
      ]
    },
    '202408': {
      articleTitle: 'RERE.US Corporate Blog – August 2024',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in August 2024. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'In August, AHS Recycle net opened 10 new self-operated standard stores, distributed in high-quality shopping malls in provincial cities. There was a total of 24 net additions of franchised standard stores in Sichuan, Guizhou, and Hunan provinces, etc. The expansion of AHS Recycle store network satisfies a wider group of user’s demand for recycling and trade-in.'
            },
            {
              subTitle: '',
              text: 'The “Revive” initiative led by AHS Recycle and well-known brand owner partners continues to gain popularity among consumers. In August, joining hands with Meituan’s Tsingshan Project and ChaBaiDao, as well as dairy product brands Fonterra and Anchor, AHS Recycle aimed at recycling more plastic milk tea cups and milk cartons, respectively, for green disposal. At the same time, “Revive” has launched more consumer engagements, encouraging consumers to team up for greater “Revive” quests while raising the general public’s awareness of green consumption.'
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'ATRenew announced 2Q24 earnings results with quality revenue and profit growth',
              text: "ATRenew reported a strong topline growth at 27.4% year-on-year to RMB3,780 million in 2Q24, exceeding the high-end of its revenue guidance. The Company reported an adjusted operating profit of over RMB94 million, representing an adjusted operating margin of 2.5%. Exciting results are seen in the growth of trade-in orders, 1PtoC retail revenues and multi-category recycling business."
            },
            {
              subTitle: 'ATRenew announced improvement in S&P Global Corporate Sustainability Assessment ESG Score for 2024',
              text: 'ATRenew achieved a notable improvement with an S&P Global ESG Score of 35 in 2024, up from 33 in the previous year. This places the Company in the 93rd percentile among its global retail industry peers, significantly surpassing the industry average. The assessment identified climate strategy, human capital management, and business ethics as among the most material drivers of ATRenew’s ESG performance. ATRenew’s commitment to transparency is evidenced by its high data availability, with 71% of required disclosures and 66% of additional disclosures made available to S&P Global. This level of transparency significantly exceeds the industry average and underscores the Company’s dedication to open and ethical business practices.'
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'Ministry of Commerce led to release a circular further clarifying subsidies for home appliances and computers trade-in',
              text: 'On August 24, Chinese authorities are offering financial incentives to encourage consumers to replace their old home appliances with new ones, according to the circular released by the Ministry of Commerce and three other government departments. The circular addressed the synergy between central and local policies. Eight categories of home appliances with high energy efficiency, including refrigerators, washing machines, televisions, air conditioners and computers, can enjoy trade-in subsidies. The subsidies will be 15 to 20 percent of the final sale prices of the products, depending on the energy efficiency grade. Each consumer is allowed to receive subsidies for one item in one category and the subsidies for every single product are limited to RMB2,000. The circular aimed to optimize the subsidy application and redemption process, improve digital management and the equal roll-out of both online and offline application channels. Cities including Beijing and Guangzhou announced detailed action plans.'
            },
          ]
        },
      ]
    },
    '202407': {
      articleTitle: 'RERE.US Corporate Blog – July 2024',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in July 2024. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'In July, the total number of self-operated standard AHS stores in high-tier cities such as Shenzhen and Chengdu increased by ten. AHS Recycle continued setting up more franchised standard stores in shopping malls in multiple cities by providing industry partners with recycling know-how and high-quality value-for-money pre-owned electronic products for retail.'
            },
            {
              subTitle: '',
              text: 'AHS Recycle leads a series of crossover consumer campaigns themed “Recycle for Reborn” with Aldi, Sekkisei and Bilibili to collect empty plastic bottles, cosmetics containers, and idle consumer electronic product accessories and toys, respectively. The quests are returning the above-mentioned to AHS Recycle for specific rewards. As a result, consumers and brand owners have joined AHS Recycle’s efforts to practice eco-friendly recycling and circular consumption activities.'
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'NDRC and the Ministry of Finance strengthen support for large-scale consumer goods trade-in',
              text: 'In July, the National Development and Reform Commission (NDRC) and the Ministry of Finance co-released a document on how to implement the plan for equipment renewal and trade-in of consumer goods, proposing relevant measures to coordinate the allocation of about 300 billion yuan of ultra-long-term special treasury bond funds to strengthen support for large-scale equipment updates and consumer goods trade-in. The document proposes to support the trade-in of household appliances including computers, and clarifies the subsidy standards.'
            },
            {
              subTitle: 'AHS Recycle joins hands with partners to advocate the development of the circular economy',
              text: 'Recently, AHS Recycle, together with 22 brand owners and dozens of business owners, partnered with the commitment to environmental protection and sustainable development. The group also jointly launched the “Carbon Recycling Carnival” in Shanghai – an interactive consumer campaign to promote the circular economy. The business communities, neighborhoods, and campuses teamed up to advocate a green lifestyle with a diversified user experience. By comprehensively leveraging the unique advantages and resources of all participants, consumers are provided with more convenient recycling channels, thus becoming increasingly aware of green and low-carbon actions and consumption.'
            },
          ]
        },
      ]
    },
    '202406': {
      articleTitle: 'RERE.US Corporate Blog – June 2024',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in June 2024. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: "In June, there was a net increase of 10 self-operated AHS stores and 13 standard joint-operated AHS stores. The new joint operations are located in Guangdong, Jiangsu, Zhejiang, Hebei, Guizhou and Shandong provinces, further improving market penetration in new markets. Meanwhile, besides self-operated stores, AHS Recycle places a few value-for-money like-new used phone samples at its joint-operated stores, strengthening consumers’ awareness and shopping experience of such products."
            },
            {
              subTitle: '',
              text: "AHS Recycle extended its doorstep pre-owned product recycling services in Shanghai to luxury goods. Users enjoy a five-step hassle-free recycling service at home to dispose of their premium but idle goods, including luxury bags and watches. The five steps are online appointment, to-door visit, price confirmation, authentication and remittance. In the second half of 2024, there is a pipeline of first-tier cities in China for launching doorstep services for luxury goods recycling."
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'ATRenew implements annual responsible disclosure for fourth consecutive year by releasing its 2023 ESG report',
              text: "For the fourth consecutive year, ATRenew has released its environmental, social and governance (ESG) report, responding to the concerns of key stakeholders such as users, employees, investors, regulators, and communities on sustainable development issues. The report disclosed that ATRenew’s greenhouse gas emission intensity (scope 1 + scope 2) in 2023 was 0.28 tons of carbon dioxide equivalent/million yuan, a year-on-year decrease of 6.67%, representing a fourth consecutive year of reduction. In 2023, ATRenew recycled and supervised the green disposal of more than 110,000 useless electronic devices, reducing electronic product pollution by nearly 18 tons. At the same time, it improved green logistics and further implemented eco-friendly reuse of packing materials. In addition, ATRenew strengthened internal audit work, improved the standardization of business processes, advanced its risk management capabilities, and rectified 100% of defects identified during SOX internal control review."
            },
            {
              subTitle: 'PJT Marketplace advances new retail supply chain with Douyin e-commerce and industry partners',
              text: 'On June 26th, PJT Marketplace, the B2B transaction platform of ATRenew hosted an industry conference in Nanjing for secondhand consumer electronics business owners to exchange ideas. During the conference, PJT Marketplace, together with the new B2C retail platform Paipai and Douyin e-commerce, hosted a strategic cooperation signing ceremony. PJT Marketplace opened its industry-leading second-hand consumer electronics supply chain capabilities to empower more small and medium-sized businesses in new retail. During the conference, a number of industry experts from Douyin e-commerce, D.Phone, and Huitongda were invited to share their experiences. Recently, this supply chain empowerment has formed model stores and is being piloted in large domestic chain stores of smartphones and consumer electronics.'
            },
          ]
        },
      ]
    },
    '202405': {
      articleTitle: 'RERE.US Corporate Blog – May 2024',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in May 2024. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: "In May, there were 10 net additions of self-operated standard AHS stores, mainly in shopping malls located in popular commercial areas in Shanghai, Chengdu, Tianjin, Hefei, Wuhan, among others. This is to increase the density of recycling services for local users and strengthen the ability to obtain high-quality first-hand sources of supply. During the same period, there were 11 net new franchise standard store openings, promoting the brand penetration and empowering franchisees of the second-hand consumer electronics industry in western China."
            },
            {
              subTitle: '',
              text: "At the end of May, with the release of Honor's new phones and the warm-up of the 618 promotion, Honor Mall and AHS Recycle jointly launched an environmentally friendly recycling campaign. Through the jointly initiated trade-in and green recycling of electronics accessories, consumers are encouraged to try low-carbon consumption options. At the same time, Huawei Mall, with its official subsidies, jointly promoted the trade-in of flagship earphones with AHS Recycle."
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'ATRenew announced unaudited financial results for the first quarter 2024 with non-GAAP opening profit jumped by over 80% year-on-year',
              text: "On May 20th, ATRenew issued its earnings release for the first quarter of 2024. Total revenues increased by 27.1% year-on-year to RMB3.65 billion, reaching the high end of its quarterly guidance. Non-GAAP operating profit increased by 80.8% year-on-year to RMB80.22 million, representing a seventh consecutive profitable quarter under the non-GAAP measure. As at the end of March 2024, cash and cash equivalents, restricted cash, short-term investments and funds receivable from third party service providers totaled RMB2.60 billion."
            },
            {
              subTitle: 'ATRenew renewed business cooperation agreement with JD.com to jointly drive the strategic development of the second-hand goods transaction business',
              text: 'On May 31st, ATRenew announced the renewal of its business cooperation agreement with JD.com. The renewed agreement covers the term from June 1, 2024 to December 31, 2027. The two parties will continue to cooperate in the second-hand business by integrating resources and leveraging their respective strengths. The cooperation will also continue in areas such as user traffic, technology support, and logistics, among others. Together, the two parties aim to provide high quality and competitive prices for second-hand goods, thus enhancing customer experiences in the second-hand market.'
            },
            {
              subTitle: 'Many articles in People\'s Daily cited ATRenew’s industry participation and leading capabilities',
              text: 'The People\'s Daily front-page <a rel="noopener noreferrer" target="_blank" href="https://www.peopleapp.com/column/30045152007-500005457024">article</a> “Giving a second life to idle phones” extensively cited ATRenew’s industry experience and ecological capacities in the recycling and trading of second-hand consumer electronics. Prior to this, an <a rel="noopener noreferrer" target="_blank" href="https://wap.peopleapp.com/article/7426845/7257473">article</a> titled “Let enterprises focus on innovation and develop with peace of mind (Legal Headlines)” was published, citing the market test results obtained since the release of the electronic product compliance refurbishment guidelines, backing companies like ATRenew to achieve an additional high-quality growth from compliance refurbishment business.'
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'Apple to open first store in Malaysia as Asia expansion continues',
              text: 'Apple Inc. will open its first retail location in Malaysia next month as it expands its presence in Southeast Asia to augment both sales and operations in Asia. The company’s first store in the country is at the Exchange TRX mall in Kuala Lumpur which will open on June 22 at 10 am local time.'
            },
          ]
        },
      ]
    },
    '202404': {
      articleTitle: 'RERE.US Corporate Blog – April 2024',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in April 2024. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: "AHS Recycle has co-launched not-for-profit circular consumption initiatives. Together with Mengniu, AHS Recycle established a strategic ESG collaboration to promote low-carbon development and consumer participation in sustainable consumption, while landing a local public welfare project to celebrate World Earth Day. Joining hands with Guyu, Cotton Times, and Freshippo, the partnerships urge the recycling and reuse of empty skincare containers, second-hand clothes, and paper products."
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'ATRenew appoints Mr. Mervin Zhou as director',
              text: "On April 26, ATRenew announced the appointment of Mr. Mervin Zhou as a new member of the Company's board of directors (the \"Board\"), the compensation committee of the Board, and the nominating and corporate governance committee of the Board, to fill the vacancies arising from the resignation of Mr. Lijun Xi. Mr. Mervin Zhou is a vice president of JD.com (NASDAQ: JD and HKEX: 9618 (HKD counter) and 89618 (RMB counter)) and head of Strategic Investment. The appointment reflects ATRenew’s commitment to excellence and strategic growth. ATRenew expressed the gratitude to Mr. Lijun Xin for his outstanding contributions during his tenure."
            },
            {
              subTitle: 'Mr. Rex Chen, President of ATRenew presents new practices in second-hand products circulation at Hainan International Consumption Season Forum',
              text: 'On April 14, the Hainan International Consumption Season Forum took place in Haikou, spotlighting green circulation. Mr. Rex Chen, presented a keynote speech on the circulation of second-hand products. “ATRenew, as a corporation, follows the national guidelines and proactively drives the circulation of used goods through trade-in programs. This not only benefits consumers with convenient solutions to dispose of used goods for economic rewards but also provides incentives for consumers to purchase new.” Mr. Rex Chen also showcased ATRenew’s innovative practices in business and technology upgrades, and their contribution to industry expansion, standardization, and digitalization.'
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'Local regulators issue action plans following central government’ promotion of massive trade-in of consumer goods',
              text: 'Taking Shanghai as an example, the municipal government issued a notice on the “Shanghai Action Plan to Promote Large-Scale Equipment Updates and Trade-in of Consumer Goods (2024-2027)”, proposing to “improve the recycling and reuse system” and supporting second-hand product transactions. The plan proposes to “promote the standardization of second-hand electronic product transactions, explore the formulation of standards and specifications for information removal on used electronics, and prevent leakage and malicious recovery of user information” and to “support the construction of online platforms and offline markets for second-hand product transactions.”'
            },
          ]
        },
      ]
    },
    '202403': {
      articleTitle: 'RERE.US Corporate Blog – March 2024',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in March 2024. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: "AHS Device, the overseas business division of ATRenew, has entered into a new partnership with a leading Japanese general trading company. This collaboration aims to install advanced self-service recycling kiosks in Japan. In addition to piloting innovative compliance-based recycling processes, the installations will also serve to further investigate the viability of ATRenew's automated inspection solutions for used smartphones and consumer electronics in global markets."
            },
            {
              subTitle: '',
              text: "PJT Marketplace has revised its quality standards for models qualified for retail sales, prudently lowering the eligibility threshold for several popular product lines of used smartphones and tablets. This change expands the range of product listings available to bidders with a demand for bulk purchases of like-new inventory."
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'ATRenew announces its 4Q23 & FY23 earnings with a USD20 million share repurchase program',
              text: "In the fourth quarter of 2023, ATRenew’s total net revenues increased by 29.9% year-on-year to RMB3.87 billion, exceeding the high-end of its guidance range. During the quarter, ATRenew reported a net income of RMB2.78 million, marking its first net income under the GAAP measures. It also realized operating income under the non-GAAP measures for a sixth consecutive quarter. In the full year of 2023, the total revenues saw a year-on-year growth of 31.4% to RMB12.97 billion, with the non-GAAP operating profit surpassing RMB250 million, representing a steep increase from RMB6.90 million in the full year of 2022. Concurrent with the financial report release, the company announced a stock repurchase plan of USD20 million, valid for a period of 12 months, demonstrating management's confidence in the company’s development."
            },
            {
              subTitle: 'China Environmental News features ATRenew on the development of the second-hand electronic product transaction industry',
              text: 'China Environment News wrote a feature <a rel="noopener noreferrer" target="_blank" href="https://res.cenews.com.cn/h5/news.html?id=1121922">story</a> following an interview with Mr. Tian Mu, Vice President at ATRenew, on the overview, challenges, and outlook of China’s second-hand electronics transaction industry. Mr. Tian analyzed the issues present throughout the industry chain, from recycling and processing to distribution, and underscored the critical responsibilities of corporates in ensuring user privacy clearance and information security protection.'
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'China to promote equipment renewals and trade-ins of consumer goods',
              text: 'The State Council released an action plan to promote large-scale equipment renewals and trade-ins of consumer goods, according to a circular issued in March, 2024. The implementation of this plan is designed to carry out recycling and re-utilization initiatives. Additionally, it seeks to accelerate the development of a new logistics system model for trade-ins, support the circulation of pre-owned goods, and promote the standardization of transactions involving second-hand electronic products.'
            },
          ]
        },
      ]
    },
    '202402': {
      articleTitle: 'RERE.US Corporate Blog – February 2024',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in February 2024. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Key highlights',
          list: [
            {
              subTitle:'',
              text: 'In the fourth issue of the micro-documentary “<a rel="noopener noreferrer" target="_blank" href="https://m.chinanews.com/wap/detail/chs/sp/10155339.shtml">《师想者来了》</a>” produced by China Europe International Business School (CEIBS), Mr. Rui Meng, Professor of Finance and Accounting at CEIBS, talks to Mr. Wang Yongliang, Executive President at ATRenew while experiencing the applications of the circular economy in real life. The during the walk, they discussed the parallel development of commercial, social and environmental value of corporate. In the case of ATRenew’s business innovation, more diversified groups of target users are encouraged to try out recycle-and-reuse services which is becoming a leading practice of green consumption upgrade.'
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'CCFEA discussed large-scale equipment renewal and trade-in of consumer goods',
              text: 'On February 24, prior to the two sessions, President Xi presided over the fourth meeting of the Central Financial and Economic Commission. The meeting was held to discuss issues of large-scale equipment renewal and trade-in of consumer goods. During the meeting, it was stressed that efforts should be made to promote trade-in of durable goods along with large-scale recycle-and-reuse. As far as trade-in of consumer goods is concerned, the central financial authorities should coordinate with local governments, and support all links in a coordinated manner, so that consumers will get more benefits.'
            },
          ]
        },
      ]
    },
    '202401': {
      articleTitle: 'RERE.US Corporate Blog – January 2024',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in January 2024. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: "ATRenew prepares its plan for uninterrupted services during the Chinese New Year holidays. AHS Recycle, the C2B recycling business, keeps its secondhand consumer electronics and key non-electronics new categories recycling business open across all channels while 90% of the standard stores under direct operation keep business as usual throughout public holidays. PJT Marketplace keeps its main functions including B2B bidding, wholesale, retail market and spare stock business running so that business users have access to sufficient supply and demand. Paipai Marketplace adjusts its B2C inventory management and go-to-market strategies for the holiday season, providing quality used goods shopping solutions non-stop."
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'China Central Television (CCTV) recognizes ATRenew’s business model, supply chain technologies and industry standards',
              text: 'ATRenew’s secondhand consumer electronics recycling and circulation business model was <a rel="noopener noreferrer" target="_blank"  href="https://mp.weixin.qq.com/s/JLWSiGXDRpGls_-TyFpIpQ">reported</a> by News Channel of CCTV. To highlight, ATRenew’s safe and convenient recycling and trade-in service capabilities are recognized by consumers. Its Dongguan automated operation center, equipped with an independently developed quality inspection system, has also received acknowledgement for advanced efficiency in quality inspection and transaction. '
            },
            {
              subTitle: 'People’s Daily reports statistics on uses of pre-owned mobile phones',
              text: 'The <a rel="noopener noreferrer" target="_blank"  href="http://paper.people.com.cn/rmrb/html/2024-01/05/nw.D110000renmrb_20240105_1-07.htm">article</a> quoted suggestions from Yongliang Wang, executive president of ATRenew, for a standardized development of the secondhand smartphone recycling industry.'
            },
            {
              subTitle: 'ATRenew interprets inspection standards of used electronics at Reverse Logistics and Low-Carbon Supply Chain Annual Conference',
              text: 'The 2024 Annual Meeting of the Reverse Logistics and Low-Carbon Supply Chain Industry Alliance was held in Shanghai. Two major joint standards were released, namely “Inspection and Grading Specifications for Second-hand Consumer Electronic Products” and “Inspection and Service Specifications for Second-hand Consumer Electronic Products”. ATRenew attended the meeting as the chairman unit of the alliance, and as the co-developer, interpreted the two specifications in detail. The annual meeting is expected to accelerate the green and low-carbon transformation of the supply chain and promote the standardized development of the second-hand consumer electronics industry.'
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: "IDC reports a rebounding smartphone shipment in China",
              text: "According to preliminary <a rel='noopener noreferrer' target='_blank'  href='https://www.idc.com/getdoc.jsp?containerId=prAP51817224'>data</a> from the International Data Corporation (IDC), Chinese smartphone market “is moving towards recovery with the fourth quarter of 2023 delivering year-over-year growth of 1.2% on shipments of 73.6 million units, marking the end of ten consecutive quarters of year-over-year decline. The resilient high-end consumers in the tier 1-3 cities were the key drivers, fueling demand for flagship products,” such as iPhone 15, Huawei Mate 60, Xiaomi 14 and vivo X100, etc. In terms of market share in China, Apple ranked top in smartphone shipment in the fourth quarter and full year of 2023."
            },
          ]
        },
      ]
    },
    '202312': {
      articleTitle: 'RERE.US Corporate Blog – December 2023',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in December 2023. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: "In December, the numbers of net standard store openings under the self-operated model and joint-operated model were 29 and 11, respectively. These new stores cover premium shopping plazas in high-tier cities including Beijing, Guangzhou, Shanghai, Chengdu, Wuhan, Nanjing and Zhengzhou, and serve users' demand for getting cash for their idle electronics and high-value goods while penetrating popular shopping malls in key cities in northern China and northwestern China."
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'ATRenew honored as a public company case study for innovative ESG practices in 2023 by Securities Daily',
              text: "In December, Securities Daily hosted the 2023 Public Company Environmental, Social and Corporate Governance Conference in Beijing. The event selected and honored outstanding ESG innovation practices. It was committed to promoting the establishment and improvement of the local ESG ecosystem, providing a professional and authoritative investment benchmark for the capital markets, encouraging green operations among corporates, helping enterprises to tap endogenous power, setting an excellent benchmark in the industry, and leading high-quality economic and social development in the new era. ATRenew’s innovative ESG business practice of “Internet + Environmental Protection” was one of the awarded cases."
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: "Counterpoint Research notes global premium smartphone market continues to see record sales in 2023",
              text: 'According to Counterpoint Research’s latest monthly <a rel="noopener noreferrer" target="_blank"  href="https://www.counterpointresearch.com/insights/global-premium-smartphone-market-continues-record-growth-2023/">Market Pulse</a>, the global premium smartphone (wholesale price ≥$600) market’s sales are likely to grow 6% YoY in 2023 to hit a new record. “This contrasts with the decline projected for the overall global smartphone market this year. The premium segment is likely to capture close to one-fourth of the global smartphone market sales and 60% of the revenues in 2023.” The research house also notes that China, India, the Middle East and Latin America are likely to set a new record for premium market sales in 2023.'
            },
            {
              subTitle: "IDC’s insights: Chinese consumers’ inclination towards value-for-money substitutes and second-hand smart electronic devices",
              text: "IDC has recently <a rel='noopener noreferrer' target='_blank'  href='https://www.idc.com/getdoc.jsp?containerId=prCHC51425923'>released</a> the top ten insights into China's smart electronics market in 2024, predicting a general consumption trend of returning to the intrinsic value of goods. Consumers are inclined to prudent spending behavior in electronic devices, and to opt for value-for-money products. As the consumer electronics market becomes increasingly transparent and diversified in distribution channels, users’ purchasing considerations will be overlaid with multiple influences such as fairly priced substitutes and second-hand products."
            },
          ]
        },
      ]
    },
    '202311': {
      articleTitle: 'RERE.US Corporate Blog – November 2023',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in November 2023. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'In November, there were net additions of three self-operated AHS stores and six joint-operated standard stores. Direct store operation additions mainly covered Changsha and Beijing while franchisees were located in Guangdong, Sichuan and Chongqing areas.'
            },
            {
              subTitle: '',
              text: 'Since November 2023, AHS Recycle has won the recycling qualification for N-1 and N-2 generations of iPhone products within Apple’s direct retail systems in mainland China. Such an update allows users to trade in used iPhone 13 and iPhone 14 lineup products for new iPhone products through Apple’s direct-to-consumer channels backed by AHS Recycle. The pre-owned phones will be handed over to AHS Recycle for further handling after front-end collection by Apple. This qualification update reflects the brand owner’s recognition of AHS Recycle’s supply chain capabilities. In addition, throughout this year’s Single’s Day grand promotion, there was a surge in user enthusiasm for recycling and trade-in. During the pre-sale period of the grand promotion, the total value of C2B recycling orders increased significantly by 60% year-on-year.'
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'Xinhua Credit included ATRenew’s circular business in its ESG case studies',
              text: "On November 13, the list of outstanding ESG case studies for the second Xinhua Credit Jinlan Cup was <a rel='noopener noreferrer' target='_blank'  href='https://bm.cnfic.com.cn/sharing/share/articleDetail/176810749/1'>released</a>. ATRenew was selected as an outstanding ESG case for “taking root in the circular economy track and helping to achieve the goal of carbon peak and carbon neutrality”. This case collection was sponsored by the China Economic Information Service. Enterprises and institutions in various fields and industries at home and abroad actively submitted applications. The cases collected cover energy, electric power, automobiles, logistics and transportation, the Internet industries, etc."
            },
            {
              subTitle: 'ATRenew’s topline and adjusted profit in 3Q23 exceeded expectation',
              text: 'On November 22, ATRenew released its third-quarter earnings. Total revenue increased by 28.4% year-on-year to RMB3.26 billion, breaking through the high end of management’s guidance on the back of a counter-cyclical growth momentum of the secondhand economy. Meanwhile, its non-GAAP operating income totaled RMB73.8 million, representing a year-on-year increase of 585.5%. The adjusted operating profit margin was 2.3%. The profitability results were beyond expectations. Sales of the self-operated refurbishment business totaled RMB200 million, and the multi-category recycling volume (GMV) has reached RMB350 million, contributing more value to users.'
            },
            {
              subTitle: 'ATRenew recognized in “Global Business Responsibility Award 2023” by Reuters',
              text: 'ATRenew stood out among the finalists of Reuters’s “Global Business Responsibility Award 2023”. Under the category of Circular Transition Award, ATRenew was honored “highly commended”, while its case study outruns those submitted by a number of world-renowned companies such as Oracle, Schneider Electric, and PepsiCo.'
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: "Goldman Sachs issues China Smartphones November updates",
              text: 'Goldman Sachs concluded on price and specifications of top-selling models by the end of November 2023: “1) Higher price: The average selling price of top-selling models in Nov is US$515, better than May (US$260), showing a better momentum for high-end phones in 2H23; and 2) Smaller discount: The average selling price vs. the average launch price is 15% below, better than May (17% below).”'
            },
            {
              subTitle: "Alibaba upgraded Xianyu as one of its first batch of strategic innovative businesses",
              text: 'At the earnings conference call on November 16, Eddie Yongming Wu, CEO of Alibaba announced the group’s first batch of strategic innovative businesses, namely 1688, Xianyu, DingTalk, and Quark. The above-mentioned strategic innovative businesses are operated as independent subsidiaries, breaking the previous positioning restrictions within the group and using more independent strategies to face the broadest market. Alibaba Group will make continuous investment in a 3 to 5-year cycle.'
            },
          ]
        },
      ]
    },
    '202310': {
      articleTitle: 'RERE.US Corporate Blog – October 2023',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in October 2023. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'In October, a total of ten new directly managed AHS Selection stores were opened in Shanghai, Qingdao and Chengdu, etc., and these AHS Selection stores combine recycling and selling of quality pre-owned products. A total of nine standard franchise stores were opened in mid-level cities, mainly in Zhejiang, Guangdong and Jiangxi provinces. The stores serve popular shopping malls while raising consumer awareness of AHS Recycle. ',
            },
            {
              subTitle: '',
              text: 'AHS Recycle’s multi-category strategy, rooted in its store stratification, has made positive progress, with monthly recycling value exceeding RMB100 million. In addition, recycling operations in Shanghai and Beijing both realized their RMB100 million monthly recycling value milestones, in October. These re-affirm the promising outlook for the circular economy as well as users’ trust in AHS Recycle.'
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'ATRenew shortlisted as a national circular economy standardization pilot project',
              text: "ATRenew applied for the 2023 National Circular Economy Standardization Pilot Demonstration Project with the “Reuse of Secondhand Consumer Electronic Products”. It’s the only shortlisted project involving the reuse of pre-owned consumer electronics in the field of “waste electrical and electronic product recycling” after expert review and soliciting opinions from relevant departments. The list was announced by the National Standardization Management Committee for public review. "
            },
            {
              subTitle: 'AHS Recycle and Warrior Shoes initiated the “Old Shoes Step to New Life” project',
              text:"The joint initiative aims to reduce environmental pollution from randomly discarded shoes and urge the remake and reuse of recyclables. 35 AHS stores in Beijing, Shanghai, Shenzhen, Hangzhou and Chengdu were designed for this initiative. Users can bring used shoes of any brand to these stores in exchange for eco-friendly bags and lucky draw for winning Warrior products. It encourages more users to participate in the eco-friendly recycling of idle goods."
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: "Shenzhen unveils China second-hand electronics transaction center",
              text: "In order to promote the recycling and reuse of second-hand electronics, Shenzhen has built a display and trading platform combining wholesale and retail, while introducing a number of Internet companies to Huaqiangbei, to strengthen the integration of marketplaces and offline industries. AHS Recycle, PJT Marketplace and Paipai Marketplace, business segments of ATRenew, settled in as key corporate participants to assist in the compliance and standardized development of the industry."
            },
            {
              subTitle: "CCS Insight’s prediction for second-hand iPhones",
              text: "According to recent <a rel='noopener noreferrer' target='_blank'  href='https://www.ccsinsight.com/company-news/ccs-insights-predictions-for-2024-and-beyond-revealed/'>predictions</a> broadcasted by CCS Insights, over 50% of the 1.3 billion iPhones in use will be second-hand devices by the end of 2023. From 2024, the average life of an iPhone lasts eight years. In contrast, second-hand Android phones represent less than 25% of Android’s installed base."
            },
          ]
        },
      ]
    },
    '202309': {
      articleTitle: 'RERE.US Corporate Blog – September 2023',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in September 2023. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'In September, AHS Recycle has added 9 self-operated standard stores, mainly located in popular shopping malls in Hangzhou, Changsha and Dongguan, and has added 14 franchise standard stores in mid-tier cities in Southwest China and Central China. The new store opening nationwide enhances user awareness of AHS Recycle as it offers a wider array of recycling and circulation services for used goods.',
            },
            {
              subTitle: '',
              text: 'AHS Recycle now supports trade-in programs for Dyson’s hair care products. This is a step forward based on the established collaboration of supporting Dyson’s official recycling services, trade-in services and refurbished product sales. AHS Recycle also strives to take a step further to support cross-category trade-in, for users to trade in used smart phones and home appliances for new Dyson products, strengthening the in-depth cooperation between the two parties in the field of circular consumption.'
            },
            {
              subTitle: '',
              text: 'The 2023 Paipai Business Owner Conference in Chongqing was held in success, aiming to provide merchants with online expert community Q&A interaction and offline boot camps. In addition, the conference also offered traffic support and service fee discounts for a range of product categories while absorbing new merchant users. This conference aimed at providing a variety of operational capability empowerment to second-hand business owners.'
            },
            {
              subTitle: '',
              text: "PJT Marketplace launched a special laptop retail section, offering open auctions and bidding every afternoon. The products cover popular models from mainstream brands, making it easier for merchants to purchase laptops with better quality that are suitable for retail. There was also an upgrade related to POP prepayment. The basic quota of prepayment is based on the merchant's transaction volume and credit grading, and the temporary quota is unveiled for high-quality merchants. Merchants can also adjust the margin level based on their transaction activities to flexibly manage their capital needs."
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'ATRenew joins the United Nations Global Compact, becoming a pioneer of sustainability commitments',
              text: "ATRenew announced that it has joined the United Nations Global Compact (the “UNGC”) initiative and its commitment to the UNGC’s ten universally accepted principles in the areas of human rights, labor, environment, and anti-corruption. ATRenew has become the first corporate member of China’s pre-owned consumer electronics industry to join this initiative."
            },
            {
              subTitle: 'AHS Recycle urges educational accessibility and green recycling through public welfare initiatives',
              text:"AHS Recycle and Tencent’s 99 Giving Day philanthropy campaign awarded “little safflowers” to users who recycle idle goods with AHS Recycle. The awarded users can support the “Recycling for Love” digital education philanthropy project which donates pre-owned electronic devices to diversify educational resources for school children in rural areas. AHS Recycle also supported World Cleanup Day 2023 and called on Chinese residents to participate in garbage picking and sorting, and foster environmental protection responsibilities."
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: "Shanghai issues carbon inclusive management measures (trial)",
              text: "On September 25th, the Shanghai Municipal Ecological Environment Bureau formulated and issued to all districts the “Shanghai Carbon Inclusive Management Measures (<a rel='noopener noreferrer' target='_blank'  href='https://sthj.sh.gov.cn/hbzhywpt2022/20230927/78a437ce9f7b4f30bc50b4fc11fc5d0b.html'>Trial</a>)” to promote the formation of a green and low-carbon production lifestyle and ensure that Shanghai's carbon inclusive system is standardized and orderly run."
            },
          ]
        },
      ]
    },
    '202308': {
      articleTitle: 'RERE.US Corporate Blog – August 2023',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in August 2023. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'There were net increases in the number of AHS stores in August. The number of standard stores under direct operation grew by five, with the majority of them installed in new first-tier cities, further enriching the recycling service coverage in these cities. During the same month, there was also a net increase of 11 standard franchise stores, which are identical in layout and service standard to the directly operated stores. This amplifies the offline coverage in middle-tier cities in Anhui, Henan, and Sichuan provinces, etc.',
            },
            {
              subTitle: '',
              text: 'The service capabilities of PJT Marketplace have been upgraded, and the ability to manage bulk purchases and warehousing has been improved by leveraging DeviceHero, the portable inspection tool. DeviceHero has specially developed a warehousing function for merchant users of PJT Marketplace. It can synchronize the list of goods purchased by users in PJT Marketplace every day, conduct warehousing and inventory management, sales management, and business analysis, helping users to better operate their businesses while saving time and labor costs.',
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'ATRenew reports 2Q23 results, highlighting strong topline and operating profits',
              text: 'On August 23rd, ATRenew released its 2Q23 earnings report ended on June 30th. As at the end of June, there were 1,944 AHS Selection stores across 269 cities in China, meeting users’ need for consumer electronics recycling and trade-in services. There were also upgraded AHS specialty stores, fulfilling users’ demand for recycling high-value consumer goods including bags, watches, vintage liquor, and gold jewelry, etc. Topline of the quarter reached RMB2.96 billion, representing an increase of 38.1% year over year and beating the business guidance provided in the previous financial report. The non-GAAP operating profit was RMB52 million, which equals a non-GAAP operating margin of 1.8%. This was the fourth quarter in a row for the Company to report operating profits while setting a new record. These achievements were primarily attributable to an improved fulfillment expenses ratio compared with total net revenues under the non-GAAP measure.',
            },
          ]
        },
      ]
    },
    '202307': {
      articleTitle: 'RERE.US Corporate Blog – July 2023',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in July 2023. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'AHS Recycle had a net addition of 31 self-operated stores in July, mainly resulting from franchisee store conversion in Changsha and Shijiazhuang. Such conversion is expected to improve regional operational management efficiency and the recycling transaction experience for users.',
            },
            {
              subTitle: '',
              text: 'The retail selection brand Paipai Selection was renamed as “AHS Selection” out of the Company’s brand strategy. AHS Selection covers multiple product categories, including premium pre-owned mobile phones, tablets, laptops, smart electronics, luxury goods, watches, etc. The brand change aims at integrating to-consumer service capabilities and enhancing user awareness of the core AHS Recycle brand. The original Paipai brand mainly focuses on B2C platform services. Adjustments to front-line personnel structure and advancements to operational capabilities were done prior to this brand merger.',
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'ATRenew onboards management trainees of 2023',
              text: 'During the first week of July, ATRenew provided orientation and onboarding training to the newly recruited management trainees. These graduates mainly majored in information management and information systems, computer application engineering, and logistics management, etc. These trainees will rotate across multiple business departments, functions, and project teams within the following six months before the final review. The trainees will be appointed to the most suitable roles in December 2023, which marks the end of the trainee development roadmap and the start of their journey as future leaders.',
            },
            {
              subTitle: 'ATRenew named in Shanghai’s recommended “Quality Benchmark” (2022-2023)',
              text: 'The Shanghai Municipal Economic and Information Commission recently organized, reviewed and selected a series of quality benchmark applications according to the requirements of the Ministry of Industry and Information Technology. Shanghai then unveiled a list of recommendations for “Quality Benchmarks” (2022-2023). ATRenew’s Practical experience of “streamlined quality inspection and digitalized intelligent management” of pre-owned consumer electronic products was shortlisted.',
            },
            {
              subTitle: 'ATRenew as a finalist of Responsible Business Awards 2023 by Reuters Events',
              text: 'Recently, Reuters announced lists of finalists for its “Responsible Business Awards 2023”. With an industry-leading, innovative, and scalable circular business model, ATRenew is honored as the only Chinese finalist for the “Circular Transition Award” for its contribution to helping the use of consumer electronics move from a linear to a circular model. Finalists of this award include Schneider Electric, Oracle and PepsiCo, among others.',
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'The State Council approved taking multiple measures to promote the recovery and expansion of consumption, pinpointing electronics consumption and green consumption',
              text: `According to a <a rel='noopener noreferrer' target='_blank' href="https://english.www.gov.cn/policies/latestreleases/202307/31/content_WS64c727d5c6d0868f4e8de3e2.html">circular</a> released on July 31, China’s State Council approved a series of measures proposed by the National Development and Reform Commission to drive the recovery of consumption. The measures include stabilizing spending on big-ticket items, encouraging the upgrade of consumer electronic products, and promoting trade in used home appliances for new ones. The measures also urged new-type consumption, highlighting an active development outlook for green consumption and well-established low-carbon manufacturing standards.`,
            },
          ]
        },
      ]
    },
    '202306': {
      articleTitle: 'RERE.US Corporate Blog – June 2023',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in June 2023. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'The new store opening and multi-category recycling capability upgrade are both on track. The net increase of 5 self-operated AHS stores in June mainly happened in core commercial areas of high-tier cities. These new stores, which acquire used consumer electronics, luxury bags and watches, and gold and accessories from individuals, reflect AHS Recycle’s commitment to circulate more pre-owned products. There was a net increase of 9 standard franchise stores during the month. In addition, AHS Recycle adjusted some shop-in-shops and set up new touchpoints in low-tier cities in northern China.',
            },
            {
              subTitle: '',
              text: 'On June 16th, PJT Marketplace set its 48-hour sales guarantee live for certified POP (platform open plan) sellers who have passed the thresholds of transaction volume and platform credit rating. Pre-owned mobile phone products that meet the requirements for model, grade, and condition can enjoy such a guarantee. Sellers can enjoy advanced efficiency in capital turnover according to the confirmed guaranteed selling price.',
            },
            {
              subTitle: '',
              text: `On June 30th, AHS Recycle, the C2B recycling brand of ATRenew, officially <a rel="noopener noreferrer" target="_blank" href="https://pandaily.com/atrenew-becomes-a-partner-of-apples-trade-in-service/">appeared</a> on Apple's official website and flagship stores in mainland China, opening up the back-end supply chain to Apple’s Trade In and Upgrade programs. AHS Recycle provides attractive recycling prices for its partners’ trade-in programs to better meet consumer demand for premium new smartphones with ease. This shall also satisfy consumers’ pursuit of the betterment of life and green consumption.`,
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'ATRenew releases 2022 ESG report',
              text: 'On June 20th, ATRenew released its annual environmental, social and governance <a rel="noopener noreferrer" target="_blank" href="https://www.atrenew.com/resources/downloads/2022_ESG_Report_EN.pdf?_v=1.0.0_5624112">report</a> for the third consecutive year, presenting the overall performance to its stakeholders and highlighting improvements in respective areas. On the environment, greenhouse gas emission intensity associated with the purchase of electricity (scope 2) continued to decrease since ATRenew first disclosed it in the 2020 report. In 2022, ATRenew oversaw the responsible recycling and green disposal of waste electronic devices totaling 270,000 units, reducing 43.2 tons of e-waste. It also reused 18 tons of packaging fillers for B2B businesses and 36,000 cardboard boxes for B2C businesses. On social, ATRenew obtained ISO 9001 quality management system certification. The Company remained committed to recruiting and training talents, provided themed training sessions to over 8,000 merchant users of PJT Marketplace, and donated a cumulative amount of RMB1 million to charity programs during the reporting period. On governance, the Company further enhanced its risk and incident management capabilities. In addition, it rolled out internal audits and rectified 90% of the defects. As for top-down management, the board of directors was further diversified, leading to 37.5% independent directors and 25% female composition as of June, 2023.',
            },
            {
              subTitle: 'ATRenew included in “2022 Top 100 Chain Store Businesses in Local Service Industry” by China Chain Store and Franchise Association',
              text: 'The China Chain Store and Franchise Association (CCFA) and a number of local life service organizations first jointly launched and conducted a survey, themed “2022 Top 100 Chain Store Businesses in Local Service Industry”, covering nearly 20 sub-sectors of local services. CCFA unveiled the <a rel="noopener noreferrer" target="_blank" href="https://mp.weixin.qq.com/s/lmSYEmjr2DKdb6gSexxDhg">list</a> on June 15th during the CCFA New Consumer Forum. ATRenew was honored in the list as a representative from the recycling and maintenance industry.',
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'Counterpoint’s whitepaper notes an increasing market share of premium smartphones in China in 2022',
              text: `On June 30th, Counterpoint, a tech industry research firm,  <a rel="noopener noreferrer" target="_blank" href="https://www.counterpointresearch.com/white-paper-china-premium-smartphone-market/">released</a> the “White Paper on China Premium Smartphone Market”. Despite the pressure on the overall smartphone industry, sales volume of China's high-end smartphones is on a rising trend. In 2022, China's high-end mobile phone shipment accounted for more than 26% of the overall market, while the market share of ultra-premium phones (wholesale price ≥ USD700) increased significantly compared with 2021. The competition around the ultra-premium market is becoming increasingly intense, where Apple still occupies over 70% of this specific segment.`,
            },
          ]
        },
      ]
    },
    '202305': {
      articleTitle: 'RERE.US Corporate Blog – May 2023',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in May 2023. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'In May, AHS Recycle opened 5 new self-operated standard stores in Shanghai, Shenzhen, Dalian and more. There were also openings of joint-operated standard stores and shop-in-shops in mid- and lower-tier cities in northwest and north China to improve local market participants’ standardized recycling capabilities in addition to increased service accessibility.',
            },
            {
              subTitle: '',
              text: 'On May 29th, PJT Marketplace started to offer Huawei spare stock products, the majority of which were 7-day free return products graded by the OEM itself and a small number of Huawei certified refurbished products. From 15:00 to 18:00 every day, there will be an open auction for wholesale, providing mobile phones, tablets, laptops, watches, earphones, chargers and other gadgets, enriching the bidding options of the users of PJT Marketplace with exclusive sources of goods of extensive categories, good quality and compelling prices.',
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'ATRenew announced 23Q1 earnings',
              text: 'On May 23rd, ATRenew released its first quarter results for 2023. Total revenue increased by 30.2% year-on-year to RMB2.87 billion, beating the high-end of the revenue guidance again. Among them, the self-operated business recovered significantly, and the corresponding product revenue increased by 34.9% year-on-year, benefiting from the rapid recovery of offline scenarios and logistics, and the contribution of RMB145 million from the compliant refurbishment of self-sourced products. The marketplace take rate corresponding to service revenue has increased significantly to 5.46% compared with 4.15% in the same period last year, as the healthy and high-quality development of the platform business was ensured. The adjusted operating profit exceeded RMB44 million, and the adjusted operating margin was 1.5%, both new highs in a single quarter. They benefited from the improved cost efficiency of the upgraded automation technologies and the control of marketing expenses. Kerry Chen, CEO of ATRenew, mentioned during the earnings conference call that in the second half of 2023, there will be a project “providing unique trade-in solutions through in-depth collaboration with a leading international brand,” and this will potentially become a key growth driver to the Company.',
            },
            {
              subTitle: 'Used electronics recycling and trade-in services kicked off to a good start for 618 grand promotions',
              text: 'In the kick-off stage of ATRenew’s June 18th grand promotion between 0:00 on May 31st and 23:59 on June 7th, the overall transaction value of C2B recycling jumped started with an increase of 62% year-on-year, while the transaction value of one-stop trade-in for consumer electronics surged by 103% year-on-year. The number of fulfilled orders across regional markets reported new historical records for three consecutive days. With safe high-price recycling and hassle-free value-for-money trade-in solutions, ATRenew will further promote the new trend of low-carbon shopping for consumers.',
            },
          ]
        },
      ]
    },
    '202304': {
      articleTitle: 'RERE.US Corporate Blog – April 2023',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in April 2023. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'AHS Recycle newly set up 10 standard jointly operated stores in April, mainly in the core commercial districts of Changsha, Baoding and Harbin, among other cities. This was to further co-establish recycling and fulfillment capabilities in local markets. At the same time, there was a net increase of 17 shop-in-shops empowering digital business owners with recycling standards in a number of telecom and consumer electronics markets of Baoding, Shijiazhuang, Fuzhou and Hanzhong, etc.',
            },
            {
              subTitle: '',
              text: 'PJT Marketplace recently upgrades its authorized quality inspection program - merchant sellers who keep their return rate lower than the marketplace’s official level, possess professional quality inspection personnel and equipment, and conduct full-process video recording are selected. The program carries out regular training, testing and spot-checks. Those who fail to comply with these requirements will be deauthorized. By upgrading the program, PJT Marketplace aims to further protect the rights and interests of buyers.',
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'ATRenew submitted its annual report on Form 20-F',
              text: 'ATRenew filed its annual report on Form 20-F for the fiscal year ended on December 31, 2022, with the Securities and Exchange Commission (the “SEC”) on April 18, 2023. The annual report on Form 20-F can be accessed on the SEC\'s website at <a rel="noopener noreferrer" target="_blank" href="https://www.sec.gov">https://www.sec.gov</a>  and on the Company\'s investor relations website at <a rel="noopener noreferrer" target="_blank" href="https://ir.atrenew.com">https://ir.atrenew.com</a>.',
            },
            {
              subTitle: 'AHS Recycle recognized Earth Day through multiple green recycling initiatives',
              text: 'AHS Recycle launched the Cosmic Recycling Alliance, together with leading consumer brands in China. The alliance called for the protection of the Earth and advocated green lifestyles through activities such as recycling for gifts. At the same time, AHS Recycle cooperated with CapitaLand China to launch environmental protection campaigns in Chengdu, Xi\'an and Shenzhen while providing consumers with eco-friendly electronics value-added services, multi-category recycling price coupons, and self-service recycling of used shoes and clothes. ',
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'Shenzhen held a resource innovation industry forum and released a series of electronic product refurbishment group standards',
              text: `On April 26, the Shenzhen Electronics Industry Association held a high-quality development forum for resource innovation industries, launched a professional committee for the electronic product refurbishment industry, and promoted the healthy, sustainable and stable development of the industry. The association also published group standards in many aspects such as general requirements for refurbishment, information disclosure, quality management and technical specifications. ATRenew participated as a leading corporate delegate in the formulation of the series of group standards.`,
            },
          ]
        }
      ]
    },
    '202303': {
      articleTitle: 'RERE.US Corporate Blog – March 2023',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in March 2023. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'In March, a dozen jointly managed AHS Recycle shop-in-shops were newly opened in mid- and low-tier cities, including Baoding, Shijiazhuang, and Lishui to further popularize the recycling services. In late March, AHS Recycle successfully held its 2023 Store Partner Conference in Shenzhen, where the management team and franchisee representatives provided sharing sessions. AHS Recycle empowers its store partners with location picking, product display, and quality standardization capabilities as efforts to jointly improve recycling services for users.',
            },
            {
              subTitle: '',
              text: 'PJT Marketplace upgrades prepayment rules for POP merchant users, by adjusting the transaction deposit according to the credit rating of each merchant user, including both buyers and sellers. PJT then adjusts the previously fixed platform prepayment multiples to floating multiples according to the level of its merchant users. The greater the transaction volume and credit rating, the greater the floating multiple, the higher prepayment multiple that the merchant can obtain, and the higher capital turnover efficiency of transactions. The new rules, which came into effect on April 6, were expected to strengthen user stickiness of active merchants on the platform, screen merchants with low transaction efficiency or non-compliant transactions, and further optimize the user structure of PJT Marketplace. In addition, PJT upgrades the sales guarantee service for sellers. If a seller accepts PJT’s suggested product prices under this program, the transaction payment will be credited to the seller immediately, and PJT will undertake after-sales responsibilities. This is believed to further improve sales and capital turnover efficiency for sellers.',
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'ATRenew announces multiple milestones when reporting its 4Q&FY22 financials',
              text: 'On March 13, ATRenew released its financial report for the fourth quarter and full year of 2022, announcing multiple revenue and profit milestones, including a 26.9% year-on-year increase in annual revenue to RMB9.87 billion, and the number of pre-owned products recycled and transacted throughout the year reached 32 million. For the first time, ATRenew realized profitability on a full-year basis, reporting an annual non-GAAP operating profit of RMB6.9 million and a non-GAAP net profit of over RMB50.8 million. In addition, ATRenew achieved a net cash inflow of RMB200 million in 2022. As of December 31, 2022, there were 1,920 offline stores in 266 cities across China. The quality inspection operation capability covers 8 regional operation centers. The South China (Dongguan) automation operation center was put into use in the fourth quarter, further advancing ATRenew’s overall automation quality inspection efficiency. As a result, the non-GAAP fulfillment expenses as a percentage of total net revenues dropped by 2.8 percentage points to 8.7% in the fourth quarter, verifying an improved cost efficiency.<br/>ATRenew also announced that it deepened its strategic cooperations with leading international OEMs for trade-in, which is expected to extend the service capabilities of ATRenew’s 1P recycling supply chain for retail scenarios and increase the recycling penetration rate in first- and second-tier cities in China.',
            },
            {
              subTitle: 'CCTV reports on the circular economy and the re-commercialization of used electronics worth advocating',
              text: 'Recently, CCTV-2, the finance channel of China Central Television, reported on second-hand electronic product transactions under the circular economy, citing the business model of AHS Recycle established by ATRenew. According to the news report, giving a second life to idle electronics is worth advocating. This emerging industry with abundant opportunities shall be developed further soon. To view the news clip, please visit the WeChat video account of ATRenew, named “万物新生新鲜发布”.',
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'Government work report: promoting the green transformation of development mode, while conserving energy and reducing carbon emission in key areas',
              text: `<a rel="noopener noreferrer" target="_blank" href="https://m.yicai.com/news/101692627.html">According</a> to “China Business News”, Keqiang Li, former premier of China’s State Council, made a government work report at the first session of the 14th National People's Congress on March 5, pointing out that this year, China would promote the green transformation of the development model, improve policies to support green development, develop the circular economy, promote resource conservation and efficient utilization.`,
            },
          ]
        }
      ]
    },
    '202302': {
      articleTitle: 'RERE.US Corporate Blog – February 2023',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in February 2023. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'During the month, AHS Recycle continued its shop-in-shop store expansion in lower-tier cities mainly in North China while optimizing the storefronts of a small number of standard stores. This was to improve regional customer access. At the same time, a selective number of AHS stores piloted rare and exclusive sneaker recycling services by leveraging the face-to-face services of AHS stores and partner’s inspection capabilities that serve the need for cashback of sneakerheads.',
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'ATRenew hosts new-year townhall and awards star employees of 2022',
              text: 'On February 21, ATRenew continued its tradition of annual townhall following the Chinese New Year during which the General Manager Office awarded the outperforming employees of 2022. Yongliang Wang, president of the company delivered an annual speech on behalf of the General Manager Office, reviewing the decision-making process in 2022 which prioritized making subtractions and focusing on the core recycling business against the headwinds caused by the covid-19 epidemic. Looking into 2023, Yongliang reiterated the core strategies of the Company, including continuously advancing the recycling penetration rate of the core consumer electronics category through “city-level service integration” strategy, strengthening compliant refurbishing capabilities to improve the value of the industrial chain, maximizing the value of the leading domestic recycling brand “AHS Recycle”, and continuing to invest and amplify automation efficiency.',
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'Shanghai issues key points of work on climate change in 2023',
              text: 'The “<a rel="noopener noreferrer" target="_blank" href="https://news.bjx.com.cn/html/20230221/1290031.shtml">Key Points</a>” pointed out that the city\'s carbon inclusive system should be gradually improved. This includes 1) establishing a carbon-inclusive management and operation center, and building an inclusive carbon system; 2) researching and releasing supporting policies; 3) developing pilot scenarios in industries such as energy and transportation; 4) incorporating carbon-inclusive certified emission reductions into the city\'s carbon market supplementary offset mechanism, and initially realize closed-loop operation with the city\'s carbon market. Furthermore, the system should strengthen regional cooperation and actively promote the joint construction of carbon inclusiveness in the Yangtze River Delta region.',
            },
          ]
        }
      ]
    },
    '202301': {
      articleTitle: 'RERE.US Corporate Blog – January 2023',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in January 2023. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'AHS Recycle unfolded its annual store opening program and kicked off at a disciplined pace ahead of the Lunar Chinese New Year. In January, the net addition of AHS stores reached 12, mainly located in North China and Northwest China.',
            },
            {
              subTitle: '',
              text: 'ATRenew provided uninterrupted services in mainland China during the Chinese New Year holiday from January 21 to January 27, 2023. AHS Recycle and Paipai’s offline stores modified their business hours to reflect shopping malls’ adjusted opening hours, ensuring that users were seamlessly provided with the full range of recycling channels: from in-store to door-to-door, to delivery. Furthermore, users who submitted orders during this period received a 10-day price match pledge. AHS Recycle launched its uninterrupted Chinese New Year service across AHS offline stores in 2021 and expanded this to new categories such as luxury goods, gold, vintage liquor and diamonds in 2023. It was even effortless for users to receive cashback while continuing the tradition of “out with the old and in with the new”. PJT Marketplace’s core auction events continued, and Paipai’s online business offered uninterrupted delivery services throughout the holiday for the fifth successive year. The marketplaces’ pre- and after-sales support was available online, and operation centers and city-level operation stations collected and shipped goods as usual. As a result, small- and medium-sized businesses could generate a steady stream of income, and users of Paipai could get ideal value-for-money products when celebrating the new year.'
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'Kerry presents annual review and strategy outlook at ATRenew’s quarterly all-hands meeting',
              text: 'On January 17, ATRenew held an all-hands meeting to provide a recap on recent developments of each business function and offer rewards to employees of the quarter. During this meeting, Kerry Chen, founder and CEO of ATRenew, shared the corporate strategy outlook for 2023 following a review of the challenges and progress made in 2022. There are three strategic goals to strive for in 2023. 1) Build AHS Recycle into the most popular brand in the pre-owned product recycling industry in China and endow the brand with love and warmth. Explore new revenue and profit growth potential by expanding the Company’s offerings into high-value products besides consumer electronics. 2) Continue to invest in automation technology (RERE InnoTech) to drive the development of ATRenew with high efficiency and quality following the automation upgrade in Dongguan operation center, the regional operation facility located in South China, by the end of 2022. 3) Embrace the established urban recyclable sorting and re-marketing business incubated by ATRenew, namely Aifenlei (“爱回收·爱分类”). As Aifenlei’s business model has been verified, it’s rejoining the ecosystem and organizational structure of ATRenew. It is expected that Aifenlei will improve its unit economies at the city level and will prove its value in the aspects of resource conservation and building an inclusive low-carbon society under its innovative “Internet + Recycling” business model.',
            },
            {
              subTitle: 'ATRenew entitled “Annual Brand Influence Enterprise” by CIIC',
              text: 'China Internet Information Center (CIIC) initiated the selection of “2022 CIIC Technology Enterprise Pioneer List”, and selected 40 influential and innovative forerunners through the evaluation of a number of indicators, including value creation, social performance, environmental performance, governance performance and information disclosure. Among them, ATRenew was recognized as an “Influential Brand of the Year”.',
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'The State Council Information Office issues white paper on green development while encouraging the secondhand economy',
              text: 'The State Council Information Office of China released a white paper titled “China\'s Green Development in the New Era (<a rel="noopener noreferrer" target="_blank" href="http://english.www.gov.cn/archive/whitepaper/202301/19/content_WS63c8c053c6d0a757729e5db7.html">full text</a>)” on January 19, 2023, introducing the concept of green and low-carbon development while highlighting the country’s practices and remarkable progress. According to the white paper, eco-friendly living becomes the prevailing ethos and China has a growing market of green products. The white paper also notes that “China has supported new business models such as the sharing economy and second-hand transactions. There is a richer variety of green products and a growing number of people who spend on green products.”',
            },
          ]
        }
      ]
    },
    '202212': {
      articleTitle: 'RERE.US Corporate Blog – December 2022',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in December 2022. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'In December, AHS Recycle opened a net of 12 joint-operated standard stores in North China and Northwest China and a net of 18 joint-operated store-in-stores in Southwest and North China. As of the end of December 2022, the total number of joint-operated AHS stores in China exceeded 1,300, reaching a doubled recycling network density in low- and middle-tier cities. This has also increased the popularity of AHS Recycle brand among its users, the recycling awareness among consumers, the recycling penetration rate as well as accessibility of quality products.',
            },
            {
              subTitle: '',
              text: 'In December, AHS Recycle launched “recycle idle shoes and clothes for gifts” initiatives across 13 AHS stores in Beijing and Shanghai. Green recycling credits were awarded to users either on a piece-by-piece basis or by weight based on the condition and brands of their idle clothes and shoes, making it more convenient and practical to participate in environmental protection recycling. Users can exchange the awarded credits for souvenirs such as thermos cups, carry cups, and wireless chargers.'
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'Focusing on the multi-category strategy, ATRenew further upgraded its organizational structure',
              text: 'On December 1, to build AHS Recycle into the most premium recycling brand in China while prioritizing new recycling categories in the coming seasons, ATRenew announced internally that the Company further upgraded its organizational structure. For backbone businesses around consumer electronics, including smartphones, tablets, and laptops, the Company’s existing platform capabilities will continue to function as a core growth driver. As for the emerging categories, such as pre-owned luxury goods, camera equipment, and gold, ATRenew assigns its storefront function to ensure consumer service excellence and aligns the targets across recycling, processing, and distributing functions with dedicated multi-category business strategy, so as to achieve rapid development.',
            },
            {
              subTitle: 'ATRenew won the “Best ESG Award” issued by Zhitong Caijing (智通财经)',
              text: 'On December 19, the “7th Zhitong Caijing Public Company Awards” was held in Hangzhou where ATRenew won <a rel="noopener noreferrer" target="_blank" href="https://www.zhitongcaijing.com/content/detail/849477.html">the “Best ESG Award”</a>. The “Best ESG Award” recognized outperforming companies in 2022, especially against the COVID-19 resurgence, that continued adhering to customer centricity and fulfilling employer and social responsibilities. The award praised Hong Kong and US-listed companies that have communicated with global stakeholders while creating long-term value for society, the environment, shareholders, customers, employees and partners. More than 1,000 Hong Kong and US-listed companies from multiple industries participated in the selection this year.',
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'JD.com releases key consumption trends in 2022 - trade-in option became increasingly popular on JD.com',
              text: 'According to <a rel="noopener noreferrer" target="_blank" href="https://mp.weixin.qq.com/s/Bzp-kgZFJHzIyQQunyGD-w">JD Research Institute for Consumption and Industrial Development</a>, more prudent and well-planned shopping decisions became one of the key trends among Chinese consumers in 2022 while value-for-money products and services were trending. In terms of electrics and home appliance consumption, JD’s data shows that in the first three quarters of 2022, JD.com’s orders for new mobile phones through trade-in increased by 240% year-on-year; orders for computer digital products through trade-in surged by 373% year-on-year; and orders for home appliances trade-in jumped by over 359% year-on-year, enabling over one million households to replace old home appliances with new ones.',
            },
          ]
        }
      ]
    },
    '202211': {
      articleTitle: 'RERE.US Corporate Blog – November 2022',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in November 2022. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'AHS Recycle added a net number of 9 jointly operated standard stores and 23 cooperative stores. These additions increase the density of the recycling and fulfillment network while providing closer access to customers for pre-owned device recycling and transactions across local markets.',
            },
            {
              subTitle: '',
              text: 'During the first 28 hours since the Singles\' Day grand promotion kicked off at 8 pm on October 31st, the number of JD customers who traded in their used devices when shopping for new increased by over 310% year-on-year. ATRenew has been the exclusive provider of electronics trade-in services on JD.com. Of note, this was the first grand promotion where ATRenew further synergized with JD.com by collaborating with its home appliances business unit while promoting the circular economy. Consumers could trade in up to six used electronic devices when shopping for new smartphones, laptops and white goods. The cross-category trade-in offering has not only realized a discounted total order value for consumers but also an improved shopping experience. Home appliance cross-category trade-in orders accounted for 20% of all the paid trade-in orders during the grand promotion.'
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'ATRenew released its financial report for the third quarter of 2022 with recurring profit while reiterating its four core strategic focuses',
              text: 'On November 22, ATRenew Inc. reported its latest quarterly financial results report. Total net revenues increased by 29.2% year-on-year to RMB2.54 billion, overcoming the downward pressure from the macroeconomy and national consumption. The Company also reported an operating profit of RMB10.8 million under the non-GAAP measures, rapidly recovering its profit trajectory from the disruptive Covid restrictions in the second quarter. ATRenew also reported an inflow of RMB101 million from operating activities and a consolidated cash reserve of RMB2.67 billion, safeguarding its sustainable development. Management reiterated that ATRenew kept its core strategic focuses clear and consistent. ATRenew will continue to 1) promote its city-level service integration model, 2) expand the service coverage and fulfillment capacity of the multi-category recycling offering, 3) convert more of its 1P product supplies to retail distribution by leveraging its value-added capabilities including refurbishment, and 4) further increase the operating efficiency by continuing to deploy automation technologies.',
            },
            {
              subTitle: 'Kerry Chen, founder and CEO of ATRenew, was nominated for “2020-2021 Green China Person of the Year”',
              text: 'In November, the Annual Meeting of the China Ecological Civilization Forum took place. During the meeting, the Ministry of Ecology and Environment nominated and praised 40 advanced collectives, 60 outstanding individuals and 10 award-winning individuals who were recognized as “2020-2021 Green China Person of the Year”. Kerry Xuefeng Chen, the founder and CEO of ATRenew Inc., was <a rel="noopener noreferrer" target="_blank" href="https://caijing.chinadaily.com.cn/a/202211/28/WS638466b9a3102ada8b22436a.html">nominated</a> for “2020-2021 Green China Person of the Year”, a recognition of his contribution to building the industry-leading consumer electronics recycling and distribution platform as well as the green city industry chain in China. ATRenew is committed to acting up to corporate social responsibilities in its business operations.',
            },
            {
              subTitle: 'ATRenew was named among 36Kr’s WISE 2022 King of the New Economy Annual Enterprises',
              text: '36Kr launched the “WISE 2022 King of the New Economy Annual Enterprises” series of surveys while focusing on industries of cutting-edge technologies, consumption and lifestyle, and vehicle and transportation, among others. A professional judging panel reviewed thousands of corporate submissions before announcing the finalists. As an industry-leading representative of the circular economy business and green lifestyle, ATRenew was <a rel="noopener noreferrer" target="_blank" href="https://36kr.com/p/2022133442341897">selected</a> as one of the most influential companies in the consumption and lifestyle vertical.',
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'More Japanese shoppers seek secondhand iPhones due to multiple factors including the tumbling yen',
              text: 'According to a Reuters <a rel="noopener noreferrer" target="_blank" href="https://www.reuters.com/technology/yen-tumbles-gadget-loving-japan-goes-secondhand-iphones-2022-11-08/">report</a> (<a rel="noopener noreferrer" target="_blank" href="https://www.sohu.com/a/604022208_120546417">reprinted</a> by Zhengguan News in Chinese), as the Japanese currency fell to a 32-year low against the dollar, some Japanese consumers chose to buy secondhand iPhones instead of the latest or new gadgets. This has led to an increase in pre-owned iPhone transactions. Japanese consumers have become more serious about buying secondhand goods, thanks in part to the rise of a number of online auction sites.',
            },
          ]
        }
      ]
    },
    '202210': {
      articleTitle: 'RERE.US Corporate Blog – October 2022',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in October 2022. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'In October, AHS Recycle has a net addition of 8 self-operated stores and 14 joint-operated standard stores, focusing on second-tier cities in the southern regions for continuous expansion of the recycling and fulfillment network. At the same time, 40 AHS cooperative stores have been set up in middle- and lower-tier cities, mainly in central and northern China, to improve the consumer influence and business empowerment of the re-commercialization of consumer electronics.',
            },
            {
              subTitle: '',
              text: 'Together with ATRenew, JD strengthens its environmental protection contributions through consumer electronic and home appliance product trade-in initiatives. JD announces that it will donate to China Green Foundation for wolfberry tree planting in Ningxia Province in the spring of 2023 through the green trade-in initiative. The total value of the donation equals the number of consumer electronics and home appliance orders placed through this initiative since August 15, 2022. This is designed as a green project to encourage trade-in and to build an ecological belt through forest expansion. '
            },
            {
              subTitle: '',
              text: 'PJT Marketplace continues to optimize its tiered user management mechanism based on the life cycle and the gross transaction value of users. Under this mechanism, PJT Marketplace improves the transaction experience by offerings a simplified transaction process when onboarding new buyers as a way to retain new users. PJT Marketplace also initiated a program to serve sellers better as they can better enjoy customer rights and more specific commission fees.'
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'ATRenew’s second automated operation center was officially put into use in Dongguan, Guangdong Province',
              text: 'The newly launched operation center has realized important improvements in three aspects, further advancing the automation capabilities compared with the automated operation center in Changzhou. On circulation, through digitally managed shunting systems, the advanced streamlined unmanned system which transports goods between each model observes a 15% improvement in efficiency. On quality inspection capability, Matrix 3.0 elevates inspection accuracy and efficiency by 10% and 50%, respectively, thus minimizing losses from the return of goods caused by inspection errors. On warehousing, the capacity of the intelligent vertical storage system doubles that of the earlier generation. The Dongguan automated operation center has an 8-hour capacity of 10,000+ devices.',
            },
            {
              subTitle: 'ATRenew wins the "For Good Awards" of China Social Enterprise and Impact Investment Forum',
              text: 'China Social Enterprise and Impact Investment Forum and Caixin Media jointly launched 2022 For Good Awards, screening companies that are both strategically and economically responsible to the society. 242 companies were included in the “2022 For Good” list where ATRenew received the “For Good Awards” as a recognition of its commitment to corporate social responsibilities and that the company has been on a mission to give a second life to all idle goods.',
            }
          ]
        },
      ]
    },
    '202209': {
      articleTitle: 'RERE.US Corporate Blog – September 2022',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in September 2022. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'In September, ATRenew opened 8 self-operated AHS stores of standard store type and 51 franchised stores mainly in the format of cooperative store (store-in-store) to strengthen service penetration in the central and eastern China markets. In addition, AHS Recycle unveiled its multi-category recycling flagship store at Baoshan Wanda Plaza in Shanghai. This flagship store offers recycling services for luxury bags, watches, jewelry, gold, liquor, wine, shoes and clothing, etc. as extensions to consumer electronics. The all-in-one store also provides consumers with trade-in solutions and value-added services, including smartphone repair, screen protector installation, and accessory purchases. The scheme design is to provide convenient and rich green consumption services through thousands of AHS stores across China, thus obtaining greater consumer mindshare. It is expected that 10 flagship stores will be established in Shanghai and consumers in Beijing will be able to enjoy such enriched offline services in the fourth quarter of 2022.',
            },
            {
              subTitle: '',
              text: 'The Dongguan operation center was put into trial operation at the end of September, including the installation and implementation of the Matrix 2.0 automated quality inspection system, realizing ATRenew’s second automated operation center in China. The 8-hour operating capacity of the Dongguan operation center can exceed 10,000 electronic devices.'
            },
            {
              subTitle: '',
              text: 'The “one-stop trade-in” option was embedded in JD.com’s product display, shopping cart and check out interfaces for consumers to smoothly order the newly launched iPhone 14 lineup products while paying the price difference by trading in their used devices. The “one-stop trade-in” service also offers a more economic solution for consumers to trade in multiple used products at the same time, including consumer electronics and home appliance. From September 16th to September 18th, GMV for AHS Recycle’s 1P recycling business increased by 50%, among which, GMV for recycling orders fulfilled at AHS stores increased by 60%. In addition, GMV for value-added services doubled.'
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'ATRenew receives a low ESG risk rating from Morningstar Sustainalytics',
              text: 'ATRenew has received an Environmental, Social and Governance (“ESG”) risk rating score of 17.6/100 from Morningstar Sustainalytics (the lower, the better), assessing ATRenew to be at “<a rel="noopener noreferrer" target="_blank" href="https://www.sustainalytics.com/esg-rating/atrenew-inc/2008898065">Low Risk</a>” of experiencing industry-specific material ESG factors. The Company was ranked fourth in the Online and Direct Marketing Retail sector. In particular, ATRenew attained “Negligible” ratings for its data privacy and security, business ethics, and environmental and social impact of products and services, all of which are key components of the assessment of material industry-specific ESG risks. Sustainalytics’ evaluation identifies ATRenew’s strong management performance across a comprehensive range of ESG metrics, demonstrating the Company’s industry-leading position in ESG-related policies, programs, and initiatives. Morningstar Sustainalytics is a leading ESG research, ratings and data firm that supports investors around the world with the development and implementation of responsible investment strategies.',
            },
            {
              subTitle: 'Mr. Lijun Xin and Mr. Yanzhong Yao appointed as new directors of the Board',
              text: 'Mr. Lijun Xin, chief executive officer of JD Retail, and Mr. Yanzhong Yao, senior vice president of JD.com and president of JD Electronics and Home Appliance, have been appointed as new directors to the Company’s board of directors (the “Board”), effective on September 30, 2022, to replace Mr. Lei Xu and Mr. Wei Tang. Mr. Lijun Xin will also serve as a member of the compensation committee of the Board and a member of the nominating and corporate governance committee of <a rel="noopener noreferrer" target="_blank" href="https://ir.atrenew.com/corporate-governance">the Board</a>. Upon the appointment of Mr. Lijun Xin and Mr. Yanzhong Yao, the Board consists of eight members: Mr. Kerry Xuefeng Chen, Mr. Yongliang Wang, Mr. Lijun Xin, Mr. Yanzhong Yao, Mr. Chen Chen, Mr. Jingbo Wang, Mr. Guoxing Jiang and Ms. Rui Zhu. ',
            }
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'Naver agrees to acquire fashion marketplace Poshmark for $1.2B',
              text: 'After market close on Oct 3, 2022, Naver Corp. (KRX: 035420) (“Naver”), Korea’s largest search engine and largest e-commerce platform, <a rel="noopener noreferrer" target="_blank" href="https://www.businesswire.com/news/home/20221003006050/en/Naver-to-Acquire-Poshmark">announced</a> that it has entered into a definitive agreement with Poshmark, Inc. (NASDAQ: POSH) (“Poshmark”), a leading social e-commerce marketplace for new and secondhand style, that Naver would acquire all of the issued and outstanding shares of Poshmark at $17.90 per share in cash, representing an enterprise value of approximately $1.2 billion. This represents a premium of 15% to Poshmark’s closing stock price as of October 3, 2022. The transaction, which was unanimously approved by both Naver’s and Poshmark’s Boards of Directors, is expected to close by the first quarter of 2023.',
            },
          ]
        }
      ]
    },
    '202208':{
      articleTitle: 'RERE.US Corporate Blog – August 2022',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in August 2022. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'In August, the number of jointly-operated AHS stores increased by 77. ATRenew continues prioritizing the cooperative store (shop-in-shop) model to strengthen penetration in the lower-tier cities in central China, thus providing a wider range of Chinese consumers with electronics product recycling services. These stores also strengthen consumer awareness of the brand name of AHS Recycle and green consumption through recycling and reuse.',
            },
            {
              subTitle: '',
              text: 'ATRenew partnered with a leading pre-owned product transactions operator in Japan. By placing the upgraded proprietary smartphone recycling kiosks in its partner’s stores in Tokyo, ATRenew made a step forward in empowering its partners overseas with electronics recycling and inspection capabilities. ATRenew aims to establish such collaboration in more economies and accelerate the circulation of pre-owned electronic gadgets through technology empowerment.'
            },
            {
              subTitle: '',
              text: 'PJT Marketplace slightly reduced the logistics fee subsidy for buyers and POP sellers since September 1 as it optimizes customer services. The flexibility in fee adjustment reflects the trust and stickiness of the merchant users and an improved monetization capability.'
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'ATRenew successfully held its first virtual investor day',
              text: 'On August 2, ATRenew held its 2022 Investor Day on Zoom webinar, vividly demonstrating its business capabilities of AHS Recycle (C2B), PJT Marketplace (B2B), and Paipai Marketplace (B2C). The webinar included virtual tours of a physical AHS store and the automated operation in Changzhou, an interview with a PJT Marketplace merchant user representative, and a live Q&A session regarding business strategy with senior management. This virtual investor day offered more comprehensive perspectives of the Company. Feel free to send an email to <a rel="noopener noreferrer" href="mailto:ir@atrenew.com">ir@atrenew.com</a> for webinar replay.',
            },
            {
              subTitle: 'ATRenew’s circular economy business demonstrated risk resilience in 2Q22 results',
              text: 'On August 24, ATRenew released its earnings report for the second quarter of 2022, demonstrating a resilient growth momentum as its topline continues beating guidance. In the context of the Covid resurgence, ATRenew reported an increase of 14.9% year-on-year in total net revenues, reaching RMB2.15 billion in the quarter. Total GMV increased by 10.3% to RMB8.6 billion yuan. The Company also reported a cash inflow from operating activities of RMB280 million and a comprehensive cash position of RMB2.6 billion, further solidifying the foundation for a sustainable and healthy development.',
            },
            {
              subTitle: 'Won the "High Energy Team Award" by Harvard Business Review',
              text: 'The Chinese edition of Harvard Business Review and FESCO jointly initiated a program to award new organizational management model and generate greater social value by expanding the influence of outstanding cases. As a result of its innovation and strategy execution abilities, ATRenew was acknowledged by both business and academia for its achievements in talent training, teamwork and organizational effectiveness, thus winning the award as a pre-owned consumer electronics industry representative.'
            }
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'Economic Daily and JD.com publishes data on green industry chain with dots connected by circular economy',
              text: 'Although most consumers have not yet "completely understood" the connotations and categories of environmental protection, they have already begun to practice green and environmental-friendly consumptions, mainly through trade-in services provided by multiple platforms, according to a survey by the Economic Daily and JD.com (<a rel="noopener noreferrer" target="_blank" href="https://www.sohu.com/a/582303222_120702">link</a> to the full data set in Chinese). Trade-in is not only a means to promote consumption upgrade, but also leads the formation of green consumption, in a way. Furthermore, the concept of "circular supply chain" derives from it.',
            },
          ]
        }
      ]
    },
    '202207': {
      articleTitle: 'RERE.US Corporate Blog – July 2022',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in July 2022. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'In July, ATRenew continued the pace of its store network expansion by adding 49 stores. The additions were mainly in the form of cooperative stores (shop-in-shop) in the first- and second-tier cities in central and southern China, such as Shenzhen and Wuxi, further strengthening the coverage of AHS Recycle in the local electronics hubs and the ability to serve consumers.',
            },
            {
              subTitle: '',
              text: 'PJT Marketplace adjusts labels and entrances to improve user experience. The quality inspection indicators are grouped into "official quality inspection", "certified quality inspection (by PJT Marketplace-certified inspectors)", and "third-party quality inspection (by merchants who adopt PJT Marketplace’s quality inspection standards and procedures)". There is also a star rating for products labeled “certified quality inspection” and “third-party quality inspection” - a five-star rating represents the highest credibility of a seller. The upgrade of the labeling and rating system further enhances the transparency of the marketplace. The auctioning section has been divided into two: 1) mobile phones and tablets, and 2) laptops and other electronics - forming category focuses and providing merchants users with more convenience.'
            },
            {
              subTitle: '',
              text: 'ATRenew has successfully accelerated the trial operation process of multi-category recycling, reusing the system and capabilities of AHS stores in central business districts in cities such as Beijing and Shanghai selectively. The new categories include pre-owned luxury bags, watches, photography and video equipment as well as gold. Consumers can get price estimates and place orders through the mobile app, official website and WeChat mini program of AHS Recycle. Take luxury goods as an example, the recycling process and user experience are significantly improved compared with the traditional approaches in the industry. The development of a multi-category business is based on strong brand awareness and consumers\' trust. It is also inseparable from the long-term construction of store fulfillment capabilities, and the quality inspection competence backboned by the Company’s systematic staff training system.'
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'ATRenew received acknowledgments as a leading CSR practitioner',
              text: 'ifeng.com 2022 Corporate Social Responsibility and Innovation Forum unveiled a series of innovative CSR initiatives where ATRenew\'s “AHS Charity and Love Digital Education Aid Program for Children in Rural Regions” stood out. In the meantime, due to its prominent performance in helping poverty alleviation, ATRenew won the special contribution award for poverty alleviation issued by the China Rural Development Foundation (formerly the China Foundation for Poverty Alleviation) - ATRenew proactively supported the recovery of the flooded schools in Henan in 2021 by donating smart blackboards, electronic teaching equipment and office supply.',
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'ATRenew exemplifies low-carbon development in Aurora Mobile’s insight report',
              text: 'MoonFox, a data provider by Aurora Mobile, a leading customer engagement and MarTech service provider in China launched the "2022 Low-Carbon Society Insight Report" (<a rel="noopener noreferrer" target="_blank" href="https://www.moonfox.cn/insight/detail?id=1168&type=report">full report in Chinese</a>) in July. It focuses on the evolvement of the Internet industry and several leading players in China as well as analyzing their contribution to the country’s carbon neutrality goal. With its leading business model, outstanding carbon reduction measures and contributions, ATRenew becomes a case model of an enterprise that recycles idle electronic products.',
            },
          ]
        }
      ]
    },
    '202206': {
      articleTitle: 'RERE.US Corporate Blog – June 2022',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in May 2022. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'There was a net increase of 47 AHS stores in June, all were jointly operated stores, in either standard format or store-in-store format. These new consumer recycling touchpoints were mainly located in Hefei, Wuhan and Hangzhou in eastern China and Suzhou, Xi’an and Lianyungang in China’s central region. In terms of city-level operation station, some self-operated ones were turned into franchises, the front-end business group managed both. Besides, the middle-office team also selectively cultivates roughly a dozen self-operated stations. Overall, the Company focuses on penetrating lower-tier cities in southern, central and northern China. The combination of store and city station networks fortifies the core toC and toB capabilities across local markets, which drives the formation of ATRenew’s city clusters.',
            },
            {
              subTitle: '',
              text: 'PJT Marketplace opened up its retail section to POP merchant users of Paipai Marketplace in June. When the POP merchants place orders, the listings will be transferred to a cloud platform of Paipai before shipping, providing a convenient procurement experience and operational support for retailers. At present, the service has run through testing and will benefit both merchant users and consumers.'
            },
            {
              subTitle: '',
              text: 'Paipai Marketplace realized a second peak during the “618 Grand Promotion”. Between 8 pm, June 17 to 12am, June 18, the recycling and trade-in orders placed through JD.com surged by 340% and 246% year-on-year, respectively. Total GMV for Paipai Marketplace went up by 152%, while the growth rates of GMV for smartphones, laptops and tablets, and luxury goods recorded 139%, 155% and 440% year-on-year, respectively. ATRenew is committed to promoting economic electronics shopping options while promoting a green lifestyle and acquiring greater consumer mindshare.'
            },
            {
              subTitle: '',
              text: 'On June 21, ATRenew announced the launch of its data wiping corporate solution leveraging the proprietarily developed “iCleanMaster” tool (“爱清除”), which supports the deep removal of single and batch data by upgrading the privacy data removal algorithm for enterprise data security needs. After 4 years of independent research and development iterations, “iCleanMaster” is now ADISA certified. It supports mobile phones, tablets and laptops, desktop computers, and is compatible with IOS and Android systems, helping businesses and enterprises to ensure data privacy and security.'
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'The 3<sup>rd</sup> anniversary of the merger between AHS Recycle and Paipai Marketplace',
              text: 'ATRenew celebrated the third anniversary of this merger in early June. The management team reflected on challenges and achievements over the years and shared a visionary outlook for more synergies from the integrated teams, platforms and brands. Teams in Beijing, Shanghai and Shenzhen joined remotely.',
            },
            {
              subTitle: 'Published 2021 ESG report while adopting TCFD framework and disclosing greenhouse gas emission reduction through reusing pre-owned phones',
              text: 'On June 6, ATRenew published its second annual environmental, social, and governance ("ESG") report (the "Report") incorporating the Task Force on Climate-related Financial Disclosures (TCFD) recommendations, a framework set by the G20\'s Financial Stability Board, for the first time. In the Report, the Company explores the climate-change-related opportunities and challenges it faces in its operation and reuse of pre-owned electronic devices through the perspectives of governance, strategy, risk management, and metrics and targets. In addition, the Company leverages the Circular Footprint Formula for the first time to disclose its contribution of 464,000 metric tons of green-house gas emission reductions through the reuse of pre-owned mobile phones in 2021.',
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'Beijing’s draft regulations on electrical and electronic products recycling open for comments, which included user information protection',
              text: 'Beijing Municipal Administration for Market Regulation recently issued the "Regulations on the Recycling of Electrical and Electronic Products (Draft for Comment) (translated)" (<a rel="noopener noreferrer" target="_blank" href="http://scjgj.beijing.gov.cn/hdjl/myzj/bzzxdyjzj/202205/t20220519_2716308.html">appendix #6 of the notice</a>), which detailed the collection, storage, transportation and disposal processes when recycling used electrical and electronic products. These include a requirement to thoroughly erase users\' personal information in person when recycling electronic products. The promulgation of this document is of great significance to further improve the recycling rate of pre-owned electronics and better protect the rights and interests of consumers.',
            },
          ]
        }
      ]
    },
    '202205': {
      articleTitle: 'RERE.US Corporate Blog – May 2022',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in May 2022. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'In May, the AHS stores in Shanghai were still temporarily closed and the employees worked from home as the COVID control measures continued in Shanghai and Beijing. However, in June, store operations resumed quickly and employees returned to the office in Shanghai. In particular, GMV for C2B business revitalized swiftly, surpassing that of the first two weeks in June 2021 -  this was mainly credited to the vibrant self-operated store business. ',
            },
            {
              subTitle: '',
              text: 'In May, the net increase in the number of AHS stores was 59 - all were profit- and risk-sharing franchise stores (including shop-in-shops), as a result of the Company’s strategy to prudently expand its store coverage. New store openings mainly occurred in Wuhan, Ningbo and Zhengzhou, indicating more consumer touchpoints and sources of supply in the second-tier cities. In terms of city-level operation stations, 3 net additions were incorporated in lower-tier cities in South China to increase local market coverage.'
            },
            {
              subTitle: '',
              text: 'PJT Marketplace launched a sub-brand “Refurbished Products”, opening a virtual shelf of re-conditioned phones. Under the first guidance for refurbishing pre-owned electronic devices in Shenzhen (<a rel="noopener noreferrer" href="http://www.shenzhen.jcy.gov.cn/inspection/Notice/5026.html" target="_blank">full text in Chinese</a>), PJT improves the condition and/or performance of the phones in compliance by using original or certified screens and batteries. In addition, reconditioning details and after-sales rules are demonstrated, both providing convenience and assurance to merchant users when selecting ideal goods.'
            },
            {
              subTitle: '',
              text: 'ATRenew planned the tactics for the annual 618 shopping festival while encouraging the green consumption style to trade in and recycle idle goods. The four-stage promotion synchronizes with that of JD.com – Paipai kicked off successfully when JD’s Grand Promotion started – during the first fours hour since 8 pm on May 31, recycling and trade-in orders placed through JD.com jumped 340% and 318% year-on-year, respectively. While the overall GMV of Paipai Marketplace increased by 192%, and GMV for smartphones, laptops and tablets, and luxury goods increased by 83%, 112% and 189% year-on-year, respectively.'
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'Ms. Rui Zhu joins as an independent director to enhance corporate governance and board diversity',
              text: 'ATRenew welcomes Ms. Rui Zhu to its board of directors and the audit committee. Ms. Rui Zhu is a professor of marketing and the director of Social Innovation Center at Cheung Kong Graduate School of Business (“CKGSB”). With her extensive experience in “business for good”, Ms. Zhu brings unique perspectives to guard the sustainable development and financial stability of the Company. Ms. Zhu leverages her insights in consumer research and marketing to drive the new trends of greener lifestyles and to create incremental social value as ATRenew expects a synchronized evolvement with the circular economy in China. The appointment took effect on May 17 - currently, three out of the eight board members are independent directors.',
            },
            {
              subTitle: 'Announces 1Q22 earnings with another quarterly non-GAAP operating profit',
              text: 'On May 24, ATRenew reported its 1Q22 financial results. Total net revenues increased by 45.7% year-on-year to RMB2.21 billion, beating Company’s guidance. Total GMV and the number of transactions increased by 51.6% and 31.3% year-on-year to RMB9.4 billion and 8.4 million, respectively. The Company continued its monetization momentum with a non-GAAP operating profit of RMB3.9 million. It also held RMB2.4 billion in cash and cash equivalents, restricted cash, short-term investments and funds receivable from third-party payment service providers as of March 31, 2022, representing a solid foundation for stable operation.',
            },
            {
              subTitle: 'Refines frontline organizational structure to further implement the “city-level service integration” strategy',
              text: 'ATRenew refines its frontline structure into the East, the Southern, the Central and the North regions to optimize management performance, penetrate consumer reach nationwide, source first-hand supplies and empower merchant users while leading them to the next level of profitability. ATRenew also adjusts its C2B and B2B businesses on the HQ level to polish the core business capabilities. The structural adjustments were announced on June 1.',
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'Shenzhen provides qualified recyclers with a 5% subsidy for selling refurbished devices',
              text: 'On May 23, the Development and Reform Commission and the Commerce Bureau of Shenzhen Municipality led the circulation of Measures to Revitalize Consumption (translated headline, full text of the municipal document in <a rel="noopener noreferrer" target="_blank" href="http://www.szgm.gov.cn/xxgk/kjxgfy/zczy/content/post_9821828.html">Chinese</a>). In this document, Shenzhen encourages electronic product consumption and clearly outlines a supportive guideline on the recycling and reuse of consumer electronics, that the government will provide qualified corporates with a subsidy equivalent to 5% of the sales of refurbished devices.',
            },
          ]
        }
      ]
    },
    '202204': {
      articleTitle: 'RERE.US Corporate Blog – April 2022',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in April 2022. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'ATRenew adopted the work-from-home policy and halted its store operations in Shanghai this April as the city implemented a lockdown to hit the zero-COVID goal. Looking beyond Shanghai, ATRenew added 70+ AHS stores to its toC recycling network in China. Through the prudent opening of self-operated stores and an increasing density of franchise stores (profit- and risk-sharing shop-in-shops) in second and third-tier cities, ATRenew further improves its service accessibility to consumers. In the meantime, 13 newly established city-level operation stations, mainly through joint operations in South China, strengthen the Company’s competency in covering retail channels as well as empowering small merchants in lower-tier cities with widely recognized inspection standards.',
            },
            {
              subTitle: '',
              text: 'Steered by the positive stimulus from policies for refurbishment, PJT Marketplace launched the screen replacement services for iPhone 12 and iPhone 12 Pro in April. Users can simply place an order online and ship their devices to ATRenew’s regional operation center in Dongguan. Then, it takes 48 hours to process the order with new Apple-certified screens. ATRenew will continuously explore collaborations with OEMs and enhance its compliant repair offerings to further widen its service margin through value creation for its merchant users.'
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'ATRenew files its 2021 annual report on the 20-F form, issues the letter to shareholders and provides an update on its status under the HFCAA',
              text: 'On April 27, ATRenew filed its annual report on Form 20-F for the fiscal year ended December 31, 2021, with the Securities and Exchange Commission (the "SEC"). On the same day, the 2022 letter to shareholders (entitled "A Trailblazer in the New Circular Economy") authored by Mr. Kerry Xuefeng Chen, CEO of the Company, was released. In this letter, Kerry discussed the progress of the pre-owned consumer electronics industry and the Company in retrospect and reflected on the emerging challenges as well as the silver linings.<br/>On May 5, ATRenew distributed a press release to provide an update on its status under the Holding Foreign Companies Accountable Act (the "HFCAA") that being provisionally named as a Commission-Identified Issuer by the U.S. SEC would not affect the Company’s listing status in the near term and the Company operates business as usual. The Company will continue to comply with applicable laws and regulations in both China and the U.S. and to monitor market developments and evaluate all strategic options.<br/>The full version of the annual report, the letter to shareholders, and the press release on the status under the HFCAA are all available on the Company’s investor relations website at http://ir.atrenew.com.',
            },
            {
              subTitle: 'Respond to COVID-19 while integrating social agenda',
              text: 'In response to the recurrence of the COVID-19 epidemic in Shanghai, ATRenew took active anti-COVID actions to support its staff and local communities. In April, the Company donated a total of nearly 80 metric tons of vegetables and groceries to various districts in Shanghai, a batch of pre-owned phones valued at RMB400,000 in total to Yangpu District as information collection devices in support of PCR tests, RMB200,000 in cash to purchase recyclable lunch boxes to Fudan University, and medical supplies such as protective clothing, gloves, and goggles worth RMB115,000 to Tongji University. In addition, ATRenew also delivered two batches of daily necessities to employees working from home.',
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'People’s Procuratorate of Shenzhen releases the first guidelines for refurbishing pre-owned consumer electronics, signaling a healthier industry outlook',
              text: 'On April 25, the People’s Procuratorate of Shenzhen published the Criminal Compliance Guidelines for Intellectual Property Rights in the Electronics Refurbishment Industry (For Trial Implementation) (translated, <a rel="noopener noreferrer" target="_blank" href="http://www.shenzhen.jcy.gov.cn/inspection/Notice/5026.html">full text in Chinese</a>) indicating prospects for the healthy and fair development of refurbishing electronics before re-commercialization.  This is the first industrial compliance guideline issued by a procuratorial organ in China, which could be a positive demonstration for the compliant renovation of electrics in China. Key highlights of the guidelines include trademark protection, legitimate sourcing of refurbished goods, personal privacy protection, proactive and full disclosure of refurbishment information at resale, limit on polishing and general maintenance, use of quality third-party accessories, and necessity of warranty. ATRenew contributed to the development of the guidelines with suggestions on the standards of refurbishment as one of the corporate representatives.',
            },
          ]
        }
      ]
    },
    '202203': {
      articleTitle: 'RERE.US Corporate Blog – March 2022',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in March 2022. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'The COVID pandemic re-occurred in multiple cities in March, including Shenzhen, Shanghai, and Shenyang, etc. Although some of the regional AHS stores were temporarily shut down in response to municipal requirements, AHS Recycle in other regions and online kept business as usual with high-standard services. This is safely done through pick-up for online orders and safe social distancing in-store fulfillment.',
            },
            {
              subTitle: '',
              text: 'During the recurrences of the COVID, our operation facility strictly adhered to the anti-pandemic requirements of local authorities. We ensure a safe workplace through disinfection of the operation centers three times every day, sanitizing the parcels upon arrival, and checking body temperature and health QR code of our staff on a daily basis. We also leverage our big-data system and direct orders to nearby city-level operation stations to minimize adverse impact on regional operation capability.'
            },
            {
              subTitle: '',
              text: 'In March, ATRenew added 16 new city-level service stations to its operational network, further burgeoning used electronics markets locally in south and southwest China.'
            },
            {
              subTitle: '',
              text: 'In December 2021, ATRenew started a pilot program named “cloud storage”, “云仓” in Chinese, to cover local electronics marketplaces in Suzhou and Shenzhen as a part of the Company’s city-level service integration strategy. It enhances transactions across local markets by leveraging the Company’s inspection capabilities. With Device Hero, a power bank sized inspection device, small POP merchants are able to easily recycle and circulate electronic products. As of the end of March 2022, this pilot program covered 60 cities in China and contributed to the GMV growth of local markets.'
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'Released 4Q21 and FY21 financial results with a quarterly operating income',
              text: 'On March 10, ATRenew reported its beyond-expectation quarterly financial results as the topline beat guidance to over RMB2.4 billion, an increase of 48.2% year-on-year, while highlighting a non-GAAP operation profit of RMB9.7 million. In 2021, total net revenues, GMV and number of transactions increased by 60.1%, 65.8% and 32.2% year-on-year to RMB7.8 billion, RMB32.5 billion and 31.2 million, respectively. The company had sufficient cash on hand by the end of 2021.',
            },
            {
              subTitle: 'Initiated 2021 ESG review and reporting program',
              text: 'In March, assisted by Miotech, ATRenew regathered its annual ESG reporting working group to conduct a yearly review on its ESG performance, benchmarking the GRI standards. The company also quantifies its carbon impact of recycling and remarketing used electronic products. Material topics of this year include responsible recycling of pre-owned electronic devices, data security and privacy protection, as well as safe working conditions and career development for staff during COVID era. ',
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'China Central Television Finance Channel reports a burgeoning recycling and trade-in market',
              text: 'On April 5, CCTV-2, the finance channel operated by China Central Television, reported that recycling and trade-in services became essential in China’s pre-owned phone market (<a rel="noopener noreferrer" target="_blank" href="https://tv.cctv.com/2022/04/05/VIDEAJfxcHdP7ioJ6ikrxwIn220405.shtml">video link</a>). Through continuous education, the action of giving a second life to idle phones obtained popularity among Chinese consumers. By incorporating online pricing and offline recycling services, e-commerce platforms and service platforms boost the expansion of the recycling rate. According to an estimate by the China Association of Electronics Equipments For Technology Development, the penetration of phones in China was about 1.9 billion in 2021, and the number of idle phones could total 6 billion from 2021 to 2025.',
            },
          ]
        }
      ]
    },
    '202202': {
      articleTitle: 'RERE.US Corporate Blog – Feb 2022',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in February 2022. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'In February, the AHS store network expanded by including 39 new jointly-operated stores, 38 of which were partner stores. The number of city-level operation stations totaled 103 with 4 additions added in the month. We resume the expansion of our local service touchpoints after the Chinese New Year holiday.',
            },
            {
              subTitle: '',
              text: 'In Q1 2022, the service integration model was launched in 22 pilot cities and was proven effective. It turns out that 12 of them achieved 100%+ growth rates for B2B GMV, including 3 provincial capitals, Shenyang, Nanchang and Hefei. We remain steadfast in the city-level service integration strategy and will leverage it to conduct refined management, analysis and roll out incentive programs based on our local operations. '
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'Proprietary R&D of “Camera Box 3.0”, a smart phone exterior inspection device',
              text: 'ATRenew upgraded its intelligent mobile phone appearance inspection equipment named “Camera Box 3.0”, which is able to simultaneously handle multiple devices. By incorporating AI algorithms, “Camera Box 3.0” can accurately detect more than 30 kinds of defects within 20 seconds, with an accuracy rate of over 99%, saving 90% of the time compared to manual inspection. It is expected that “Camera Box 3.0” will be put into use in 1H2022 to further implement automation at ATRenew’s operation centers.',
            },
            {
              subTitle: 'Relocated Shenzhen operation center to Dongguan and decentralized the regional fulfillment function of Shanghai operation center',
              text: 'The Company relocates its Shenzhen operation center to Dongguan. Currently, the Dongguan facility is going through a couple of upgrades, including an automated shunting system, an intelligent storage system, and Matrix automated inspection system. A trial operation is expected in mid-June before it’s officially in use by the end of June.  The automation rate of the inspection process will improve by 40%. In February, the Company closed its Shanghai operation center to reduce fulfillment costs in the region. The inspection orders will be directed to city-level operation stations in Shanghai and the operation center in Changzhou.',
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: '2022 government work report re-insisted low-carbon and green development',
              text: 'Premier Li Keqiang of the State Council proposed in the government work report (<a rel="noopener noreferrer" target="_blank" href="https://www.ndrc.gov.cn/xwdt/tzgg/202201/t20220121_1312525.html?code=&state=123">link</a>) on March 5 to improve supportive policies for environmental protection industries such as energy conservation, water conservation, and recycling and reuse of pre-owned goods. During the two sessions, a number of NPC deputies made proposals on the development of a circular economy, further strengthening the recycling of electronics, formulating a medium and long-term development plan for the recycling and reuse of electronics, vigorously cultivating and regulating the industry.',
            },
          ]
        }
      ]
    },
    '202201': {
      articleTitle: 'RERE.US Corporate Blog – Jan 2022',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in January 2022. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'We are delighted to see further scaling of our fulfillment network in January: 40 stores joined our comprehensive network, including self-operated AHS stores, jointly-operated AHS stores, AHS partner stores (a new type of AHS store, “合作店” in Chinese), and Paipai Selection stores. City-level operation stations totaled 99 nationwide. Such progress reflects that our business continues to penetrate deeply into both consumer and merchant communities.',
            },
            {
              subTitle: '',
              text: 'Continue operations during the Chinese New Year holiday: Paipai Marketplace achieved new records during the holiday season – from January 1 to January 7, the overall GMV of Paipai and GMV of smartphone sales increased by 71% and 78% YoY respectively. Of note, GMV of Paipai Selection stores and its official website surged by 630% YoY, and GMV of Douyin and Kuaishou jumped by more than 300% YoY. The number of recycling and trade-in orders through JD increased by 83% and 173% YoY.<br/>To ensure customer experience, AHS Recycle – recycling orders remained effective for 10 days, and door-to-door pick-up and mail services remained open. PJT Marketplace – it was business as usual with blind auctions, fixed price goods, and JD spare stock services. In order to support smooth transactions during the holiday season, PJT Marketplace also provided temporary inventory keeping service to buyers.'
            },
            {
              subTitle: '',
              text: 'Rolled out corporate structure reconfigurations to implement city-level service integration strategy. On the business front, this is done through re-mapping the mainland China market into the north and the south, for a refined business performance evaluation, improved business penetration competencies and merchant community services. There was also an upgrade to the technology department, aiming at enhancing the offerings of marketplaces, privacy protection and data security, quality assurance, and innovations.',
            },
            {
              subTitle: '',
              text: 'In January, ZTO Express joined the logistics service provider network of PJT Marketplace. In addition to mailing cost management, PJT Marketplace started to charge logistics service fees to buyers, ranging from RMB0.9 to RMB4.9 per device, manifesting improved user stickiness and monetization capability.',
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'ATRenew on Bloomberg Green as electronics recycling and reuse expert',
              text: 'Rex Chen, CFO of ATRenew, exchanged a nexus of ideas at Bloomberg Daybreak Asia, regarding trade-in as a compelling alternative to buying new devices, extending electronics lifecycle through re-commercialization, and approaches of consumer education, etc. As an industry disruptor, ATRenew’s platform capabilities and digital tools also empower a wide range of medium and small businesses owners across China.',
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'China’s NDRC leads to issue green consumption promotion master plan',
              text: 'On January 21, Chinese authorities unveiled a master plan (full text in <a rel="noopener noreferrer" target="_blank" href="https://www.ndrc.gov.cn/xwdt/tzgg/202201/t20220121_1312525.html?code=&state=123"> Chinese </a>) on boosting the green transformation of consumption in key areas, the latest move for the country to achieve its carbon peak and neutrality goals. The plan, jointly released by the National Development and Reform Commission and six other government organs, requires strengthening the support of green consumption technology and services, and expanding channels for sharing and reusing idle resources and goods. It also urges to optimize markets for transacting pre-owned home appliances, consumer electronics and clothing, and to standardize rules of online platforms and credit management of such transactions. It also encourages the development of quality inspection centers and third-party quality check laboratories.',
            },
          ]
        }
      ]
    },
    '202112': {
      articleTitle: 'RERE.US Corporate Blog – Dec 2021',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in December 2021. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'By the end of December 2021, the total number of stores exceeded 1,300 in China, including “partner stores”, a new type of AHS store (“合作店” in Chinese). The number of city-level operation stations was above 90. Both reinforced “ensuring sources of supply” at the core of ATRenew’s business strategy and processing capability.',
            },
            {
              subTitle: '',
              text: 'PJT Marketplace service upgrades: adopting seal bags to smart electronics, including sports cameras, video game consoles, and smartwatches, etc. This ensures customers properly cite evidence of defects after-sales. In addition, PJT Marketplace launched “New Releases” (“今日上新”), “PJT Certified” (“拍机堂认证”), and “Prime” (“优质质检”) tags for easy browsing and bidding. Meanwhile, there is a newly-added slider on the product page that allows shoppers to compare multiple devices at ease.',
            },
            {
              subTitle: '',
              text: 'According to JD Cellphone, the 28-hour mobile phone shopping festival on JD.com started from 20:00 on December 31, 2021, till 24:00 on January 1, 2022, achieved multiple record highs. Of note, GMV of its trade-in service, exclusively operated by AHS Recycle, increased by 625%.',
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'Open day at Changzhou operation center welcomes investors and analysts',
              text: 'On December 6, ATRenew’s fully automated operation center in Changzhou opened up to analysts from GF Securities and investors. A guided tour to Matrix 2.0, the latest version of the proprietary automatic inspection system, and a roundtable were provided for the visitors to obtain a thorough understanding of ATRenew’s advanced inspection, operation, and technology capabilities.',
            },
            {
              subTitle: 'Polish strategy implementation tactics and financial targets through quarterly discussion',
              text: 'On December 24 to 26, a periodical review of business strategy and innovative projects was conducted through ATRenew’s quarterly strategy committee meeting. The committee also discussed operational and financial targets for 1Q2022, and how the city-level service integration model could be more efficiently replicated across China. Partnerships and competitive landscapes were also among the themes of discussion.',
            },
            {
              subTitle: 'Won multiple corporate awards on merit',
              text: 'In December, ATRenew won three major corporate-level recognition for its outstanding innovations, social responsibility, and investor relations work. The awards are “2021 Capital Market Innovative Potential Company” (“2021年度资本市场潜力创新公司”) from 36Kr, “2021 Corporate Social Responsibility Excellence Award” from SUMG, and “Best Capital Market Communication Award” from Roadshow China.',
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'Authorities visit corporates for the healthy development of the circular economy',
              text: 'Recently, China’s NDRC, the MOFCOM, and the CNIPA have been initiating discussions on the recycling and reuse of pre-owned goods as well as the healthy development of the industry. As a leading player in the pre-owned consumer electronics industry, ATRenew presented its business model and exchanged ideas on industry evolvement and taxation, compliant operation, and IP protection. ',
            },
          ]
        }
      ]
    },
    '202111': {
      articleTitle: 'RERE.US Corporate Blog – Nov 2021',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in November 2021. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'The monthly GMV of Paipai Selection x Dyson flagship store exceeded RMB3 million in November, compared with RMB140,000 in October since its debut. The flagship store on JD.com distributes Dyson certified refurbished products, including vacuums, purifiers, hair dryers, and Airwrap stylers, etc. The collaboration was matchmade by JD Retail.',
            },
            {
              subTitle: '',
              text: 'In November, aside from 17 Paipai Selection stores, AHS store totaled 1,074 nationwide, achieving the Company’s strategic goal of establishing “one thousand stores across one hundred cities in China” ahead of schedule. In the meantime, ATRenew added 21 city-level operation stations to expand the national coverage of the city-level service integration business model, further increasing consumer reach to recycle and empowering merchants to conduct quality inspections and efficient circulation locally. The enhanced local supply chain and management capabilities set a solid foundation for the strategic goal of topping annual GMV to over RMB100 billion in 2025.',
            },
            {
              subTitle: '',
              text: 'During the Single’s Day shopping festival, the paid orders of JD one-stop trade-in increased by 84% year-over-year, and the number of recycling orders placed (excluding trade-in orders) increased by 44% year-over-year. On November 11, GMV of Paipai Selection ranked top across multiple online distribution channels under pre-owned goods category, including JD.com, Kuaishou, and Douyin, further demonstrating the widening acknowledgment among consumers.',
            },
            {
              subTitle: '',
              text: 'At the end of November, PJT Marketplace launched a product tag of “Nearby” (“快速达”), allowing merchant shoppers to identify the nearest goods at ease. The tag optimizes user experience and improves the turnover of goods.',
            },
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'CEO participated in Deloitte forum to discuss circular economy during the 4th CIIE',
              text: 'On November 6, Mr. Kerry Xuefeng Chen, Founder and CEO of ATRenew, delivered a keynote speech at Deloitte’s forum during the 4th China International Import Expo, sharing the Company\'s experiences in promoting the circulation of pre-owned consumer electronic products and its ESG practices. The keynote was well received by participants at Deloitte’s themed forum: "China\'s turning point: How climate action can drive our economic future".',
            },
            {
              subTitle: 'Completed global rebranding and launched new website domain',
              text: 'On November 15, the Company announced the effectiveness of "ATRenew Inc." as its new company name following the approval by its shareholders at the extraordinary general meeting on the same day. The Company\'s ticker symbol remains unchanged as "RERE". In addition, the Company launched www.atrenew.com and ir.atrenew.com as brand-new information portals for information disclosure and stakeholder communication.',
            },
            {
              subTitle: 'Released 3Q21 results while GTJA International initiated a “Buy” rating',
              text: 'On November 18, ATRenew released its 3Q21 financial results while further interpreting its “city-level service integration” business strategy. About at the same time, Guotai Junan International initiated ATRenew with a “Buy” rating – becoming the sixth leading domestic brokerage firm to cover ATRenew following China Renaissance, CITIC Securities, GF Securities, TF Securities, and Southwest Securities.',
            },
            {
              subTitle: 'ATRenew joined as a core member of “Low Carbon Office Alliance”',
              text: 'On November 25, Shui On, Circular Pie (“循环派”), and Maker Sustainability Consulting co-founded “Low-Carbon Office Alliance” (“低碳办公联盟”) where ATRenew became a core member. Ms. Becky Du, Senior Vice President of ATRenew presented the Company’s low-carbon practices and its corporate solutions.',
            },
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'JD released a research report on the circular economy, citing insights on second-hand consumer electronics from ATRenew',
              text: 'On December 1, JD Group issued a report titled “Circular Economy Report 2021: Used Goods Are Not Unwanted Goods” (<a href="https://mp.weixin.qq.com/s/Z5h6wfqStmm8q7GzpLBzaw" rel="noopener noreferrer" target="_blank">《当“旧物”不再是“废物”——2021“循环经济”研究报告》</a>) in joint efforts with ATRenew and A.T. Kearney. It pulled user research and analysis of the sales of pre-owned electrics since 2018, reflecting the status quo of the circular economy in China. The report unveils that the pre-owned consumer electronics industry outruns other industries in terms of consumer acceptance and practical development. In addition, trade-in services accelerate the sales of expensive goods, and the re-commercialization of premium pre-owned goods through reliable channels benefits a wider group of consumers.',
            },
          ]
        }
      ]
    },
    '202108': {
      articleTitle: 'RERE.US Corporate Blog - August 2021',
      articleTips: 'Below is a summary of our recent business developments and industry headlines in August 2021. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and the filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'In August, the Company opened 31 new stores nationwide, further increased the total number to over 900. The store network expansion focused on penetrating mid- and lower-tier cities in central and north China.'
            },
            {
              subTitle: '',
              text: 'The number of city-level operation stations totaled 34 – this included 4 additions in north, eastern and central China in August 2021, further increasing the national coverage. These city-level operation stations function as local gateways to further expand the Company’s sourcing capability while providing timely services to the merchants and the mobile markets locally. ',
            },
            {
              subTitle: '',
              text: 'On 1 August, the Company started charging quality inspection service fees to sellers on PJT Marketplace who sent their devices to our facilities for inspection. This was due to the Company’s improving scalability and accumulating capabilities to serve the merchants.'
            },
            {
              subTitle: '',
              text: 'On 15 August, the Company adjusted the basic service fee and value-added service fee for mobile phones in certain price ranges to sellers under the consignment model on Paipai Marketplace. Paipai Marketplace was able to take more initiatives in the pricing strategy due to the improvements in brand recognition and consumer trust.'
            },
            {
              subTitle: '',
              text: 'In late August, the Company launched an app named “Jingzhi” (鲸置). It splits the C2C service function from Paipai while upgrading the app of “Paipai” (拍拍) to “Paipai Select” (拍拍严选), so as to keep C2B and B2C at the core of Paipai Marketplace. “Jingzhi” is developed by the Company, and it is currently released through JD’s channel on mobile app stores – this is mutually agreed between the Company and JD. '
            }
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'Released the first quarterly financial results since IPO, and pioneered in issuing the annual ESG report',
              text: 'ATRenew distributed its first quarterly earnings release on 17 August as a public company. To ensure transparency, a full transcript of the earnings conference call has been uploaded to the Company’s investor relations website. Management’s discussion on the Company’s operations and financial performance is included.'
            },
            {
              subTitle: '',
              text: 'At the same time, the Company released its first annual ESG report which reviewed its ESG performance in 2020. With reference to the GRI Standards, the Company identified material ESG issues of the pre-owned electronic device transaction and service industry, and disclosed the Company\'s measures of information security and user data privacy protection. In addition, the report shared in detail the Company’s systematic approach to bringing employee welfare and caring to the next level, as well as its efforts in setting the standard for the pre-owned consumer electronics industry chain. The Company also reviewed the periodical performance in the aspects of environmental, social, and governance in 2020, aiming at establishing a more solid and comprehensive environmental management system in the future, and providing first-hand experience for energy-saving and emission-reduction management of the pre-owned consumer electronics industry chain. The Company continues to play an active role in advocating the development of a circular economy with its sustainable business model. The full report, in both Simplified Chinese and English, is available under the "ESG" column of the Company’s investor relations website.'
            },
            {
              subTitle: 'CITIC Securities and GF Securities initiated coverage of RERE.US at “Buy”',
              text: 'In August 2021, both CITIC Securities and GF Securities initiated coverage of RERE at a “Buy” rating – target prices being USD19.00/ADS and USD20.33/ADS. CITIC Securities is optimistic about the upgrade of the entire value chain of recycling, grading, pricing, and distribution of pre-owned consumer electronic devices successfully established through proprietary technology research and development as well as innovation. It holds a bullish view of the Company\'s penetration and services capabilities. GF Securities recognizes the company\'s market leadership and believes that the pre-owned consumer transaction and service business is a supply-driven business that is still in its infancy but is expected to evolve rapidly soon.'
            },
            {
              subTitle: 'Promotion of talents',
              text: '130 outstanding employees were promoted following the semi-annual performance review. The promotion is expected to encourage talents and potentially nurture more future leaders. '
            }
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'People Data Research Institute released "Second-hand Trading and Low-Carbon Life Research Report"',
              text: 'In mid-August, People\'s Daily Online and People Data Research Institute released the "<a href="http://www.peopledata.com.cn/uploadfile/2021/0818/20210818050335636.pdf">Second-hand Trading and Low-Carbon Life Research Report</a>" – view the report, here. The report reflects on China’s "dual carbon" goals of peak carbon emissions and carbon neutrality and circular economy development policy to discuss the standardization of the "Internet + second-hand goods trading" platforms. The report cited the credit score rating by Qixin, a credit rating system by People’s Daily – to highlight, among a number of peer platforms, AHS Recycle of the Company ranked top.'
            }
          ]
        }
      ]
    },
    '202109': {
      articleTitle: 'RERE.US Corporate Blog – Sept 2021',
      articleTips: 'Below is a summary of our recent business developments and industry headlines in September 2021. Regarding operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'In September, the Company remained a steady pace of new store opening. Nationwide, the number totaled approx. 950. The store network expansion focused on penetrating mid- and lower-tier cities in China. To highlight, the number exceeded 100 in Shanghai.'
            },
            {
              subTitle: '',
              text: 'The number of city-level operation stations totaled 41 – this included 7 additions in north, eastern and central China in September 2021. These local gateways further expand the Company’s sourcing capability while providing timely services to the merchants and the mobile markets locally. ',
            },
            {
              subTitle: '',
              text: 'On 1 September, the Company started charging value-added service fee on products go through testing facilities, which covers storage, inspection and after sales services, to sellers on PJT Marketplace. PJT Marketplace charges a fixed minimum service fee per device successfully transacted. This further demonstrates that the Company’s improving capabilities and services are well acknowledged by merchants.'
            },
            {
              subTitle: '',
              text: 'On 13 September, PJT Marketplace launched “PJT Instant Sale” (“即刻卖”), an optional solution for pre-owned smartphone sellers to get instant cash at a certain discount rate. It allows sellers to conveniently lock-in cash offers instantly. PJT Marketplace charges a basic service fee and earns the premium leveraging on big data. It aims to provide customers with more convenient transaction experience.'
            },
            {
              subTitle: '',
              text: 'On 18 September, PJT Marketplace upgraded its payment management system to brought more convenience and security to merchants during transactions. Merchants now can easily split and review the balance under their buyer account and seller account. The upgraded system supports multiple banks and checkout methods, as well as 24x7 cash withdrawal under RMB1 million.'
            }
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'AHS CHARITY & LOVE ·Digital Education Fund delivers support to schools in Henan province',
              text: 'In July 2021, schools and students in Henan province suffered from storms and floods. ATRenew established the “AHS Charity & Love · Digital Education Fund” in joint efforts with China Foundation for Poverty Alleviation and donated RMB5.05 million. In September, ATRenew delivered electronic teaching equipment in an orderly manner to replace the damaged equipment.'
            },
            {
              subTitle: 'Quarterly meeting called to address growth strategy and business goals for 2022 ',
              text: 'On 14-16 September, the Strategy Committee of ATRenew held a quarterly meeting to review periodical business progress. Multiple operational matters were reviewed and evaluated, such as pricing strategy, innovative development of the platform business, store opening strategy and traffic attraction, and optimization of services, etc. The committee members had open discussions when setting the strategic goals of 4Q2021 and next year. '
            },
            {
              subTitle: 'Shortlisted for the IR Magazine Awards – Best ESG materiality reporting',
              text: 'On 23 September, ATRenew was shortlisted as a finalist for the IR Magazine Awards – Greater China 2021 - Best ESG materiality reporting (small to mid-cap). Since its debut as a public company in June 2021, ATRenew has soon been recognized for its ESG efforts and active reporting among the top IR teams from across Greater China. The final list of winners will be announced on 9 December.'
            },
            {
              subTitle: 'Conducted quarterly training to enhance risk management and ESG awareness',
              text: 'On 24 September, the Company conducted an internal manager training on the Sarbanes-Oxley Act and ESG, aiming to further improve corporate governance, implement financial reporting policies and procedures, raise awareness of securities market compliance, and promote sustainable practices. In addition, there was a security awareness training for all employees, aiming at enhancing information security and risk control capabilities of the company.'
            },
            {
              subTitle: 'Set to hold EGM in regard to changing the Company’s name to “ATRenew Inc.”',
              text: 'On 30 September, the Company issued its Notice of Extraordinary General Meeting (“EGM”) which will be held at the Company’s headquarter in Shanghai on 15 November, 2021. The special resolution to be considered, passed and approved is the change of the Company’s name to “ATRenew Inc.” which is expected to strengthen brand recognition across global markets. For details of the EGM and proxy information, please refer to the event details on the Company’s Investor Relations website: ir.aihuishou.com.'
            }
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'NDRC called for comments on the Circular Economy Promotion Law',
              text: 'On September 10, 2021, the National Development and Reform Commission (NDRC) called for comments on amendments to the Circular Economy Promotion Law of the People’s Republic of China. Commercial pathways, including "Internet + resource recycling" and second-hand markets, shared economy and green consumption, are expected to evolve in a more orderly and healthy way.'
            }
          ]
        }
      ]
    },
    '202110': {
      articleTitle: 'RERE.US Corporate Blog – Oct 2021',
      articleTips: 'Below is a summary of the recent business development of the Company and the industry headlines in October 2021. Regarding the operational metrics, please refer to the Company’s quarterly earnings release and its filings with the U.S. SEC.',
      sections: [
        {
          title: 'Operational update',
          list: [
            {
              subTitle: '',
              text: 'As of 31 October, the total number of stores exceeds 990 nationwide, illustrating a continuously penetrating footprint, with a focus on south China. In the meantime, the Company added 3 Paipai Selection stores in the CBD areas in Chengdu and Zhengzhou to meet consumers’ growing needs for premium pre-owned electronic devices.'
            },
            {
              subTitle: '',
              text: 'City-level service integration: Over 10 jointly-operated city-level operation stations were added to the mainland market. By leveraging the local AHS store network, standard quality inspection and operational capabilities, and the extension of Paipai Selection stores, we aim to secure direct sources of supply and exploit the efficiency of transactions through local distribution.',
            },
            {
              subTitle: '',
              text: 'On processing, the operation centers integrate the process of generating toB and toC inspection and grading reports at the same time, each suiting the Company’s toB and toC distribution business. Such integration further advances the Company’s internal circulation from PJT Marketplace to Paipai Marketplace and cuts down operational costs.'
            }
          ]
        },
        {
          title: 'Key highlights',
          list: [
            {
              subTitle: 'Upgraded accident reporting and management measures to safeguard operations',
              text: 'On 15 October, the Company issued "ATRenew Accident Management Measures" as the upgraded measures to further standardize its internal accident grading, reporting, responding, and tracking functions. The goal is to reduce adverse effects and losses, improve operational efficiency, and ensure the orderly operation of the Company. At the same time, the original measures are unified into the upgraded measures.'
            },
            {
              subTitle: 'International acknowledgment of ATRenew’s award-winning treasury functions',
              text: 'On 20 October, ATRenew announced that the Company was highly commended by Treasury Today Group’s Adam Smith Awards Asia 2021 (the “Awards”) for“Best in Class Treasury Solution” in the PRC category. The Awards are widely recognized as the ultimate benchmark for corporate treasury achievement. This is a recognition that ATRenew outperforms hundreds of outstanding corporates of major industries across the Asia-Pacific region in terms of treasury management, credit and risk control, and practices in digital and centralized account management, etc.'
            },
            {
              subTitle: '4Q21 R&D Day complete with strategy sharing and team-building ',
              text: 'On 29 October, ATRenew held its quarterly R&D Day in Shanghai. The CEO and the COO brought themed sharing presentations on the Company’s strategy updates. In addition to appraisal and awards to outstanding employees, outdoor concerts and fun team-building activities form a balanced agenda.'
            }
          ]
        },
        {
          title: 'Industry news',
          list: [
            {
              subTitle: 'China’s State Council set "Action Plan for Carbon Dioxide Peaking Before 2030" ',
              text: 'On 26 October, the State Council, China\'s cabinet, released the “Action Plan for Carbon Dioxide Peaking Before 2030” (the “Plan”) for reaching carbon dioxide peak before 2030. The Plan is formulated to advance actions on carbon dioxide peaking in further implementing the major strategic decisions by the central authorities. Over the 14th Five-Year Plan period, notable progress will be made in the adjustment and optimization of the industrial structure and the energy mix. During the 15th Five-Year Plan period, the public will opt for environment-friendly living patterns, and formulation of the policy framework for green, low-carbon and circular development will be mostly complete. Of note, the action for promoting circular economy in carbon mitigation purpose is one of the “ten major peaking carbon dioxide emissions actions” attracting particular focus during implementation. (<a href="https://english.www.gov.cn/policies/latestreleases/202110/27/content_WS6178a47ec6d0df57f98e3dfb.html">Full text in English.</a>)'
            },
            {
              subTitle: 'China issues white paper on responses to climate change',
              text: 'The State Council Information Office released a white paper titled "Responding to Climate Change: China\'s Policies and Actions” on 27 October, in Beijing. The white paper reiterates China’s initiatives in tackling climate change, including the commitment to sustainable development and the urge that “all countries should integrate climate action into their national overall plans for sustainable development, promote a green, low-carbon, circular and sustainable approach to life and work, and foster a model of sustainable development featuring increased output, higher living standards, and healthy ecosystems.” (<a href="http://www.scio.gov.cn/zfbps/32832/Document/1715506/1715506.htm">Full text in English.</a>)'
            }
          ]
        }
      ]
    }
  }
})

const data = {
  CN,
  US
}
export default data
